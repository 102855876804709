import {atom} from "recoil";

const localStorageEffect = key => ({setSelf, onSet}) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
    }
    onSet(newValue => {
        localStorage.setItem(key, JSON.stringify(newValue));
    });
    };

// ------------------ repair entry ------------------
export const repairEntryRows = atom({
    key: 'repairEntryRows',
    default: [],
    effects_UNSTABLE: [localStorageEffect('repairEntryRows')],
});

// ------------------------------ bit evaluation ------------------------------
export const bitEvalSelectedDrill = atom({
    key: 'bitEvalSelectedDrill',
    default: null,
    effects_UNSTABLE: [localStorageEffect('bitEvalSelectedDrill')],
});

export const bitEvalDataGridStore = atom({
    key: 'bitEvalDataGridStore',
    default: [],
    effects_UNSTABLE: [localStorageEffect('bitEvalDataGridStore')],
})

export const bitEvalOrder = atom({
    key: 'bitEvalOrder',
    default: null,
    effects_UNSTABLE: [localStorageEffect('bitEvalOrder')],
})

export const bitEvalOrderConfirm = atom({
    key: 'bitEvalOrderConfirm',
    default: null,
    effects_UNSTABLE: [localStorageEffect('bitEvalOrderConfirm')],
})

export const bitEvalCutterMap = atom({
    key: 'bitEvalCutterMap',
    default: [],
    effects_UNSTABLE: [localStorageEffect('bitEvalCutterMap')],
    dangerouslyAllowMutability: true,
})

export const tempBitEvalCutterMap = atom({
    key: 'tempBitEvalCutterMap',
    default: [],
    effects_UNSTABLE: [localStorageEffect('tempBitEvalCutterMap')],
    dangerouslyAllowMutability: true,
})

export const allOrdersBitEval = atom({
    key: 'allOrdersBitEval',
    default: [],
    effects_UNSTABLE: [localStorageEffect('allOrders')],
})

export const bitEvalOrderObj = atom({
    key: "bitEvalOrderObj",
    default: {
        OrderId: null,
        DrillSerial: null,
        Scrap: false,
        ScrapReason: null,
        ScrapDate: null,
        FieldNotes: [],
        Images: [],
        CutterMap: {},
        ActiveOrder: true,
        RepairTypeCard: {hardface: false, matrix: false, standard: true}
    },
    effects_UNSTABLE: [localStorageEffect('bitEvalOrderObj')],
    dangerouslyAllowMutability: true,
})
// ------------------------------ DB ------------------------------

export const selectedCuttersList = atom({
    key: 'selectedCuttersList',
    default: [],
    effects_UNSTABLE: [localStorageEffect('selectedCuttersList')],
});


// ------------------------------ ordering ------------------------------
export const selectedOrder = atom({
    key: 'selectedOrder',
    default: null,
    effects_UNSTABLE: [localStorageEffect('selectedOrder')],
});

export const selectedOrderDrill = atom({
    key: 'selectedOrderDrill',
    default: null,
    effects_UNSTABLE: [localStorageEffect('selectedOrderDrill')],
});

// ------------------------------ auth ------------------------------

export const savedPages = atom({
    key: 'savedPages',
    default: [],
    effects_UNSTABLE: [localStorageEffect('savedPages')],
});