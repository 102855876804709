import React from "react";
import { Avatar, Box, Card, Typography } from "@mui/material";
import PrimaryRowAvatars from "./PrimaryRowAvatars";
import SecondaryRowAvatars from "./SecondaryRowAvatars";

const BladeCard = (props) => {
  const { index, parentState, childrenState, bladeIndex } = props;
  const {
    primary,
    secondary,
    max,
    cutters,
    primaryEvalCutters,
    secondaryEvalCutters,
  } = props.blade;
  const bladeNumbers = Array.from(Array(max), (_, x) => x + 1);
  return (
    <Card
      variant={"outlined"}
      sx={{
        display: "flex",
        width: "100%",
        overflow: "auto",
        alignItems: "center",
        gap: 1,
        // justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: "grid",
          gap: 1,
          gridTemplateColumns: `100px 0.5fr 6fr`,
          gridTemplateRows: `repeat(3,1fr)`,
          padding: 1,
          gridTemplateAreas: `"bladeName row avatars"
                    "bladeNumber P avatars"
                    "bladeNumber S avatars"`,
          alignItems: "center",
          overflow: "auto",
          // maxWidth: '100%',
        }}
      >
        <Box
          sx={{
            gridArea: "bladeName",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // height: '100%',
          }}
        >
          <Typography variant={"h6"}>Blade</Typography>
        </Box>
        <Box
          sx={{
            gridArea: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant={"h6"}>Row</Typography>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateRows: `repeat(3,1fr)`,
            gridTemplateAreas: `"numbers"
                        "primary"
                        "secondary"`,
            gridArea: "avatars",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridArea: "numbers",
              gridTemplateColumns: `repeat(${max},1fr)`,
              gap: 1,
            }}
          >
            {bladeNumbers.map((number, i) => {
              return (
                <Avatar variant={"square"} key={i}>
                  {number.toString()}
                </Avatar>
              );
            })}
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: `repeat(${max},1fr)`,
              gridArea: "primary",
            }}
          >
            {primaryEvalCutters.map((cutterState, i) => {
              return (
                <PrimaryRowAvatars
                  key={i}
                  cutterState={cutterState}
                  primaryRow={cutters[0].primary}
                  cutterNumber={cutters[0].primary[i]}
                  cutterIndex={i}
                  bladeIndex={bladeIndex}
                  parentState={parentState}
                  childrenState={childrenState}
                />
              );
            })}
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: `repeat(${max},1fr)`,
              gridArea: "secondary",
            }}
          >
            {secondaryEvalCutters.map((cutterState, i) => {
              return (
                <SecondaryRowAvatars
                  key={i}
                  cutterState={cutterState}
                  primaryRow={cutters[1].secondary}
                  cutterNumber={cutters[1].secondary[i]}
                  cutterIndex={i}
                  bladeIndex={bladeIndex}
                  parentState={parentState}
                  childrenState={childrenState}
                />
              );
            })}
          </Box>
        </Box>

        <Card
          variant={"outlined"}
          sx={{
            height: "100%",
            gridArea: "bladeNumber",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            // justifySelf: 'center',
          }}
        >
          <Typography variant={"h6"}>{(index + 1).toString()}</Typography>
        </Card>
        <Card
          variant={"outlined"}
          sx={{
            gridArea: "P",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant={"h6"}>P</Typography>
        </Card>

        <Card
          variant={"outlined"}
          sx={{
            gridArea: "S",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant={"h6"}>S</Typography>
        </Card>
      </Box>
    </Card>
  );
};

export default BladeCard;
