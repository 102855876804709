import React from 'react';
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import {Card} from "@mui/material";
import {useRecoilState} from "recoil";
import {orderingRows} from "../../../../../atoms/Ordering_Atoms/OrderingAtoms";
import {selectedOrder, selectedOrderDrill} from "../../../../../atoms/staticAtoms";

/* Определение столбцов, которые будут отображаться в таблице. */
const testColumns = [
    {
        field: 'id',
    },
    {
        field: 'orderId',
        headerName: 'Order ID',
        flex: 1,
    },
    {
        field: 'serial',
        headerName: 'Serial',
        flex: 1,
    },
    {
        field: 'size',
        headerName: 'Size',
        flex: 1,
    },
    {
        field: 'type',
        headerName: 'Type',
        flex: 1,
    },
    {
        field: 'origin',
        headerName: 'Origin',
        flex: 1,
    },
    {
        field: 'repairType',
        headerName: 'Repair Type',
        flex: 1,
    },
]


const OrderingTable = (props) => {
    const [rows, setRows] = useRecoilState(orderingRows)
    const [selectedOrderId, setSelectedOrder] = useRecoilState(selectedOrder) // TODO: Сделать это объектом, чтоб в базу по 20 раз не лезть.
    const [orderDrillSerial, setOrderDrillSerial] = useRecoilState(selectedOrderDrill)

    const goToOrder = (e) => {
        setSelectedOrder(e.row.orderId);
        setOrderDrillSerial(e.row.serial);
        /*let urlUpgradeCutterMap = "/api/orders/upgradeOrderCutterMap?order=" + e.row.orderId;
        fetch(urlUpgradeCutterMap).then((response) => {
            if (response.ok) {
                console.log("Upgrade order cutter map success");
            } else {
                console.log("Upgrade order cutter map failed");
            }
        })
        console.log("Upgrade order");*/

        window.location.href = '/order/'
    }

    return (
        <Box>
            <DataGrid columns={testColumns} rows={rows} onRowClick={(e) => goToOrder(e)}
                      sx={{
                          // display: 'flex',
                          height: '70vh',
                      }}/>
        </Box>

    );
}

export default OrderingTable;