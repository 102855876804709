import React from 'react';
import {Box} from "@mui/material";
import {BitEvalDataGrid, BitEvalHeader} from "./components";
import UseBitEvalStates from "../../../hooks/useBitEvalStates";
import {useRecoilState} from "recoil";
import {allOrdersBitEval, bitEvalOrderObj, bitEvalSelectedDrill} from "../../../atoms/staticAtoms";
import {DbData, Order} from "../../../atoms/repairEntryFetchData";
import {useEffect} from "react";

const BitEvaluation = (props) => {

    const defaultOrderObj = {
        OrderId: null,
        DrillSerial: null,
        Scrap: false,
        ScrapReason: null,
        FieldNotes: [],
        Images: [],
        CutterMap: {},
        ActiveOrder: true,
        RepairTypeCard: {hardface: false, matrix: false, standard: false}
    }
    
    const [orderObj, setOrderObj] = useRecoilState(bitEvalOrderObj);
    
    useEffect(() => {
        document.title = "Bit Evaluation";
        if(!window.location.href.includes("start")){
            setOrderObj(defaultOrderObj);
        }
    }, [])
    
    
    return (
            <Box
                id={'BitEvalLayout'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                }}
            >
                <BitEvalHeader/>
                <BitEvalDataGrid />
            </Box>
    );
}

export default BitEvaluation;