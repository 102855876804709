import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { urlSetParam } from "../../../../../../atoms/repairEntryFetchData";
import { toast } from "react-toastify";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

const EvaluationContent = (props) => {
  const [location, setLocation] = useState("");
  const [workOrder, setWorkOrder] = useState("");
  const [scrapName, setScrapName] = useState("");
  const [scrapDate, setScrapDate] = useState(null);
  const [scrapList, setScrapList] = useState([]);

  const columns = React.useMemo(() => [
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "Name",
      headerName: "Material",
      flex: 0.5,
    },
    {
      field: "Description",
      headerName: "Material Code",
      flex: 1,
    },

    {
      field: "qty",
      headerName: "Qty",
      flex: 0.5,
    },
  ]);

  const getOrderPdf = () => {
    let urlRequest = urlSetParam("/api/pdf/createPdf?orderId=%0", [orderId]);
    fetch(urlRequest)
      .then((response) => response.json())
      .then((data) => {
        let url = urlSetParam("/api/pdf/dowloadPdf?orderId=%0", [orderId]);

        const linkSource = url;
        const downloadLink = document.createElement("a");
        const fileName = "test.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
  };

  const getLocationName = async (locationCode) => {
    try {
      const response = await axios.get("api/location/getLocation", {
        params: { locationCode: locationCode.toString() },
      });
      if (response.status === 200) {
        setLocation(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const completeOrder = () => {
    let url = "/api/orders/completeOrder?id=%0";
    let urlRequest = urlSetParam(url, [orderId]);

    fetch(urlRequest).then((response) => {
      if (response.ok) {
        toast.success("Order completed");
        window.location.href = "/repairs";
        return;
      }
      toast.error("Order not completed");
    });
  };

  const [rows, setRows] = React.useState([]);

  const getRows = (order) => {
    let url = "/api/cutters/getRepairCutters?orderId=" + order;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setRows(data.cutters);
      });
  };

  const removeOrder = () => {
    let url = "/api/orders/removeOrder?id=%0";
    let urlRequest = urlSetParam(url, [orderId]);

    fetch(urlRequest).then((response) => {
      if (response.ok) {
        toast.success("Order removed");
        window.location.href = "/repairs";
        return;
      }
      toast.error("Order not removed");
    });
  };

  const getWorkOrder = (orderId) => {
    axios
      .get(`api/orders/GetWorkOrder`, { params: { orderId: orderId } })
      .then((res) => {
        setWorkOrder(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getScrapList = async () => {
    try {
      const response = await axios.get("api/Scrap/getScraps");
      if (response.status === 200) {
        setScrapList(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [orderId, setOrderId] = useState("");
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let order = props.arhive
      ? props.rowData.state.row.orderId
      : props.rowData.state.row.evalution;
    setOrderId(order);
    getRows(order);
    getLocationName(props.rowData.state.row.origin).then(() => {
      console.log("Location name: " + location);
    });
    getWorkOrder(order);
    getScrapList();
  }, []);

  const handleScrapButton = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const makeScrapped = async () => {
    if (scrapName === "" || scrapDate === null) {
      toast.error("Please fill all fields");
      return;
    }
    try {
      const response = await axios.get(
        "api/DrillsControllers/setScrapForOrderAndDrill",
        {
          params: {
            serial: props.rowData.state.row.serial,
            scrapName: scrapName,
            scrapDate: scrapDate,
            orderNumber: orderId,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Drill scrapped");
        window.location.href = "/repairs";
        return;
      }
    } catch (error) {
      toast.error("Error while scrapping order");
      console.error(error);
    }
  };

  const handleDateChange = (date) => {
    if (new Date(date.target.value) < new Date()) {
      toast.error("Scrap date can't be in past");
      return;
    }
    setScrapDate(date.target.value);
  };

  const handleChange = (event) => {
    setScrapName(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5,1fr)",
          gridTemplateRows: "repeat(2,1fr)",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <Typography variant={"h6"}>Order ID</Typography>
        <Typography variant={"h6"}>Serial</Typography>
        <Typography variant={"h6"}>Material</Typography>
        <Typography variant={"h6"}>Size</Typography>
        <Typography variant={"h6"}>Type</Typography>
        <Typography>{orderId}</Typography>
        <Typography>
          {props.arhive
            ? props.rowData.state.row.serialNumber
            : props.rowData.state.row.serial}
        </Typography>
        <Typography>
          {props.arhive
            ? props.rowData.state.row.materialNumber
            : props.rowData.state.row.serial}
        </Typography>
        <Typography>{props.rowData.state.row.size}</Typography>
        <Typography>{props.rowData.state.row.type}</Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `3fr 1fr`,
          // gridTemplateRows: `1fr`,
          gridTemplateAreas: `"table data"
                    "workOrder workOrder"
                    "buttons buttons"`,
          gap: 1,
          padding: 1,
        }}
      >
        <Card
          variant={"outlined"}
          sx={{
            gridArea: "table",
          }}
        >
          <DataGrid
            columns={columns}
            rows={rows}
            sx={{
              height: "60vh",
            }}
          />
        </Card>
        <Card
          variant={"outlined"}
          sx={{
            display: "flex",
            gridArea: `data`,
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              padding: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                padding: 1,
              }}
            >
              <Typography variant={"h6"}>Локация: {location?.name}</Typography>
              <Typography>Код локации: {location?.code}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                padding: 1,
              }}
            >
              <Typography>Repair Types:</Typography>
              <Typography>{props.rowData.state.row.repType}</Typography>
            </Box>
          </Box>
        </Card>
        <Card
          variant={"outlined"}
          id={"FooterCard"}
          sx={{
            gridArea: `workOrder`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              padding: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>Work order</Typography>
            <Typography variant={"h5"}>{workOrder}</Typography>
          </Box>
        </Card>
        <Card
          sx={{
            gridArea: `buttons`,
          }}
          variant={"outlined"}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: `4fr 1fr`,
              padding: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                padding: 1,
              }}
            >
              {/*TODO: Добавить обработчики*/}
              <Button
                size={"small"}
                variant={"outlined"}
                onClick={() => {
                  getOrderPdf();
                }}
              >
                Re-Print Order
              </Button>
              {props.arhive ? null : (
                <>
                  <Button
                    size={"small"}
                    variant={"outlined"}
                    onClick={handleScrapButton}
                  >
                    Scrap
                  </Button>
                  {sessionStorage.getItem("role") === "SUPERADMIN" ? (
                    <Button
                      size={"small"}
                      variant={"outlined"}
                      onClick={() => {
                        removeOrder();
                      }}
                    >
                      Remove Order
                    </Button>
                  ) : null}
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                padding: 1,
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {!props.arhive ? (
                <Button
                  size={"small"}
                  variant={"outlined"}
                  onClick={() => completeOrder()}
                >
                  Complete
                </Button>
              ) : null}
            </Box>
          </Box>
        </Card>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle id="form-dialog-title">Scrap Order</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please select the reason for scrapping the order
            </DialogContentText>
            <Card variant={"outlined"} sx={{ padding: 1 }}>
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 120,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  Scrap Reason
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={scrapName}
                  label="Scrap Reason"
                  onChange={handleChange}
                >
                  {scrapList.map((reason, index) => {
                    return (
                      <MenuItem value={reason.Name} key={index}>
                        {reason.Name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <TextField
                  id="date"
                  label="Scrap Date"
                  type="date"
                  value={scrapDate}
                  variant={"outlined"}
                  fullWidth
                  error={scrapDate !== null ? false : true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleDateChange}
                />
              </FormControl>
            </Card>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={makeScrapped}>Scrap</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default EvaluationContent;
