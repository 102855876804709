import UseRECOILState from "./useRECOILState";
import {bitEvalCutterMap, tempBitEvalCutterMap} from "../atoms/staticAtoms";

const useChangeBitEvalBladesState = () => {
    const tempBladeStore = UseRECOILState(tempBitEvalCutterMap)

    /**
     * Он принимает объект резака, индекс лезвия и индекс резака, а затем помещает объект резака в состояние
     * лезвияCutterState.
     * @param cutter - объект резака, который переводится в состояние
     * @param bladeIndex - индекс блейда в массиве tempBladeStore.state
     * @param cutterIndex - индекс фрезы в массиве фрез
     */
    const pushToBladePrimaryCutterState = (cutter, bladeIndex, cutterIndex) => {
        let temp = [...tempBladeStore.state]
        temp[bladeIndex].primaryEvalCutters.splice(cutterIndex, 1, cutter)
        tempBladeStore.setState(temp)
        console.log(temp)
    }

    const pushToBladeSecondaryCutterState = (cutter, bladeIndex,cutterIndex) => {
        let temp = [...tempBladeStore.state];
        temp[bladeIndex].secondaryEvalCutters.splice(cutterIndex, 1, cutter);
        tempBladeStore.setState(temp)
        console.log(temp);
    }

    return {
        pushToBladePrimaryCutterState,
        pushToBladeSecondaryCutterState,
    }
}

export default useChangeBitEvalBladesState;