import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userRows } from "../../../../atoms/Administration_Atoms/atoms";
import { IconButton, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchRounded";
import RefreshIcon from "@mui/icons-material/RefreshRounded";
import useValidation from "../../../../hooks/useValidation";
import { toast } from "react-toastify";
function UserTextFieldWithButton() {
  const [textFieldValue, setTextFieldValue] = useState("");
  const [rows, setRows] = useRecoilState(userRows);
  const { isEmptyTextValidation } = useValidation();
  const [error, setError] = useState(false);

  const getAllUsers = (username = "") => {
    axios
      .get(`api/login/getUsers`, {
        params: { username },
      })
      .then((response) => {
        setRows(response.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Ошибка получения пользователей");
      });
  };
  const handleTextFieldChange = (e) => {
    const isEmpty = isEmptyTextValidation(e.target.value);
    console.log(e.target.value);
    setError(isEmpty);
    if (!isEmpty) {
      setTextFieldValue(e.target.value);
    }
  };
  const handleSearchButtonClick = () => {
    getAllUsers(textFieldValue);
  };
  const handleRefreshButton = () => {
    setTextFieldValue("");

    getAllUsers();
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <TextField
        size={"small"}
        variant={"outlined"}
        label={"Enter username"}
        value={textFieldValue}
        error={error}
        helperText={error ? "Please enter a valid username" : ""}
        onChange={handleTextFieldChange}
      />
      <IconButton onClick={handleSearchButtonClick}>
        <SearchIcon />
      </IconButton>
      <IconButton onClick={handleRefreshButton}>
        <RefreshIcon />
      </IconButton>
    </Stack>
  );
}

export default UserTextFieldWithButton;
