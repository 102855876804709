import React from "react";
import { Button, Card } from "@mui/material";
import Box from "@mui/material/Box";
import UseOrderingChangeBladeMaterial from "../../../../../../hooks/useOrderingChangeBladeMaterial";
import { orderingRows } from "../../../../../../atoms/Ordering_Atoms/OrderingAtoms";

import axios from "axios";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";

const ConfigureButtons = (props) => {
  const [rows, setRows] = useRecoilState(orderingRows);

  const { modalStore, orderId } = props;
  const { getBladeStore } = UseOrderingChangeBladeMaterial();
  // const workOrder = useRecoilValue(orderingWorkOrderAtom);

  const submitHandler = () => {
    const cutterMap = JSON.stringify(getBladeStore().state);
    let url = "/api/orders/setCutterMap";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const body = { orderId: orderId, cutterMap: cutterMap };
    setRows(rows.filter((row) => row.orderId !== orderId));
    axios.post(url, body, axiosConfig).then((response) => {
      console.log(response.status);
      if (response.status === 200) {
        toast.success("Cutter map saved");
        modalStore.setState(false);
        return;
      } else {
        toast.error("Bad cutter map or order id not found");
      }
    });
  };

  return (
    <Card
      variant={"outlined"}
      sx={{
        display: "flex",
        padding: 1,
        width: "100%",
      }}
    >
      <Box
        id={"firstButtonsBlock"}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Button
          size={"small"}
          variant={"outlined"}
          onClick={() => modalStore.setState(false)}
        >
          Cancel
        </Button>

        <Button
          size={"small"}
          variant={"outlined"}
          onClick={() => {
            submitHandler();
          }}
        >
          Submit
        </Button>
      </Box>
    </Card>
  );
};

export default ConfigureButtons;
