import React from "react";
import Box from "@mui/material/Box";
import { Button, TextareaAutosize, TextField, Typography } from "@mui/material";
import { selectedNotes } from "../../../../../atoms/BitEvalutionNotesAtoms/atoms";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { DbData, Order } from "../../../../../atoms/repairEntryFetchData";
import {
  bitEvalOrderObj,
  bitEvalSelectedDrill,
} from "../../../../../atoms/staticAtoms";

export const NotesList = ({ notes }) => {
  const [selectedNote, setSelectedNote] = useRecoilState(selectedNotes);
  const [selectedDrill, setSelectedDrill] =
    useRecoilState(bitEvalSelectedDrill);

  const orderObj = useRecoilValue(bitEvalOrderObj);

  return (
    <Box
      sx={{
        border: "1px solid #e0e0e0",
        width: "30%",
        borderRadius: "3px",
      }}
    >
      <Box
        sx={{
          overflowY: "scroll",
          height: "600px",
        }}
      >
        {notes.map((note, index) => {
          let border =
            note === selectedNote ? "2px solid #1976D2" : "1px solid #e0e0e0";
          border =
            note.Order === orderObj.OrderId ? "2px solid #FF0000" : border;

          return (
            <React.Fragment key={index}>
              <Box sx={{ margin: "10px" }}>
                <Box
                  sx={{
                    border: { border },
                    borderRadius: "4px",
                    margin: "10px",
                    padding: "10px",
                    ":hover": {
                      borderColor: "#1976D2",
                    },
                  }}
                  onClick={(e) => {
                    setSelectedNote("");
                    setSelectedNote(note);
                  }}
                >
                  <Typography sx={{ wordWrap: "break-word" }}>
                    {note.Title} - Order {note.Order}
                  </Typography>
                </Box>
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default NotesList;
