import { BrowserRouter, Route, Routes } from "react-router-dom";
import { appRoutes } from "./routes";
import { DrawerCustom } from "./components";
import TableExample from "./pages/Main/Table";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { RecoilRoot } from "recoil";
import Main from "./pages/Main";
import { LocationProvider } from "./utils/location_utils";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <LocationProvider>
          <ToastContainer
            position="bottom-left"
            newestOnTop={true}
            theme="colored"
            autoClose={1500}
          />{" "}
          {/*<ThemeProvider theme={darkTheme}>*/}
          <Routes>
            <Route path={"*"} element={<Main />} />
            {/*<Route path={'/'} element={<DrawerCustom/>}>
              <Route path={'table-example'} element={<TableExample/>}/>
          </Route>*/}
            {/*{appRoutes.map((route, index) => (
                            <Route key={index} {...route} element={<route.element/>}/>
                        ))}*/}
          </Routes>
        </LocationProvider>
        {/*</ThemeProvider>*/}
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
