import {Box, Typography} from "@mui/material";
import React from "react";

const TextItem = (props) => {
    /*
    *- : Margins : 
    *-
    *- ma - margin all
    *- 
    *- mx - margin left and right
    *- my - margin top and bottom
    *- 
    *- m<d> where d is direction (t - top, b - bottom, l - left, r - right)
    *- priority: ma > mx > my > m<d>
    *-
    *- : Other :
    * 
    *- bgcolor: background color of the box
    *- tcolor: text color
    *- text: text to be displayed
    *- 
    * */
    const ma = props.ma ? props.ma + "px" : "0"; // margin to all sides

    const mx = props.mx ? props.mx + "px" : ma; // margin to x axis
    const my = props.my ? props.my + "px" : ma; // margin to y axis

    let ml = props.ml ? props.ml + "px" : "0px";
    let mt = props.mt ? props.mt + "px" : "0px";
    let mr = props.mr ? props.mr + "px" : "0px";
    let mb = props.mb ? props.mb + "px" : "0px";

    if (ma !== "0") {
        ml = ma;
        mt = ma;
        mr = ma;
        mb = ma;
    } else {
        if (mx !== "0") {
            ml = mx;
            mr = mx;
        }
        if (my !== "0") {
            mt = my;
            mb = my;
        }
    }
    
    return (
        <Box sx={{
            backgroundColor: props.bcolor ? props.bcolor : "#dadada",
            borderRadius: "4px",
            padding: "10px",
            textAlign: "center",
            display: props.fullWidth !== undefined ? "inline-block" : "block",
            width: props.fullWidth !== undefined ? "calc(100% - 10px * 2)" : "auto",
            position: "relative",
            marginLeft : ml,
            marginRight : mr,
            marginBottom : mb,
            marginTop : mt,
            color: props.tcolor ? props.tcolor : "#000000",
        }}>
            <Typography variant={props.size}>{props.text}</Typography>
        </Box>
    )
}

export default TextItem;