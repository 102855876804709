import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { scrapCheckboxStore } from "../../../../../atoms/bitEvaluationatoms";
import { selectedTab } from "../../../../../atoms/DbAtoms/atoms";
import AddUserComponent, { UserAdd } from "../AddUserComponent";
import AddDrill from "../AddDrillComponent";
import AddCutters from "../AddCuttersComponent";
import AddMaterial from "../AddMaterialComponent";

const DbTabs = (props) => {
  const [tab, setTab] = useRecoilState(selectedTab);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Tabs
          value={tab}
          // textColor={"primary"}
          // indicatorColor={"pr"}
          onChange={handleChange}
        >
          <Tab value={"Users"} label="Пользователи" />
          <Tab value={"Drills"} label="ОЭ 1С" />
          <Tab value={"Cutters"} label="Резцы" />
          <Tab value={"Materials"} label="Артикул" />
          {/*<Tab value={'Locations'} label="Location"/>*/}
          {/*<Tab value={'Import'} label="Import"/>*/}
          <Tab value={"Scrap"} label="Причины списания" />
        </Tabs>
      </Box>
    </React.Fragment>
  );
};

export default DbTabs;
