import { useNavigate } from "react-router-dom"


const useDrawerLogic = (store) => {
    const navigate = useNavigate();

    const handleDrawerChangeState = () => {
        store.drawerState.setState(!store.drawerState.state);
    }

    const onClickLink = (url) => {
        navigate(url)
    }
    
    return {
        handleDrawerChangeState,
        onClickLink,
    }
}

export default useDrawerLogic;