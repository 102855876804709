import React from "react";
import { Avatar, Card, Tooltip } from "@mui/material";
import UseObjectState from "../../../../../../hooks/useObjectState";

const BladeTemplateAvatar = (props) => {
  const { pushToCutters, bladeIndex, index, dataGridState, cutter } = props;
  const avatarState = UseObjectState(cutter);
  console.log("index: " + index);
  const handleAvatarClick = () => {
    console.log(bladeIndex, index, dataGridState, cutter);
    if (dataGridState) {
      const replaceResults = pushToCutters(
        bladeIndex,
        index,
        dataGridState,
        cutter
      );
      console.log(dataGridState);
      switch (true) {
        case replaceResults.replacedAnother: {
          console.log(dataGridState);
          avatarState.setState(dataGridState.name);
          break;
        }
        case replaceResults.replacedYourself: {
          avatarState.setState("None");
          break;
        }
        default:
          break;
      }
    } else {
      alert("Пожалуйста, выберите резец");
    }
  };

  return (
    <React.Fragment>
      <Tooltip title={avatarState.state}>
        <Avatar
          onClick={handleAvatarClick}
          sx={{
            fontSize: 12,
          }}
        >
          {avatarState.state}
        </Avatar>
      </Tooltip>
    </React.Fragment>
  );
};

const BladeTemplateCuttersPrimary = (props) => {
  const { primary, secondary, max, cutters } = props.blade;
  const { dataGridState, pushToCutters, bladeIndex } = props;
  const maxArray = Array.from({ length: primary }, (_, i) => i + 1);
  return (
    <Card
      variant={"outlined"}
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${max},1fr)`,
        gap: 1,
        padding: 1,
      }}
    >
      {maxArray.map((number, i) => {
        return (
          <React.Fragment key={number}>
            <BladeTemplateAvatar
              index={i}
              dataGridState={dataGridState}
              bladeIndex={bladeIndex}
              pushToCutters={pushToCutters}
              cutter={cutters[0].primary[i]}
            />
          </React.Fragment>
        );
      })}
    </Card>
  );
};

export default BladeTemplateCuttersPrimary;
