import Box from "@mui/material/Box";
import React, {useEffect} from "react";
import {Button, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import {Image} from "@mui/icons-material";
import { DbData, Order } from "../../../../../atoms/repairEntryFetchData";


import {useRecoilState, useRecoilValue} from "recoil";
import {bitEvalOrderObj, bitEvalSelectedDrill} from "../../../../../atoms/staticAtoms";
import axios from "axios";

export const DocumentsList = ({docs }) => {

    const [images, setImage] = React.useState([]);
    const [orderImages, setOrderImages] = React.useState([]);
    const [selectedDrill, setSelectedDrill] = useRecoilState(bitEvalSelectedDrill);
    const orderObj = useRecoilValue(bitEvalOrderObj);
    const setImageFromEffect = (images) => {
        setImage(images);
    }
    
    const loadOrderImages = async () => {
        const response = await axios.get(`/api/DrillsControllers/getAllDrillDocuments`, { params: { serial: selectedDrill.Serial } });
        
        let tempImages = [];
        for (let i = 0; i < response.data.result.length; i++) {
            let temp = response.data.result[i].split("\\");
            let fileName = temp[temp.length - 1];
            let title = fileName.split(".")[0];
            let obj = {
                order: orderObj.OrderId,
                fileName: fileName,
                title: title,
            }
            tempImages.push(obj);
        }
        setOrderImages(tempImages);
    }
    
                

    

    useEffect(() => {
        // loadImages();
        
    }, [])
    
    const checkImage = (image) => {
        let name = image.fileName; 
        if (name) { 
            let afterDot = name.split(".")[1];
            if (afterDot === "jpg" || afterDot === "png" || afterDot === "jpeg")
                return (
                    <img src={`/Documents/${selectedDrill.Serial}/${name}`} width={"40%"}/>
                    )
        }
        
        return (
            <img alt='document' src={`https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/File_alt_font_awesome.svg/512px-File_alt_font_awesome.svg.png`}
                 width={"40%"} docurl={`/Documents/${selectedDrill.Serial}/${image.fileName}`}/>
        )
    }
    
    const downloadFile = (docUrl) => {
        window.open(docUrl);
    }
    
    return (
        <React.Fragment>
            <Box sx={{
                border: "1px solid #e0e0e0",
                borderRadius: "1px",
                padding: "10px",
                display: "flex",
                marginX: "auto",
                justifyContent: "center",
                alignItems: "center",
                width: "40%",
            }}>
                <Box>
                    <Typography variant={"h6"}>Documents List</Typography>
                    <Divider/>
                    <Box sx={{
                        maxHeight: "600px",
                        overflowY: "scroll"
                    }}>
                        {docs.map((image, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        border: "1px solid",
                                        borderColor: image.order === orderObj.OrderId ? "#ff0000" : "#e0e0e0",
                                        borderRadius: "3px",
                                        margin: "10px",
                                        padding: "10px",
                                    }}>
                                        {checkImage(image)}
                                        <Divider orientation={"vertical"} flexItem sx={{marginX: "10px"}}/>
                                        <Box sx={{
                                            marginX: "10px",
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            textAlign: "center",
                                            minHeight: "220px"
                                        }}>
                                            <Box sx={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                height: "50%",
                                                justifyContent: "center"
                                            }}>
                                                <Typography variant={"h6"}>Title</Typography>
                                                <Divider/>
                                                <Typography variant={"body1"}>{image.title}</Typography>
                                            </Box>
                                            <Box sx={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center"
                                            }}>
                                                <Typography variant={"h6"}>Order</Typography>
                                                <Divider/>
                                                <Typography variant={"body1"}>{image.order}</Typography>
                                                <Button variant={"contained"} sx={{marginTop: "10px"}} onClick={() => downloadFile(`/Documents/${selectedDrill.Serial}/${image.fileName}`)}>Download</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </React.Fragment>

                            )
                        })}
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    )
}
export default DocumentsList;