import * as React from "react";
import { styled } from "@mui/material/styles";
import { Outlet, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MailIcon from "@mui/icons-material/Mail";
import { CssBaseline, ListItemText, Toolbar, Typography } from "@mui/material";
import useDrawerState from "./hooks/useDrawerState";
import useDrawerLogic from "./hooks/useDrawerLogic";
import { useEffect } from "react";
import UserPanel from "../CustomUserPanel/CustomUserPanel";
import { useRecoilState } from "recoil";

import Logo from "../DrawerCustom/Burservice_logo_cyr_white.png";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerCustom = (props) => {
  const store = useDrawerState();
  const logic = useDrawerLogic(store);

  const links = props.pages;
    console.log(links);
  return (
    <Box id={"box-drawer-custom"} sx={{ display: "flex" }}>
      <AppBar open={store.drawerState.state}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={logic.handleDrawerChangeState}
            edge="start"
            sx={{
              marginRight: 5,
              ...(store.drawerState.state && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            display={"flex"}
            paddingRight={1}
            component={"img"}
            sx={{
              maxHeight: 15,
            }}
            alt={"logo"}
            src={Logo}
          />
          <Typography variant="h6" fontWeight={"bold"} noWrap component="div">
            Repair Bits & Drills Service
          </Typography>

          <UserPanel />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" id="array" open={store.drawerState.state}>
        <DrawerHeader>
          <IconButton onClick={logic.handleDrawerChangeState}>
            {store.drawerState.state ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {links.map((link, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => logic.onClickLink(link.url)}
                sx={{
                  minHeight: 48,
                  justifyContent: store.drawerState.state
                    ? "initial"
                    : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: store.drawerState.state ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <link.icon />
                </ListItemIcon>
                
                <ListItemText
                  primary={link.text}
                  sx={{ opacity: store.drawerState.state ? 1 : 0 }}
                />
              </ListItemButton> 
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default DrawerCustom;
