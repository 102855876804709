import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import LoginPage from "./components/Auth";
import CssBaseline from "@mui/material/CssBaseline";
import {ThemeProvider} from "@mui/material/styles";
import theme from "./theme/theme";
import {logout} from "./components/CustomUserPanel/CustomUserPanel";
import { useSearchParams } from "react-router-dom";
   

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App /> 
  </ThemeProvider>
);
