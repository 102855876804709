import {
  Autocomplete,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  AddCircleOutlineSharp,
  RemoveCircleOutlineSharp,
} from "@mui/icons-material";
import CutterCounter from "./CutterCounter";

function ChangeMaterialPackModel({ materialCode, modalStore }) {
  const [selectedCutter, setSelectedCutter] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [cutters, setCutters] = useState([]);
  const [counters, setCounters] = useState({
    materialCode: materialCode,
    cutters: [],
  });

  const addCounter = () => {
    const newCounter = { id: Date.now(), value: 1 };
    setCounters((prevCounters) => [...prevCounters, newCounter]);
  };

  const removeCounter = (counterId) => {
    setCounters((prevCounters) =>
      prevCounters.filter((counter) => counter.id !== counterId)
    );
  };

  useEffect(() => {
    console.log(materialCode);
    asyncGetCutters();
    asyncGetCutterPacks();
  }, []);
  const handleCounterChange = (counterId, value) => {
    setCounters((prevCounters) => ({
      materialCode: prevCounters.materialCode,
      cutters: prevCounters.cutters.map((counter) =>
        counter.id === counterId ? { ...counter, value } : counter
      ),
    }));
  };

  const submitButtonClickHandler = () => {
    console.log(counters);
    axios
      .post("api/material/setCuttersForMaterial", counters)
      .then((response) => {
        console.log(response);
        toast.success(
          `Набор резцов для артикула №${materialCode} обновлен успешно!`
        );
        modalStore.setState(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Ошибка обновления набора резцов.");
      });
  };
  const handleClose = () => {
    modalStore.setState(false);
  };
  const handleDeleteCounter = (counterId) => {
    setCounters((prevCounters) => ({
      materialCode: prevCounters.materialCode,
      cutters: prevCounters.cutters.filter(
        (counter) => counter.id !== counterId
      ),
    }));
  };
  const handleCutterAdd = (event, newValue) => {
    setSelectedCutter(newValue);
    console.log(newValue);
    if (
      newValue &&
      !counters.cutters.some((cutter) => cutter.id === newValue.id)
    ) {
      setCounters((prevCounters) => ({
        materialCode: prevCounters.materialCode,
        cutters: [
          ...prevCounters.cutters,
          { id: newValue.id, name: newValue.name.trim(), value: 1 },
        ],
      }));
    }
    console.log(counters);
  };

  const asyncGetCutters = async () => {
    axios
      .get(`api/cutters/getCutters`, {})
      .then((response) => {
        setCutters(response.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Ошибка получения резцов.");
      });
  };
  const asyncGetCutterPacks = async () => {
    axios
      .get(`api/material/getCutterPackByMaterial`, {
        params: {
          materialCode: materialCode,
        },
      })
      .then((response) => {
        setCounters(response.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Ошибка получения имеющегося набора резцов.");
      });
  };
  return (
    <Dialog open={modalStore.state} onClose={handleClose} fullWidth>
      <DialogContent sx={{ height: "80vh", overflowY: "hidden" }}>
        <Stack display={"flex"} direction={"column"}>
          <Stack
            display={"flex"}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Stack
              display={"flex"}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={1}
              mt={0.3}
              mb={0.3}
            >
              <Typography variant={"h5"}>Набор резцов</Typography>
            </Stack>
          </Stack>

          <Divider />
          <Stack
            padding={1}
            height={"100%"}
            alignItems={"space-between"}
            gap={1}
          >
            <Box sx={{ display: "flex" }}>
              <Autocomplete
                disablePortal
                value={selectedCutter}
                onChange={handleCutterAdd}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="combo-box"
                options={cutters}
                sx={{ width: 300 }}
                getOptionLabel={(cutter) => cutter.materialCode}
                getOptionKey={(cutter) => cutter.id}
                renderInput={(params) => (
                  <TextField {...params} label="Резцы" />
                )}
              />
              {/* <IconButton onClick={handleCutterAdd}>
                <AddCircleOutlineSharp fontSize="large" />
              </IconButton> */}
            </Box>
            <Stack
              padding={1}
              maxHeight={"50vh"}
              alignItems={"space-between"}
              gap={1}
              sx={{ overflowY: "auto" }}
            >
              {counters.cutters &&
                counters.cutters.map((counter) => (
                  <CutterCounter
                    key={counter.id}
                    counterId={counter.id}
                    value={counter.value}
                    onCounterChange={handleCounterChange}
                    title={counter.name}
                    onDelete={handleDeleteCounter}
                  />
                ))}
            </Stack>

            <Stack
              direction={"row"}
              display={"flex"}
              alignItems={"center"}
              mt={1}
              justifyContent={"space-between"}
            >
              <Button
                size={"medium"}
                color={"error"}
                onClick={() => modalStore.setState(false)}
                variant={"outlined"}
                // onClick={cancelButtonClickHandler}
              >
                Отменить
              </Button>
              <Button
                size={"medium"}
                color={"success"}
                variant={"outlined"}
                onClick={submitButtonClickHandler}
              >
                Сохранить
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default ChangeMaterialPackModel;
