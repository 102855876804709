import React, {useEffect} from 'react';
import Box from "@mui/material/Box";
import {OrderingHeaderLayout} from "./components/ordering-header";
import {OrderingTable} from "./components/ordering-table";


const Ordering = (props) => {
    useEffect(() => {
        document.title = "Ordering";
    });
    
    return (
        <Box
            
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                gap: 1,
                padding: 1,
                flexDirection: 'column',
            }}
        >
            <OrderingHeaderLayout/>
            <OrderingTable/>
        </Box>
    );
}

export default Ordering;