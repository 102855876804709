import React, {useEffect, useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import useRECOILState from "../../../../../../../hooks/useRECOILState";
import {
    scrapCheckboxStore,
    scrapSelectionStore
} from "../../../../../../../atoms/bitEvaluationatoms";
import {DbData, Scrap} from "../../../../../../../atoms/repairEntryFetchData";
import {useRecoilState, useRecoilValue} from "recoil";
import {bitEvalOrderObj, bitEvalSelectedDrill} from "../../../../../../../atoms/staticAtoms";

const ScrapSelect = (props) => {

    const [selectStore, setSelectStore] = useRecoilState(scrapSelectionStore);

    const [scrapReasonList, setScrapReasonList] = useState([]);

    const selectedDrill = useRecoilValue(bitEvalSelectedDrill);
    const scrapName = selectedDrill.ScrapName ? selectedDrill.ScrapName : "None";
    const isScrap = useRecoilValue(scrapCheckboxStore);
    
    const [allScrapReasons, setAllScrapReasons] = useState({}); 
    const orderObj = useRecoilValue(bitEvalOrderObj);
    
    useEffect(() => {
        let dbData = new DbData(Scrap.model, [Scrap.fields.Name, Scrap.fields.Code])
        dbData.sendRequest().then(() => {
            let tempAllScrapReasons = {};            
            dbData.result.forEach((scrapReason) => {
                tempAllScrapReasons[scrapReason[Scrap.fields.Name]] = scrapReason[Scrap.fields.Code];
            })
            setScrapReasonList(Scrap.getNames(dbData.result));
            setAllScrapReasons(tempAllScrapReasons);
        });
        
        if (scrapName !== null) {
            setSelectStore(scrapName);
        }
    }, []);

    const handleSelectChange = (e) => {
        setSelectStore(e.target.value);
        orderObj.ScrapReason = allScrapReasons[e.target.value];
    }

    return (
        <FormControl
            id={'Select Component'}
            sx={{
                display: 'flex',
            }}
        >
            <InputLabel>Select Scrap Reason</InputLabel>
            <Select
                value={selectStore}
                disabled={!isScrap}
                label={'Select Scrap Reason'}
                onChange={handleSelectChange}
                sx={{minWidth: 250}}
            >
                {scrapReasonList.map((item, index) => {
                        return (
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                        )
                    }
                )}
            </Select>
        </FormControl>
    );
}

export default ScrapSelect;