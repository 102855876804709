import {atom, selector} from 'recoil';

export const checkBoxStoreAtom = atom({
    key: 'checkBoxStoreAtom',
    default: false,
})

/* Атом состояния, содержащий текущую вкладку. */
const tabStoreAtom = atom({
    key: 'tabStoreAtom',
    default: 'Repair'
})

const repairsCompleteRows = atom({
    key: 'repairsCompleteRows',
    default: []
})

const repairsActiveRows = atom({
    key: 'repairsActiveRows',
    default: []
})


export {
    tabStoreAtom,
    repairsCompleteRows,
    repairsActiveRows
}