import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Alert,
} from "@mui/material";
const ConfirmationDialog = ({
  handleClose,
  handleConfirm,
  open,
  title,
  description,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      
      <DialogActions>
          <Button onClick={handleClose} autoFocus>
          Нет
        </Button>
        <Button onClick={handleConfirm}>Да</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
