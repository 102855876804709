import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Outlet, Route, Routes } from "react-router-dom";
import { DrawerCustom } from "../../components";
import RepairEntry from "./RepairEntry";
import TestDB from "./TestDB";
import UseObjectState from "../../hooks/useObjectState";
import UserPanel from "../../components/CustomUserPanel/CustomUserPanel";
import { BitEvalRepairForm } from "./BitEvaluation/BitEvalRepairForm";
import { BitEvaluation } from "./BitEvaluation";
import { CutterMap } from "./Administration/CutterMapping";
import { Archive } from "./Archive";
import { Ordering } from "./Ordering";
import { RepairOrderDetail } from "./Ordering/RepairOrderDetail";
import { useRecoilState } from "recoil";
import { testAtom } from "../../atoms/staticAtoms";
import { OrderConfigureCutterMapLayout } from "./Ordering/OrderConfigureCutterMap";
import Example from "./material-table";
import { Repairs } from "./Repairs";
import MainAppBar from "./MainAppBar";
import { Layout } from "../Layout";
import useObjectState from "../../hooks/useObjectState";
import { BitRun } from "./PDB/BitRun/BitRun";
import { PrivateRoute } from "../PrivateRoute";

let evaluationName = null;

const Main = () => {
  const dataGridStore = UseObjectState(null);
  if (dataGridStore.state) {
    evaluationName = dataGridStore.state.row["Serial"];
  } else {
    evaluationName = null;
  }
  useEffect(() => {
    document.title = "RMT";
  }, []);
  return (
    <Box
      display={"flex"}
      sx={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/bit-run" element={<BitRun />} />
          <Route path="/repairs" element={<Repairs />} />
          <Route path="/example" element={<Example />} />
          <Route
            path="/ordering/configure/:serial"
            exact={true}
            element={<OrderConfigureCutterMapLayout />}
          />
          <Route path="/ordering" exact={true} element={<Ordering />} />
          <Route path="/archive"  exact={true} element={<Archive />} />
          <Route path="/repair-entry" exact={true} element={<RepairEntry />} />
          <Route path="/TestDB" exact={true} element={<TestDB />} />
          <Route path="/cuttermap" element={<CutterMap />} />
          <Route path="/order" exact={true} element={<RepairOrderDetail />} />
          <Route
            path="/start/:serial"
            exact={true}
            element={<BitEvalRepairForm />}
          />
          <Route
            path="/bit-evaluation"
            exact={true}
            element={<BitEvaluation />}
          />
        </Route>
      </Routes>
    </Box>
  );
};

export default Main;
