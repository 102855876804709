import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import UseObjectState from "../../../hooks/useObjectState";
import {
  Button,
  Card,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { RepairDataGrid, RepairDialogInformation } from "./components";
import axios from "axios";
import { toast } from "react-toastify";

const RepairEntry = (props) => {
  const modalStore = UseObjectState(false);
  const textFieldStore = UseObjectState("");
  const dataGridStore = UseObjectState(null);
  const [error, setError] = useState(false);

  //   const [dbData, setDb] = useState("");
  const [textFieldValue, setTextFieldValue] = useState("");

  const [rows, setRows] = useState([]);

  const handleTextFieldChange = (e) => {
    setTextFieldValue(e.target.value);
  };
  const asyncGetDrills = async (textFieldValue) => {
    axios
      .get(`api/DrillsControllers/getDrills`, {
        params: { startsWithQuery: textFieldValue },
      })
      .then((response) => {
        setRows(response.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Ошибка получения долот.");
      });
  };
  useEffect(() => {
    document.title = "Repair Entry";
    asyncGetDrills(textFieldValue);
  }, [textFieldValue]);
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      height={"100%"}
    >
      {/* <RepairScan callbackDbData={handleDbDataChange} /> */}
      <Box
        sx={{
          display: "flex",
          padding: 1,
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box display={"flex"} padding={1}>
          <Card
            variant={"outlined"}
            sx={{
              padding: 1,
              background: "lightgrey",
              boxShadow: "20px",
            }}
          >
            <Typography variant={"h6"}>Введите номер оборудования</Typography>
          </Card>
        </Box>
        <TextField
          size={"small"}
          variant={"outlined"}
          label={"Серийный номер"}
          value={textFieldValue}
          error={error}
          helperText={error ? "Введите корректный серийный номер" : ""}
          onChange={handleTextFieldChange}
        />
      </Box>
      <Divider />
      <RepairDataGrid
        store={dataGridStore}
        modalStore={modalStore}
        rows={rows}
      />
      {modalStore.state && (
        <RepairDialogInformation
          store={modalStore}
          dataGridStore={dataGridStore}
          //   dbData={dbData}
        />
      )}
    </Box>
  );
};

export default RepairEntry;
