﻿import { bladeStore } from "../atoms/cutterMapState";
import UseRECOILState from "./useRECOILState";
import _ from "lodash";
import { useRecoilValue } from "recoil";
import {
  cutterDataGridStore,
  cutterMapRows,
} from "../atoms/cutterMapTextFieldsAtoms";
import { toast } from "react-toastify";

const useChangeBladesState = (props) => {
  const tempBladeStore = UseRECOILState(bladeStore);
  const dataGridState = UseRECOILState(cutterDataGridStore);
  const cutterRows = UseRECOILState(cutterMapRows);

  /**
   * Функция для добавления блейэда.
   * @param blade
   */
  const pushToBladesState = (blade) => {
    const temp = [...tempBladeStore.state];
    temp.push(blade);
    tempBladeStore.setState(temp);
  };

  /**
   * Он принимает массив объектов и для каждого объекта проверяет значение первичного и вторичного свойств и
   * устанавливает свойство матрицы равным большему из двух.
   * @param temp - массив объектов, содержащий первичные и вторичные значения
   * @param index - индекс текущего элемента в массиве
   */
  const checkMaxAndChange = (temp, index) => {
    const max = Math.max(
      parseInt(temp[index].primary),
      parseInt(temp[index].secondary)
    );
    temp[index].max = max;
  };

  /**
   * Он принимает индекс и добавляет первичный резак к лезвию с этим индексом.
   * @param index - индекс лезвия в массиве
   */
  const addPrimaryCutter = (index) => {
    let temp = [...tempBladeStore.state];
    temp[index].primary = (parseInt(temp[0].primary) + 1).toString();
    temp[index].cutters[0].primary.push("None");
    checkMaxAndChange(temp, index);
    tempBladeStore.setState(temp);
  };

  /**
   * Он удаляет последний первичный резак с последнего лезвия в массиве.
   * @param index - индекс лезвия в массиве
   */
  const removePrimaryCutter = (index) => {
    const temp = [...tempBladeStore.state];
    if (temp.length > 0) {
      temp[index].primary = (parseInt(temp[index].primary) - 1).toString();
      temp[index].cutters[0].primary.pop();
      checkMaxAndChange(temp, index);
      tempBladeStore.setState(temp);
    }
  };

  /**
   * Он добавляет вторичный резак к лезвию с указанным индексом.
   * @param index - индекс лезвия в массиве
   */
  const addSecondaryCutter = (index) => {
    const temp = [...tempBladeStore.state];
    temp[index].secondary = (parseInt(temp[index].secondary) + 1).toString();
    temp[index].cutters[1].secondary.push("None");
    const max = Math.max(
      parseInt(temp[index].primary),
      parseInt(temp[index].secondary)
    );
    if (max > temp[index].max) {
      temp[index].max = max;
    }
    tempBladeStore.setState(temp);
  };

  /**
   * Удаляет вторичный резец с лезвия по заданному индексу
   * @param index - индекс лезвия в массиве
   */
  const removeSecondaryCutter = (index) => {
    const temp = [...tempBladeStore.state];
    if (temp.length > 0) {
      temp[index].secondary = (parseInt(temp[index].secondary) - 1).toString();
      temp[index].cutters[1].secondary.pop();
      checkMaxAndChange(temp, index);
    }
  };

  const pushToCutters = (
    bladeIndex,
    cutterIndex,
    materialNumber,
    currCutter
  ) => {
    const temp = [...tempBladeStore.state];
    const tempCutterRows = _.cloneDeep(cutterRows.state);
    const tempDataGridRow = { ...dataGridState.state };
    console.log(dataGridState.state);
    const currentQty = parseInt(tempCutterRows[tempDataGridRow.index].value);
    console.log("Current Qty: " + currentQty);
    if (currentQty <= 0) {
      alert("Превышено максимальное количество резцов для назначения");
      return {
        replacedAnother: false,
        replacedYourself: false,
        replaceOverflow: true,
      };
    }

    const currentCutter = tempCutterRows.find((cutter) =>
      searchByCutterName(cutter, currCutter)
    );

    if (
      temp[bladeIndex].cutters[1].secondary[cutterIndex] !== materialNumber.name
    ) {
      temp[bladeIndex].cutters[1].secondary[cutterIndex] = materialNumber.name;
      tempCutterRows[tempDataGridRow.index].value = (currentQty - 1).toString();
      tempCutterRows[currentCutter.index].Used = (
        parseInt(currentCutter.Used) + 1
      ).toString();
      cutterRows.setState(tempCutterRows);
      tempBladeStore.setState(temp);
      return {
        replacedAnother: true,
        replacedYourself: false,
        replaceOverflow: false,
      };
    }

    temp[bladeIndex].cutters[1].secondary[cutterIndex] = "None";
    tempCutterRows[tempDataGridRow.index].value = (currentQty + 1).toString();
    tempCutterRows[currentCutter.index].Used = (
      parseInt(currentCutter.Used) - 1
    ).toString();
    cutterRows.setState(tempCutterRows);
    tempBladeStore.setState(temp);
    return {
      replacedAnother: false,
      replacedYourself: true,
      replaceOverflow: false,
    };
  };
  /**
   * Если имя резака совпадает с текущим именем резака, добавьте единицу к количеству резака.
   * @param cutter - Текущий объект резака в массиве
   * @param currCutter - Текущий резак, который ищется.
   * @returns Значение true, если имя резака совпадает с текущим именем резака.
   */
  const searchByCutterName = (cutter, currCutter) => {
    if (currCutter === "None") {
      return true;
    }
    if (cutter.name === currCutter) {
      cutter.value = (parseInt(cutter.value) + 1).toString();
      cutter.Used = (parseInt(cutter.Used) - 1).toString();
      return true;
    }
    return cutter.name === currCutter;
  };

  const updatePrimaryCutter = (blade, cutterIndex, materialName) => {
    if (blade.cutters[0].primary[cutterIndex] === materialName) {
      blade.cutters[0].primary.splice(cutterIndex, 1, "None");
      return "replacedYourself";
    } else if (!materialName) {
      return "replaceOverflow";
    } else {
      blade.cutters[0].primary.splice(cutterIndex, 1, materialName);
      return "replacedAnother";
    }
  };

  /*    const decrementCutterQuantity = (cutterRows, rowId) => {
              const quantity = parseInt(cutterRows[rowId].value);
              if (quantity > 0) {
                  cutterRows[rowId].value = (quantity - 1).toString();
                  return true;
              }
              return false;
          };*/

  const decrementUsedCutterQuantity = (cutterRows, rowId) => {
    const usedQuantity = replaceUsedNullNumber(cutterRows, rowId);
    console.log(usedQuantity);
    const quantity = parseInt(cutterRows[rowId].value);
    if (usedQuantity >= 0) {
      cutterRows[rowId].value = (quantity + 1).toString();
      cutterRows[rowId].Used = (usedQuantity - 1).toString();
      return true;
    }
    return false;
  };

  const incrementUsedCutterQuantity = (cutterRows, rowId) => {
    const quantity = parseInt(cutterRows[rowId].value);
    const usedQuantity = replaceUsedNullNumber(cutterRows, rowId);
    if (quantity > 0) {
      cutterRows[rowId].value = (quantity - 1).toString();
      cutterRows[rowId].Used = (usedQuantity + 1).toString();
      console.log(cutterRows);
      return true;
    }
    return false;
  };

  const replaceUsedNullNumber = (cutterRows, rowId) => {
    cutterRows[rowId].Used === undefined && (cutterRows[rowId].Used = 0);
    console.log(cutterRows[rowId]);
    return parseInt(cutterRows[rowId].Used);
  };

  const pushToPrimaryCutters = (
    bladeIndex,
    cutterIndex,
    materialNumber,
    currCutter
  ) => {
    const temp = [...tempBladeStore.state];
    const tempCutterRows = _.cloneDeep(cutterRows.state);
    const tempDataGridRow = { ...dataGridState.state };
    const rowId = parseInt(tempDataGridRow.index);

    const blade = temp[bladeIndex];
    const materialName = materialNumber.name || "None";

    const updateResult = updatePrimaryCutter(blade, cutterIndex, materialName);
    if (updateResult === "replacedYourself") {
      decrementUsedCutterQuantity(tempCutterRows, rowId);
      cutterRows.setState(tempCutterRows);
      tempBladeStore.setState(temp);
      return { [updateResult]: true };
    }

    if (updateResult === "replaceOverflow") {
      toast.error("Превышено максимальное количество резцов для назначения");
      return { [updateResult]: true };
    }

    incrementUsedCutterQuantity(tempCutterRows, rowId);
    const foundCutter = tempCutterRows.find((cutter) =>
      searchByCutterName(cutter, currCutter)
    );
    if (foundCutter) {
      cutterRows.setState(tempCutterRows);
      tempBladeStore.setState(temp);
      return { [updateResult]: true };
    }

    return { [updateResult]: true };
  };
  /*const pushToPrimaryCutters = (
          bladeIndex,
          cutterIndex,
          materialNumber,
          currCutter
      ) => {
          let switchBoolean = {
              replacedAnother: false,
              replacedYourself: false,
              replaceOverflow: false,
          };
          let temp = [...tempBladeStore.state];
          let tempCutterRows = _.cloneDeep(cutterRows.state);
          let tempDataGridRow = {...dataGridState.state};
          if (parseInt(tempCutterRows[parseInt(tempDataGridRow.id)].value) > 0) {
              if (
                  temp[bladeIndex].cutters[0].primary[cutterIndex] !== materialNumber.name
              ) {
                  temp[bladeIndex].cutters[0].primary.splice(
                      cutterIndex,
                      1,
                      materialNumber.name
                  );
                  tempCutterRows[parseInt(tempDataGridRow.id)].value = (
                      parseInt(tempCutterRows[parseInt(tempDataGridRow.id)].value) - 1
                  ).toString();
                  tempCutterRows.find((cutter) => {
                      if (searchByCutterName(cutter, currCutter)) return true;
                  });
                  cutterRows.setState(tempCutterRows);
                  tempBladeStore.setState(temp);
                  switchBoolean.replacedAnother = true;
                  console.log(tempBladeStore.state);
                  return switchBoolean;
              } else {
                  temp[bladeIndex].cutters[0].primary.splice(cutterIndex, 1, "None");
                  tempCutterRows.find((cutter) => {
                      if (searchByCutterName(cutter, currCutter)) return true;
                  });
                  cutterRows.setState(tempCutterRows);
                  tempBladeStore.setState(temp);
                  switchBoolean.replacedYourself = true;
                  console.log(tempBladeStore.state);
                  return switchBoolean;
              }
          } else if (
              parseInt(tempCutterRows[parseInt(tempDataGridRow.id)].value) === 0 &&
              temp[bladeIndex].cutters[0].primary[cutterIndex] === materialNumber.name
          ) {
              temp[bladeIndex].cutters[0].primary.splice(cutterIndex, 1, "None");
              tempCutterRows.find((cutter) => {
                  if (searchByCutterName(cutter, currCutter)) return true;
              });
              cutterRows.setState(tempCutterRows);
              tempBladeStore.setState(temp);
              switchBoolean.replacedYourself = true;
              console.log(tempBladeStore.state);
              return switchBoolean;
          } else alert("Превышено максимальное количество резцов для назначения");
          switchBoolean.replaceOverflow = true;
          console.log(tempBladeStore.state);
          return switchBoolean;
      };*/

  /**
   * Функция удаления последнего Blade из стора.
   */
  const removeLastBlade = () => {
    if (tempBladeStore.state.length > 0 && cutterRows.state.length > 0) {
      let temp = [...tempBladeStore.state];
      let tempCutterRows = _.cloneDeep(cutterRows.state);
      let tempBlade = temp.pop();
      tempBlade.cutters[0].primary.map((cutter, i) => {
        if (cutter === "None") {
        } else {
          tempCutterRows.find((rowCutter, i) => {
            if (rowCutter.name === cutter) {
              rowCutter.value = (parseInt(rowCutter.value) + 1).toString();
              rowCutter.Used = (parseInt(rowCutter.Used) - 1).toString();
              return true;
            }
          });
        }
      });
      tempBlade.cutters[1].secondary.map((cutter, i) => {});
      cutterRows.setState(tempCutterRows);
      tempBladeStore.setState(temp);
      toast.success("Successfully removed.");
    } else if (tempBladeStore.state.length > 0) {
      let temp = [...tempBladeStore.state];
      temp.pop();
      tempBladeStore.setState(temp);
      toast.success("Successfully removed.");
    } else toast.error("No blades for delete.");
  };

  return {
    removeLastBlade,
    pushToBladesState,
    pushToCutters,
    pushToPrimaryCutters,
    addPrimaryCutter,
    addSecondaryCutter,
    removePrimaryCutter,
    removeSecondaryCutter,
  };
};

export default useChangeBladesState;
