import {atom, selector} from "recoil";

/* Создание атома с ключом «Blade» и значением по умолчанию пустого массива. */
const cutterBladeNumber = atom({
    key: "Blade",
    default: [],
})

const cutterMaterialTextFieldStore = atom({
    key: 'materialTextFieldStore',
    default: '',
})

const cutterSerialNumberTextFieldStore = atom({
    key: 'serialNumberTextFieldStore',
    default: '',
})

const cutterPrimaryRowCheckboxStore = atom({
    key: 'primaryRowCheckboxStore',
    default: false,
})


const cutterPrimaryTextFieldStore = atom({
    key: 'primaryTextFieldStore',
    default: '0',
})

const cutterSecondaryRowCheckboxStore = atom({
    key: 'secondaryRowCheckboxStore',
    default: false,
})

/* Создание переменной состояния. */
const cutterSecondaryTextFieldStore = atom({
    key: 'secondaryTextFieldStore',
    default: '0',
})

/* Хранилище для сетки данных. */
const cutterDataGridStore = atom({
    key: 'dataGridStore',
    default: null,
})

/* Массив объектов. */
const cutterMapRows = atom({
    key: 'mapRows',
    default: [],
})

/* Селектор, который используется для получения значения элемента select. */
const cutterSelectStore = atom({
    key: 'selectStore',
    default: '2130',
})

/* Массив атомов. */
const cutterMapTextFieldsAtoms = atom({
    key: 'textFields',
    default: [cutterMaterialTextFieldStore],
});

/* Селектор, который используется для получения максимального количества резаков. */
const cutterMapMaxCuttersCount = selector({
    key: 'maxCuttersCount',
    get: ({get}) => {
        if (get(cutterPrimaryTextFieldStore) !== '' && get(cutterSecondaryTextFieldStore) !== '') {
            const primaryRow = parseInt(get(cutterPrimaryTextFieldStore));
            const secondaryRow = parseInt(get(cutterSecondaryTextFieldStore));

            if (primaryRow !== null && secondaryRow !== null) {
                return Math.max(primaryRow, secondaryRow);
            } else if (primaryRow === null && secondaryRow !== null) {
                return secondaryRow;
            } else if (primaryRow !== null && secondaryRow === null) {
                return primaryRow;
            }
        }
        if (get(cutterPrimaryTextFieldStore) !== '' && get(cutterSecondaryTextFieldStore) === '') {
            const primaryRow = parseInt(get(cutterPrimaryTextFieldStore))
            if (primaryRow) {
                return primaryRow
            } else return null;
        }
        if (get(cutterPrimaryTextFieldStore) === '' && get(cutterSecondaryTextFieldStore) !== '') {
            const secondaryRow = parseInt(get(cutterSecondaryTextFieldStore))
            if (secondaryRow) {
                return secondaryRow
            } else return null;
        }
    }
})

/* Создание массива чисел от 1 до максимального количества фрез. */
const cuttersMapArray = selector({
    key: 'cuttersMapArray',
    get: ({get}) => {
        let array = get(cutterMapMaxCuttersCount);
        if (array) {
            return Array.from({length: array}, (_, i) => i + 1);
        } else return null;
    }
})

/* Создание массива чисел от 1 до числа в текстовом поле. */
const cutterPrimaryArray = selector({
    key: 'cuttersPrimaryArray',
    get: ({get}) => {
        let array = parseInt(get(cutterPrimaryTextFieldStore))
        if (array) {
            return Array.from({length: array}, (_, i) => i + 1);
        } else return null;
    }
})

/* Создание массива чисел от 1 до числа в текстовом поле. */
const cutterSecondaryArray = selector({
    key: 'cuttersSecondaryArray',
    get: ({get}) => {
        let array = parseInt(get(cutterSecondaryTextFieldStore))
        if (array) {
            return Array.from({length: array}, (_, i) => i + 1)
        } else return null;
    }
})


export {
    cutterMaterialTextFieldStore,
    cutterMapTextFieldsAtoms,
    cutterSelectStore,
    cutterDataGridStore,
    cutterPrimaryRowCheckboxStore,
    cutterSecondaryRowCheckboxStore,
    cutterPrimaryTextFieldStore,
    cutterSecondaryTextFieldStore,
    cutterMapMaxCuttersCount,
    cuttersMapArray,
    cutterBladeNumber,
    cutterSecondaryArray,
    cutterPrimaryArray,
    cutterMapRows,
    cutterSerialNumberTextFieldStore
}


