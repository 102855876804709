import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import {RepairsHeader} from "./components/repairs-header";
import {CompleteTab, RepairsTab} from "./components/repairs-tab";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    repairsActiveRows,
    repairsCompleteRows,
    tabStoreAtom,
} from "../../../atoms/RepairsAtoms/repairsAtoms";
import useRECOILState from "../../../hooks/useRECOILState";
import {RepairsDetails} from "./components/repairs-detail";
import useObjectState from "../../../hooks/useObjectState";
import {DbData, Order} from "../../../atoms/repairEntryFetchData";
import {RepairsCompleteModal} from "./components/repairs-complete-modal";

const Repairs = (props) => {
    const tabValueStore = useRECOILState(tabStoreAtom);
    const modalStore = useObjectState(false);
    const completeModalStore = useObjectState(false);
    const datePickerStore = useObjectState(null);
    const textFieldStore = useObjectState('');
    const rowData = useObjectState(null);

    const [completeRows, setCompleteRows] = useRecoilState(repairsCompleteRows);
    const [repairsRows, setRepairsRows] = useRecoilState(repairsActiveRows);

    const getRepairsTab = async () => {
        const response = await fetch("/api/orders/getRowsRepairs");

        const data = await response.json();
        setRepairsRows(data.active);
        setCompleteRows(data.complete);
    };

    useEffect(() => {
        document.title = "Current Repairs";
        getRepairsTab();
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                padding: 1,
                gap: 1,
            }}
        >
            <RepairsHeader tabValueStore={tabValueStore} textFieldStore={textFieldStore}/>
            {tabValueStore.state === "Repair" ? (
                <RepairsTab modalStore={modalStore} rowData={rowData}/>
            ) : (
                <CompleteTab
                    completeModalStore={completeModalStore}
                    rowData={rowData}
                />
            )}
            {tabValueStore.state === "Repair" ? (
                <RepairsDetails modalStore={modalStore} rowData={rowData}/>
            ) : (
                <RepairsCompleteModal
                    datePickerStore={datePickerStore}
                    completeModalStore={completeModalStore}
                    rowData={rowData}
                />
            )}
        </Box>
    );
};

export default Repairs;