import React from "react";
import Box from "@mui/material/Box";
import {Card, Dialog, Typography} from "@mui/material";
import useObjectState from "../../../../../hooks/useObjectState";
import DetailsTabs from "./components/DetailsTabs";
import EvaluationContent from "./components/EvaluationContent";
import EvalutionNotesRepairs from "./components/NotesListRepairs";
import DocumentsListRepairs from "./components/DocumentListRepairs";

const RepairsDetails = (props) => {
    const {modalStore, rowData} = props;
    const tabsStore = useObjectState("Evaluation");
    return (
        <Dialog
            open={modalStore.state}
            onClose={() => modalStore.setState(false)}
            sx={{}}
            fullScreen
        >
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <Card
                    variant={"outlined"}
                    sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            padding: 1,
                            height: "100%",
                        }}
                    >
                        <DetailsTabs tabsStore={tabsStore} modalStore={modalStore}/>
                        <Card
                            variant={"outlined"}
                            sx={{
                                padding: 1,
                            }}
                        >
                            {tabsStore.state === "Evaluation" && (
                                <EvaluationContent rowData={rowData} history={false}/>
                            )}
                            {tabsStore.state === "Documents" && (
                                <DocumentsListRepairs
                                    drillSerial={props.rowData.state.row.serial}
                                />
                            )}
                            {tabsStore.state === "Notes" && (
                                <EvalutionNotesRepairs drillSerial={props.rowData.state.row.serial}/>
                            )}
                        </Card>
                    </Box>
                </Card>
            </Box>
        </Dialog>
    );
};

export default RepairsDetails;
