import React from "react";
import { Box } from "@mui/material";
import CutterMapSelect from "./CutterMapSelect";
import CutterMapDataGrid from "./CutterMapDataGridComponents/CutterMapDataGrid";
import { useRecoilState } from "recoil";
import { cutterMapRows } from "../../../../../atoms/cutterMapTextFieldsAtoms";

const ImageAndTableLayout = (props) => {

  return (
    <React.Fragment>
      <Box
        id={"ImageAndTableLayoutBox"}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          height: "100%",
          padding: 1,
          backgroundColor: "pink",
        }}
      >
        <CutterMapSelect />
        <CutterMapDataGrid />
      </Box>
    </React.Fragment>
  );
};

export default ImageAndTableLayout;
