import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { DbData, Drill } from "../../../../../../atoms/repairEntryFetchData";
import UseRECOILState from "../../../../../../hooks/useRECOILState";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import {
  bitEvalOrder,
  bitEvalOrderObj,
  bitEvalSelectedDrill,
} from "../../../../../../atoms/staticAtoms";
import TextItem from "../../../../OtherComponents/TextItem";
import axios from "axios";
import { toast } from "react-toastify";

const EvaluationTabHeader = (props) => {
  const [selectedDrill, setSelectedDrill] =
    useRecoilState(bitEvalSelectedDrill);
  const [locationName, setLocationName] = useState("");
  let navigate = useNavigate();

  const orderObj = useRecoilValue(bitEvalOrderObj);
  if (selectedDrill === null) {
    document.location.href = "/bit-evaluation";
  }
  const getLocationName = async () => {
    let url = `/api/location/getLocation`;
    await axios
      .get(url, { params: { locationCode: selectedDrill.Location } })
      .then((r) => {
        if (r.data.name) {
          setLocationName(r.data.name);
        } else {
          toast.warning("Cannot load location");
        }
      });
  };
  useEffect(() => {
    getLocationName();
  });

  console.log("EvaluationTabHeader");
  console.log(selectedDrill);

  return (
    <React.Fragment>
      <Box
        id={"EvaluationTabHeaderBox"}
        sx={{
          display: "flex",
          // justifyContent: 'center',
          // alignItems: 'center',
          // height: "100%",
          // width: "100%",
          // flexDirection: 'column',
          gap: 1,
          padding: 1,
          gridArea: "header",
        }}
      >
        <Card variant={"outlined"}>
          <CardContent>
            <Typography variant={"h4"}>
              {locationName ? locationName : "Not found Location"}
            </Typography>
            <Typography variant={"h6"}>
              Location code: {selectedDrill.Location}
            </Typography>
          </CardContent>
        </Card>
        <Card variant={"outlined"}>
          <CardContent>
            <TextItem size={"h6"} text={"Номер ордера"} />
            <TextItem size={"h6"} text={orderObj.OrderId} mt={10} />
          </CardContent>
        </Card>
        {orderObj.DrillSerial !== null && (
          <Card variant={"outlined"}>
            <CardContent>
              <TextItem size={"h6"} text={"Сериальный номер долота"} />
              <TextItem size={"h6"} text={orderObj.DrillSerial} mt={10} />
            </CardContent>
          </Card>
        )}
        {selectedDrill.Material !== null && (
          <Card variant={"outlined"}>
            <CardContent>
              <TextItem size={"h6"} text={"Материальный номер долота"} />
              <TextItem size={"h6"} text={selectedDrill.Material} mt={10} />
            </CardContent>
          </Card>
        )}
        {selectedDrill.Size !== null && (
          <Card variant={"outlined"}>
            <CardContent>
              <TextItem size={"h6"} text={"Размер долота"} />
              <TextItem size={"h6"} text={selectedDrill.Size} mt={10} />
            </CardContent>
          </Card>
        )}
        {selectedDrill.Type !== null && (
          <Card variant={"outlined"}>
            <CardContent>
              <TextItem size={"h6"} text={"Тип долота"} />
              <TextItem size={"h6"} text={selectedDrill.Type} mt={10} />
            </CardContent>
          </Card>
        )}
      </Box>
    </React.Fragment>
  );
};

export default EvaluationTabHeader;
