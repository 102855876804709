import axios from "axios";
  import { toast } from "react-toastify";


export function urlSetParam(url, param) {
  for (let i = 0; i < param.length; i++) {
    url = url.replace(`%${i}`, param[i]);
  }
  return url;
}

class Scrap {
  static model = "Scrap";
  static fields = { Name: "Name", Code: "Code" };

  static getNames(data) {
    let names = [];

    for (let i = 0; i < data.length; i++) {
      names.push(data[i].Name);
    }

    return names;
  }

  static async getCode(name) {
    let dbData = new DbData(
      Scrap.model,
      [Scrap.fields.Code, Scrap.fields.Name],
      Scrap.fields.Name,
      name
    );
    await dbData.sendRequest();
    return dbData.result[0].Code;
  }
}

class Order {
  static model = "Order";

  static fields = {
    Id: "OrderId",
    Serial: "DrillSerial",
    RepairType: "RepairTypeCard",
    Scrap: "Scrap",
    ScrapReason: "ScrapReason",
    RepairLevel: "RepairLevel",
    Notes: "FieldNotes",
    Images: "Images",
    CutterMap: "CutterMap",
  };

  static requestUrlSetOrder = "/api/orders/setOrder";
  static requestUrlSetImages = "/api/orders/setImage";
  static requestUrlSetNotes = "/api/orders/setNotes";
  static requestUrlCreateOrder = "/api/orders/CreateOrder?serial=%0";
  static requestUrlRemoveOrder = "/api/orders/removeOrder?id=%0";

  static removeOrder(orderId) {
    let url = urlSetParam(Order.requestUrlRemoveOrder, [orderId]);
    fetch(url).then((response) => {
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    });
  }

  static setOrder(
    orderId,
    drillSerial,
    repairTypeCard,
    repairLevel,
    scrap,
    scrapReason,
    scrapDate,
    cutterMap
  ) {
    let requestParam = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        OrderId: orderId,
        DrillSerial: drillSerial,
        RepairTypeCard: JSON.stringify(repairTypeCard),
        RepairLevel: repairLevel,
        Scrap: scrap,
        ScrapReason: scrapReason,
        ScrapDate: scrapDate,
        CutterMap: JSON.stringify(cutterMap),
      }),
    };
    fetch(Order.requestUrlSetOrder, requestParam).then((response) => {
      if (response.ok) {
        console.log("Order is set");
      } else {
        console.log("Order is not set");
      }
    });
  }

  static createOrder(serial) {
    let url = urlSetParam(Order.requestUrlCreateOrder, [serial]);

    return fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return null;
      }
    });
  }

  static setImages(orderId, images) {
    let requestParam = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        OrderId: orderId,
        Images: JSON.stringify(images),
      }),
    };
    fetch(Order.requestUrlSetImages, requestParam).then((response) => {
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    });
  }

  static setNotes(orderId, notes) {
    let requestParam = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        OrderId: orderId,
        Notes: JSON.stringify(notes),
      }),
    };
    fetch(Order.requestUrlSetNotes, requestParam).then((response) => {
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    });
  }
}

class Drill {
  static model = "Drills";

  static fields = {
    Serial: "Serial",
    Material: "MaterialCode",
    Size: "Size",
    Type: "Type",
    Location: "LocationCode",
    Scrap: "Scrap",
    ScrapCode: "ScrapCode",
    Map: "CutterMap",
  };

  static requestUrlSetScrap = `/api/DrillsControllers/setScrap?serial=%0&scrapName=%1&scrapDate=%2`;
  static requestUrlSetOrUpdate = "/api/DrillsControllers/setOrUpdate";
  static requestUrlRemoveCutterMap =
    "/api/DrillsControllers/removeCutterMap?serial=%0";
  static requestUrlRemoveDrill = "/api/DrillsControllers/removeDrill?serial=%0";
  static requestUrlUpgradeCutterMap = `/api/DrillsControllers/UpgradeCutterMap?serial=%0&order=%1`;

  static removeDrill(serial) {
    let requestUrl = urlSetParam(Drill.requestUrlRemoveDrill, [serial]);
    return fetch(requestUrl);
  }

  static removeCutterMap(serial) {
    let url = urlSetParam(Drill.requestUrlRemoveCutterMap, [serial]);
    return fetch(url);
  }

  static async setCutterMap(serial, cutterMap) {
    let requestParams = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Serial: serial,
        CutterMap: JSON.stringify(cutterMap),
      }),
    };

    return fetch("/api/DrillsControllers/setCutterMap", requestParams);
  }

  static upgradeCutterMap = (serial, orderId) => {
    let upgradeUrl = urlSetParam(Drill.requestUrlUpgradeCutterMap, [
      serial,
      orderId,
    ]);
    fetch(upgradeUrl).then((response) => {
      if (response.ok) {
        console.log("Cutter map upgraded");
      } else {
        console.log("Cutter map not upgraded");
      }
    });
  };

  static async addDrill(
    serial,
    locationCode,
    materialCode,
    size,
    type,
    scrap,
    scrapName = null,
    CutterMap
  ) {
    let scrapCode =
      scrap && scrapName != null ? await Scrap.getCode(scrapName) : null;

    if (scrap && scrapName == null) {
      console.log("Scrap code is empty");
      return;
    }

    let requestParams = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Serial: serial,
        MaterialCode: materialCode,
        Size: size,
        Type: type,
        Scrap: scrap,
        ScrapCode: scrapCode,
        LocationCode: locationCode,
        CutterMap: JSON.stringify(CutterMap),
      }),
    };

    return fetch(Drill.requestUrlSetOrUpdate, requestParams);
  }

  static setScrap(serial, scrapName, scrapDate = null) {
    let url = urlSetParam(Drill.requestUrlSetScrap, [
      serial,
      scrapName,
      scrapDate ? scrapDate : new Date().toISOString().slice(0, 10),
    ]);
    fetch(url).then((response) => {
      if (response.ok) {
        console.log("OK");
      }
    });
  }

  static getRepairCount(serial) {
    let dbData = new DbData(
      Order.model,
      ["DrillSerial"],
      "DrillSerial",
      serial
    );
    let result = null;
    dbData.sendRequest().then(() => {
      result = dbData.result;
    });

    return result.length;
  }
}

/**
 * Класс для работы с резцами
 * @class Cutter - класс для работы с резцами в базе данных.
 * @property {string} model - название модели в базе данных
 * @property {object} fields - поля модели в базе данных
 * @property {string} urlAddCutter - url для добавления резца в базу данных
 * @property {string} urlRemoveCutter - url для удаления резца из базы данных
 * @function addCutter - добавление резца в базу данных
 * @function removeCutter - удаление резца из базы данных
 * @example - Примеры использования добавления и удаления резца
 * Material.addMaterial('M1', [{name: 'C1', count: 10}, {name: 'C2', count: 5}]) // добавление материала M1 с двумя резцами C1 и C2 в количестве 10 и 5 штук соответственно
 * Material.removeMaterial('M1') // удаление материала M1
 */
class Material {
  static model = "Material";
  static fields = { Name: "Name", Codes: "CuttersCodes" };

  // static ast = fetch('api/material/addMaterial')
  static urlAddMaterial = "/api/material/addMaterial";
  static urlRemoveMaterial = "/api/material/removeMaterial?material=%0";

  /**
   * Функция добавления материала в базу данных.
   * @param matCode - код материала
   * @param codes - массив объектов с кодами и количеством резцов
   * @example
   * Material.addMaterial('M1', [{name: 'C1', count: 10}, {name: 'C2', count: 5}]) // добавление материала M1 с двумя резцами C1 и C2 в количестве 10 и 5 штук соответственно
   * @returns {Promise<void>}
   * @constructor
   * @memberOf Material
   * @method addMaterial - добавление материала в базу данных
   */
  static addMaterial(matCode, codes) {
    let requestParam = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Code: matCode,
        CuttersCodes: JSON.stringify(codes),
        // CutterMap: JSON.stringify({})
      }),
    };
    fetch(Material.urlAddMaterial, requestParam).then((response) => {
      if (response.ok) {
        toast.success("Материал успешно добавлен.");
        console.log("OK");
      } else {
        toast.error("Ошибка при добавлении материала.");
        console.log(response.json());
      }
    });
  }

  static removeMaterial(matCode) {
    let url = urlSetParam(Material.urlRemoveMaterial, [matCode]);
    fetch(url).then((response) => {
      if (response.ok) {
        toast.success("Материал успешно удален.");
        console.log("OK");
      } else {
        console.log(response.json());
        toast.error("Ошибка при удалении материала.");
      }
    });
  }
}

class User {
  static model = "User";

  static fields = { Name: "Name", Role: "Role" };
  static fieldsForRepairEntry = ["Name", "Role"];

  static requestUrlGetUsers = "/api/DataTransferControllers/getData";
  static requestUrlSetOrUpdUser = "/api/login/setOrUpdate"; // TODO: change to /api/user/setOrUpdate
  static requestUrlDeleteUser = "/api/login/removeUser"; // TODO: change to /api/user/deleteUser

  static addUser(name, role, password) {
    if (!name || !role || !password) {
      console.log("Empty field");
      return;
    }

    let url = "/api/login/register";
    let requestParamSetUser = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Name: name,
        role: role,
        password: password,
      }),
    };

    fetch(url, requestParamSetUser)
      .then((response) => {
        if (response.ok) {
          return new Object({ result: "Успешно" });
        } else if (response.status === 401) {
          return new Object({ result: "У вас нет прав" });
        }

        return response.json();
      })
      .then((data) => {
        console.log(data.result);
      });
  }

  static setOrUpdateUser(name, role) {
    let requestParamSetUser = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Name: name,
        Role: role,
      }),
    };

    return fetch(User.requestUrlSetOrUpdUser, requestParamSetUser);
  }

  static removeUser(name) {
    let requestParamSetUser = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Name: name,
      }),
    };

    return fetch(User.requestUrlDeleteUser, requestParamSetUser);
  }
}

class Cutters {
  static model = "Cutter";
  static fields = {
    Name: "Name",
    Type: "Type",
    Code: "MaterialCode",
    Interchangeble: "Interchangeble",
    Description: "Description",
  };

  static requestUrlSetCutters = "/api/cutters/setCutter";
  static requestUrlSetOrUpdCutter = "/api/cutters/setOrUpdate";
  static requestUrlDeleteCutter = "/api/cutters/removeCutter?name=%0";

  static addCutters(name, type, code, interchangeble) {
    let requestParam = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Name: name,
        Type: type,
        MaterialCode: code,
        Interchangeble: interchangeble,
      }),
    };
    fetch(Cutters.requestUrlSetCutters, requestParam).then((response) => {
      if (response.ok) {
        console.log("OK");
      } else {
        console.log(response.json());
      }
    });
  }

  /**
   * Добавляет или обновляет резец
   * @param name - название резца
   * @param type - тип резца
   * @param code - код материала
   * @param interchangeble - массив с названиями резцов, с которыми можно взаимозаменять данный резак
   * @param description - описание резака
   * @returns {Promise<Response>} - ответ сервера на запрос
   * @example
   * Cutters.setOrUpdateCutter("Резец 1", "Тип 1", "Код материала", ["Резец 2", "Резец 3"], "Описание резца")
   * .then((response) => {
   *    if (response.ok) {
   *    console.log('OK');
   *    } else {
   *    console.log(response.json());
   *    }
   *    });
   */
  static setOrUpdateCutter(name, type = "", code, interchangeble, description) {
    let requestParam = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Name: name,
        Type: type,
        MaterialCode: code,
        Description: description,
        Interchangeble: JSON.stringify(interchangeble),
      }),
    };

    return fetch(Cutters.requestUrlSetOrUpdCutter, requestParam);
  }

  static removeCutter(name) {
    let url = urlSetParam(Cutters.requestUrlDeleteCutter, [name]);
    return fetch(url);
  }

  static removeCutters(names) {
    let promises = [];
    for (let name of names) {
      promises.push(Cutters.removeCutter(name));
    }
    return Promise.all(promises);
  }
}

class DbData {
  url = "/api/DataTransferControllers/getData";
  result = null;

  constructor(model, fields, pkName = null, pkValue = null) {
    this.model = model;
    this.fields = fields;
    this.pkName = pkName;
    this.pkValue = pkValue;

    this.requestParam = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: this.model,
        fields: this.fields,
        primaryKeyName: this.pkName,
        primaryKeyValue: this.pkValue,
      }),
    };

    this.checkParam();
  }

  checkParam() {
    if (this.pkName != null && this.pkValue == null) {
      throw new Error("Primary key value is null");
    }

    if (
      this.pkName == null &&
      this.pkValue != null &&
      !this.fields.contains(this.pkName)
    ) {
      throw new Error("Primary key name is not in fields");
    }
  }

  async sendRequest() {
    return await fetch(this.url, this.requestParam)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.result = data["result"];
      });
  }
}

export { DbData, Scrap, Drill, User, Order, Cutters, Material };
