import React from "react";
import {
  Box,
  CardContent,
  CardHeader,
  Typography,
  Button,
  CardActions,
} from "@mui/material";
import {
  AddCircle,
  Delete,
  FileUpload,
  TextSnippet,
} from "@mui/icons-material";
import axios from "axios";
import { StyledCard } from "./Import";
import { StyledProgress } from "./Import";
import { toast } from "react-toastify";

const CuttersImport = () => {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [loaded, setLoaded] = React.useState(0);
  const [isUpload, setIsUpload] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const textFieldFocusRef = React.useRef(null);
  let interval;

  const changeHandler = (event) => {
    if (
      event.target.files[0].type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setSelectedFile(event.target.files[0]);
      setIsError(false);
      textFieldFocusRef.current.focus();
    } else {
      setIsError(true);
    }
  };

  const getProgress = async (interval) => {
    if (progress === 100) {
      clearInterval(interval);
    }
    const res = await axios.get(`api/Excel/getProgress`);
    setProgress(res.data);
  };

  const stopUpload = (interval) => {
    clearInterval(interval);
    setProgress(0);
    setLoaded(0);
    setIsUpload(false);
  };

  const importCutters = async () => {
    try {
      const data = new FormData();
      data.append("file", selectedFile);
      interval = setInterval(async () => await getProgress(interval), 1000);
      const res = await axios.post(`/api/Excel/importCuttersExcel`, data);
      console.log(res);
      stopUpload(interval);
      toast.success("Import was successful");
    } catch (e) {
      console.log(e);
      stopUpload(interval);
      toast.error(`Error: ${e.message}`);
    }
  };

  return (
    <StyledCard variant="outlined">
      <CardHeader
        title="Import Cutters"
        subheader={
          <Typography variant="body2" color="text.primary">
            Import the excel file with the data to be added to the database
            <br />
            The file must be in the .xlsx format
          </Typography>
        }
      />
      {selectedFile && (
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="body2"
              color="text.primary"
              sx={{
                color: (theme) => theme.palette.text.secondary,
                fontWeight: 600,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                "&:hover": {
                  overflow: "visible",
                  whiteSpace: "normal",
                  cursor: "pointer",
                  color: (theme) => theme.palette.primary.main,
                },
              }}
              onClick={() => {
                toast.success("Скопировано в буфер обмена");
                navigator.clipboard.writeText(selectedFile.name);
              }}
              ref={textFieldFocusRef}
            >
              {selectedFile.name}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Delete />}
              onClick={() => setSelectedFile(null)}
            >
              Clear
            </Button>
          </Box>
        </CardContent>
      )}

      <StyledProgress variant="determinate" value={progress} />
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          component="label"
          startIcon={<AddCircle />}
        >
          Choose file
          <input type="file" hidden onChange={changeHandler} />
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedFile}
          onClick={importCutters}
          startIcon={<FileUpload />}
        >
          Import
        </Button>
        {isError && (
          <Typography variant="body2" color="error">
            The file must be in the .xlsx format
          </Typography>
        )}
      </CardActions>
    </StyledCard>
  );
};

export default React.memo(CuttersImport);
