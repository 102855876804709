import React, {FC, useMemo} from 'react';
import MaterialReactTable from 'material-react-table';
import {Box} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";

/* Массив объектов. */
export const data = [
    {
        firstName: 'Dylan',
        lastName: 'Murray',
        address: '261 Erdman Ford',
        city: 'East Daphne',
        state: 'Kentucky',
        subRows: [
            {
                firstName: 'Ervin',
                lastName: 'Reinger',
                address: '566 Brakus Inlet',
                city: 'South Linda',
                state: 'West Virginia',
                subRows: [
                    {
                        firstName: 'Jordane',
                        lastName: 'Homenick',
                        address: '1234 Brakus Inlet',
                        city: 'South Linda',
                        state: 'West Virginia',
                    },
                ],
            },
            {
                firstName: 'Brittany',
                lastName: 'McCullough',
                address: '722 Emie Stream',
                city: 'Lincoln',
                state: 'Nebraska',
            },
        ],
    },
    {
        firstName: 'Raquel',
        lastName: 'Kohler',
        address: '769 Dominic Grove',
        city: 'Columbus',
        state: 'Ohio',
        subRows: [
            {
                firstName: 'Branson',
                lastName: 'Frami',
                address: '32188 Larkin Turnpike',
                city: 'Charleston',
                state: 'South Carolina',
            },
        ],
    },
];

const Example = () => {
    /* Запоминаемый массив определений столбцов. */
    const columns = useMemo(
        //column definitions...
        () => [
            {
                accessorKey: 'firstName',
                header: 'First Name',
            },
            {
                accessorKey: 'lastName',
                header: 'Last Name',
            },

            {
                accessorKey: 'address',
                header: 'Address',
            },
            {
                accessorKey: 'city',
                header: 'City',
            },

            {
                accessorKey: 'state',
                enableColumnOrdering: false,
                header: 'State',
            },
        ],
        [],
        //end
    );

    const customTheme = useMemo(
        () => 
            createTheme({
                components: {
                    MuiPaper: {
                        styleOverrides:{
                            root:{
                                display: 'flex',
                            }
                        }
                    }
                }
            })
    )
    
    return (
            <MaterialReactTable
                columns={columns}
                data={data}
                enableColumnResizing={true}
                enableExpanding
                enableExpandAll={false}
                initialState={{expanded: true}} //expand all rows by default
                paginateExpandedRows={false}
            />
    );
};

export default Example;
