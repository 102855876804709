import React, { useEffect, useState } from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { AccountBox } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import { DbData, User } from "../../atoms/repairEntryFetchData";
import { useRecoilCallback } from "recoil";
import { savedPages } from "../../atoms/staticAtoms";

const UserPanel = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [role, setRole] = React.useState("");
  const [LogoutLink, setLogoutLink] = useState("");
  const resetState = useRecoilCallback(({ reset }) => () => {
    reset(savedPages);
    
  });
  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    resetState();
    if (LogoutLink) {
      window.location.replace(LogoutLink);
    }
  };
  const userName = sessionStorage.getItem("userName");
  const roleCode = sessionStorage.getItem("role");

  useEffect(() => {
    fetch("/api/login/GetLogoutLink")
      .then((data) => data.json())
      .then((dataJson) => {
        setLogoutLink(dataJson.link);
      });
    let dbData = new DbData("Role", ["Code", "Name"], "Code", roleCode);
    dbData.sendRequest().then(() => {
      setRole(dbData.result[0]["Name"]);
    });
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box position={"fixed"} right={0}>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color={"inherit"}
      >
        <AccountBox fontSize={"large"} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box padding={"10px"} width={"150px"}>
          <Typography variant={"h6"}>{userName}</Typography>
          <Typography variant={"subtitle1"}>{role}</Typography>
          <Divider />
          <br />
          <Button
            variant={"contained"}
            color={"error"}
            fullWidth
            size={"medium"}
            onClick={logout}
          >
            LogOut
          </Button>
        </Box>
      </Menu>
    </Box>
  );
};

export default UserPanel;
