import React from 'react';
import {Box} from "@mui/material";
import {RepairOrderHeader} from "./components/repair-order-header";
import RepairOrderTable from "./components/repair-order-table/RepairOrderTable";
import {RepairOrderLayout} from "./components/repair-order-table";

const RepairOrderDetail = (props) => {
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 1,
                gap: 1,
            }}
        >
            <RepairOrderHeader/>
            <RepairOrderLayout/>
        </Box>
    );
}




export default  RepairOrderDetail;