import React, {useEffect} from 'react';
import {Box, Card} from "@mui/material";
import CuttersCard from "./CuttersCard";
import {useRecoilState, useSetRecoilState} from "recoil";
import {bitEvalCutterMap, bitEvalOrder, tempBitEvalCutterMap} from "../../../../../../atoms/staticAtoms";
import {DbData, Order} from "../../../../../../atoms/repairEntryFetchData";

const CuttersTabLayout = (props) => {
   

    return (
        <>
            <Card variant={"outlined"}
                  sx={{
                      padding: 1,
                  }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        overflow: 'auto',
                        gap: 1,
                        // padding: 1,
                    }}
                >
                    <CuttersCard/>
                </Box>
            </Card>

        </>
    );
}

export default CuttersTabLayout;