import React, {useEffect} from 'react';
import {Box, Card} from "@mui/material";
import EvaluationTabHeader from "./EvaluationTabHeader";
import EvaluationTabScrap from "./EvaluationTabScrap";
import {RepairLevelComponent, RepairTypeComponent} from "./components";
import EvaluationTabFooter from "./EvaluationTabFooter";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    bitEvalCutterMap,
    bitEvalOrder,
    bitEvalOrderObj,
    tempBitEvalCutterMap
} from "../../../../../../atoms/staticAtoms";
import {DbData, Order} from "../../../../../../atoms/repairEntryFetchData";

const EvaluationTab = (props) => {
    
    const orderObj = useRecoilValue(bitEvalOrderObj);
   
    useEffect(() => {
        if(orderObj.OrderId === null){
            window.location.href = "/bit-evaluation";
        }
    },[])
    
    return (
        <Card variant={"outlined"}>
            <Box
                id={'EvaluationTabBox'}
                sx={{
                    display: 'grid',
                    gridTemplateColumns: `5fr 1fr`,
                    gridTemplateRows: `repeat(3,1fr) 0.5fr`,
                    gridTemplateAreas: `"header ."
                        "scrap level"
                        "type ."
                        "save submit"`,
                    // height: "100%",
                    // width: "100%",
                    // backgroundColor: 'pink',
                    padding: 1,
                    gap: 1,
                }}
            >
                <EvaluationTabHeader serial={props.serial}/>
                <EvaluationTabScrap/>
                <RepairLevelComponent/>
                <RepairTypeComponent/>
                <EvaluationTabFooter/>
            </Box>
        </Card>
    );
}

export default EvaluationTab;