import {useRecoilState} from "recoil";
import {selectedNotes} from "../../../../../atoms/BitEvalutionNotesAtoms/atoms";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import Divider from "@mui/material/Divider";


export const FullNotes = (props) => {
    const [selectedNote, setSelectedNote] = useRecoilState(selectedNotes);
    return (
        <Box sx={{
            marginLeft: "10px",
            border: "1px solid #e0e0e0",
            borderRadius: "3px",
            width: "70%",
        }} >
            <Typography variant={'h4'} sx={{padding: "10px"}}>{selectedNote.Title}</Typography>
            <Divider />
            <Typography component='pre' variant={'h5'} sx={{padding: "10px"}}>{selectedNote.Text}</Typography>
        </Box>
    )
}

export default FullNotes;