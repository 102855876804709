import {useRecoilState} from "recoil";


/**
 * Кастомный хук, который возвращает объект с двумя свойствами: `state` и `setState`
 * @param recoilId - key атома, который вы хотите использовать.
 * @returns Объект с двумя свойствами: state и setState.
 */
const useRECOILState = (recoilId) => {
    const [state,setState] = useRecoilState(recoilId);
    
    return {
        state: state,
        setState: setState,
    }
}

export default useRECOILState;