import React from 'react';
import useRECOILState from "../../../../../../../hooks/useRECOILState";
import {
    bitEvalCheckBoxRepairTypeStore,
    bitEvalGetAllRepairTypesState
} from "../../../../../../../atoms/bitEvaluationatoms";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import UseRepairTypeStateChange from "../../../../../../../hooks/useRepairTypeStateChange";
import {useRecoilState, useRecoilValue} from "recoil";
import {bitEvalOrderObj} from "../../../../../../../atoms/staticAtoms";

const RepairTypeCheckboxes = (props) => {
    const changeRepairType = UseRepairTypeStateChange();
    const checkboxStates = useRecoilValue(bitEvalGetAllRepairTypesState);

    return (
        <React.Fragment>
            <FormGroup
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxStates.matrix}
                            onChange={changeRepairType.changeMatrixState}
                            value={checkboxStates.matrix}
                        />
                    }
                    label={"Matrix"}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxStates.hardface}
                            value={checkboxStates.hardface}
                            onChange={changeRepairType.changeHardFaceState}
                        />
                    }
                    label={"Hard Face"}
                />
            </FormGroup>
        </React.Fragment>
    );
}

export default RepairTypeCheckboxes;