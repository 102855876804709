import React, { useEffect } from "react";
import { Box, Button, Card, CardContent, IconButton } from "@mui/material";
import useRECOILState from "../../../../../../hooks/useRECOILState";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  bitEvalCheckBoxRepairTypeStore,
  bitEvalCuttersBladeStore,
  bitEvalRepairLevelSelectStore,
  scrapCheckboxStore,
  scrapDatePickerStore,
  scrapSelectionStore,
} from "../../../../../../atoms/bitEvaluationatoms";
import {
  DbData,
  Drill,
  Order,
  Scrap,
} from "../../../../../../atoms/repairEntryFetchData";
import {
  bitEvalCutterMap,
  bitEvalDataGridStore,
  bitEvalOrder,
  bitEvalOrderConfirm,
  bitEvalOrderObj,
  bitEvalSelectedDrill,
  tempBitEvalCutterMap,
} from "../../../../../../atoms/staticAtoms";
import { toast } from "react-toastify";
import axios from "axios";
import useObjectState from "../../../../../../hooks/useObjectState";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useNavigatingAway } from "../../../../../../hooks/useNavigatingAway";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const EvaluationTabFooter = (props) => {
  const [orderObject, setOrderObj] = useRecoilState(bitEvalOrderObj);
  const [isNavigatingAway, navigateAway] = useNavigatingAway();
  const [open, setOpen] = React.useState(false);
  const [isDataChanged, setIsDataChanged] = React.useState(false);
  const [bitEvalRows, setBitEvalRows] = useRecoilState(bitEvalDataGridStore);
  const navigate = useNavigate();
  useEffect(() => {
    if (isNavigatingAway) {
      setOpen(true);
    }
  }, [isNavigatingAway]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    if (isDataChanged) {
      localStorage.setItem("bitEvalDataGridStore", JSON.stringify([]));
    }
    // navigateAway(`/bit-evaluation`);
    // window.location.reload();
  };

  const handleCloseButton = () => {
    if (isDataChanged) {
      setOpen(true);
    } else {
      navigateAway(`/bit-evaluation`);
    }
  };

  const createOrder = async () => {
    let orderObj = { ...orderObject };
    orderObj.CutterMap = JSON.stringify(orderObject.CutterMap);
    orderObj.RepairTypeCard = JSON.stringify(orderObject.RepairTypeCard);
    orderObj.Images = JSON.stringify(orderObject.Images);
    orderObj.FieldNotes = JSON.stringify(orderObject.FieldNotes);
    orderObj["Repair_Start_Time"] = new Date();
    // orderObj['Repair_End_Time'] = null;
    console.log(orderObj);
    // console.log(orderObject.Serial);
    console.log(bitEvalRows);
    console.log(
      bitEvalRows.filter((row) => row.Serial !== orderObj.DrillSerial)
    );

    setBitEvalRows(
      bitEvalRows.filter((row) => row.Serial !== orderObj.DrillSerial)
    );
    let urlSetOrder = "/api/orders/setOrder";
    let requestParams = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(orderObj),
    };

    await fetch(urlSetOrder, requestParams)
      .then((response) => {
        if (response.ok) {
          toast.success("Order Created Successfully");
          navigate("/ordering");
          return;
        }
        toast.error(
          "Order Creation Failed: Order already exists or bit is scrapped"
        );
      })
      .catch((error) => {
        toast.error("Order not created, contact admin");
      });

    setIsDataChanged(true);
  };

  /*const handleCancel = () => {
        window.location.href = '/bit-evaluation';
    }*/

  const handleDeleteButton = async () => {
    await axios
      .get(`/api/orders/removeOrder`, {
        params: { id: JSON.stringify(orderObject.OrderId) },
      })
      .then((res) => {
        toast.success("Order deleted successfully");
      })
      .catch((err) => {
        toast.error("Something went wrong when delete order");
      });
  };

  return (
    <React.Fragment>
      <Card
        variant={"outlined"}
        sx={{
          display: "grid",
          gridArea: "save",
        }}
      >
        <Box
          id={"RetroFitButtonBlock"}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            padding: 1,
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            size={"small"}
            variant={"outlined"}
            onClick={handleCloseButton}
          >
            Cancel
          </Button>
          <Button
            size={"small"}
            variant={"outlined"}
            onClick={handleDeleteButton}
          >
            Delete
          </Button>
        </Box>
      </Card>
      <Card
        variant={"outlined"}
        sx={{
          display: "grid",
          gridArea: "submit",
        }}
      >
        <Box
          id={"SubmitBox"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            size={"small"}
            variant={"outlined"}
            onClick={() => {
              createOrder();
            }}
          >
            Submit
          </Button>
        </Box>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        closeable={true}
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Leave page?"}</DialogTitle>
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContentText id="alert-dialog-description">
            You are leaving the page. All unsaved data will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={handleClose}>Stay</Button>
          <Button onClick={handleConfirm} autoFocus>
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default EvaluationTabFooter;
