import { Button, Input, MenuItem, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { DbData, Order } from "../../../../../atoms/repairEntryFetchData";
import {
  allOrdersBitEval,
  bitEvalOrder,
  bitEvalOrderObj,
  bitEvalSelectedDrill,
} from "../../../../../atoms/staticAtoms";

export const DocumentsFooter = ({ isUpdated, setIsUpdated }) => {
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState("");

  const [selectedDrill, setSelectedDrill] =
    useRecoilState(bitEvalSelectedDrill);

  const [title, setTitle] = useState("");

  const orderObj = useRecoilValue(bitEvalOrderObj);

  const orderIdGlobal = orderObj.OrderId;
  const [orderIdLocal, setOrderIdLocal] = useState("");

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleOrderIdChange = (event) => {
    setOrderIdLocal(event.target.value);
  };

  const saveFile = (e) => {
    setFiles(e.target.files);
      setFileName(e.target.files[0].name);
  };

  const loadFile = async () => {
    let dbData = new DbData(
      Order.model,
      [Order.fields.Id, Order.fields.Images],
      Order.fields.Id,
      orderIdLocal
    );
    await dbData.sendRequest();

    let data = dbData.result;
    if (data === "No find" && orderIdLocal !== orderIdGlobal) {
      alert("No order with this id");
      return;
    } else if (data === "No find" && orderIdLocal === orderIdGlobal) {
      data = [
        {
          OrderId: orderIdLocal,
          Images: [],
        },
      ];
    }

      console.log(files);
      let images = [];

      for (let i = 0; i < files.length; i++) {
          const file = files[i];
          let formData = new FormData();

          formData.append("File", file);
          formData.append("FileName", file.name);
          formData.append("Serial", selectedDrill.Serial);
          
          const requestOptions = {
              method: "POST",
              body: formData,
          };

          fetch("/api/file", requestOptions).then((response) => {
              console.log(response);
          })
          let imgObj = {
              FileName: file.name,
              Title: title.length === 0 ? file.name: title,
          };

          images.push(imgObj);
          orderObj.Images.push(imgObj);
      }

     


    if (data[0].Images !== "NO DATA" && orderIdLocal !== orderIdGlobal) {
      images = JSON.parse(data[0].Images);
    }

    

    if (orderIdLocal !== orderIdGlobal) {
      Order.setImages(orderIdLocal, JSON.stringify(images));
    }
    setIsUpdated(!isUpdated);
  };

  const [allOrders, setAllOrders] = useRecoilState(allOrdersBitEval);

  useEffect(() => {
    setOrderIdLocal(orderIdGlobal);
    let order = allOrders.find((order) => order.OrderId === orderIdGlobal);
    if (order === undefined) {
      setAllOrders([
        ...allOrders,
        { OrderId: orderIdGlobal, DrillSerial: orderObj.DrillSerial },
      ]);
    }
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #e0e0e0",
          borderRadius: "3px",
          padding: "10px",
          width: "50%",
          marginY: "10px",
          marginX: "auto",
        }}
      >
        <TextField
          variant={"outlined"}
          value={title}
          label={"Title"}
          onChange={(e) => {
            handleTitleChange(e);
          }}
          fullWidth
          sx={{ marginX: "10px" }}
        />
        <TextField
          select
          variant={"outlined"}
          value={orderIdLocal}
          label={"Order id"}
          onChange={(e) => {
            handleOrderIdChange(e);
          }}
          fullWidth
        >
          {allOrders.map((item) => {
            return (
              <MenuItem key={item.OrderId} value={item.OrderId}>
                {item.OrderId}
              </MenuItem>
            );
          })}
        </TextField>
        <Button
          variant={"contained"}
          color={"error"}
          sx={{ marginX: "10px" }}
          component="label"
        >
          Upload
          <input
            type="file"
            hidden
            multiple={true}
            onChange={(e) => {
              saveFile(e);
            }}
          />
        </Button>
        <Button
          variant={"contained"}
          colo={"error"}
          sx={{ marginX: "10px" }}
          disabled={orderIdLocal.length === 0 || fileName.length === 0}
          onClick={() => {
            loadFile();
          }}
        >
          Save
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default DocumentsFooter;
