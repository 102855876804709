import React from 'react';
import {Typography} from "@mui/material";

const BladeTemplateHeader = (props) => {
    return (
        <React.Fragment>
            <Typography variant={"h5"}>
                Blade Template
            </Typography>
        </React.Fragment>
    );
}

export default BladeTemplateHeader;