import React, {useEffect} from 'react';
import {Checkbox, FormControlLabel, FormGroup, Stack} from "@mui/material";
import GppBadIcon from '@mui/icons-material/GppBad';
import useRECOILState from "../../../../../../hooks/useRECOILState";
import {scrapCheckboxStore} from "../../../../../../atoms/bitEvaluationatoms";
import {useRecoilState, useRecoilValue} from "recoil";
import {bitEvalOrderObj, bitEvalSelectedDrill} from "../../../../../../atoms/staticAtoms";

const ScrapCheckboxWithIcon = (props) => {
    const [scrapStore, setScrap] = useRecoilState(scrapCheckboxStore);
    const selectedDrill = useRecoilValue(bitEvalSelectedDrill);
    
    const orderObj = useRecoilValue(bitEvalOrderObj);
    

    useEffect(() => {
        setScrap(selectedDrill.Scrap);
    }, [selectedDrill.Scrap]);

    
    const handleScrapCheckbox = (e) => {
        setScrap(e.target.checked);
        orderObj.Scrap = e.target.checked;
    }
    
    return (
        <React.Fragment>
            <FormGroup
                id={'ScrapCheckBoxFormGroup'}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    // gap: 1,
                    flexDirection: 'row',
                }}
            >
                <GppBadIcon sx={{color: 'orange'}}/>
                <FormControlLabel 
                    control={<Checkbox checked={scrapStore} id="checkBox" color={"secondary"} onClick={(e) => {
                        handleScrapCheckbox(e)
                    }}/>}
                    label={'Request Scrap'}/>
            </FormGroup>
        </React.Fragment>
    );
}

export default ScrapCheckboxWithIcon;