import React from 'react';
import {Avatar, Tooltip, Typography} from "@mui/material";
import UseObjectState from "../../../../../../hooks/useObjectState";
import useOrderingChangeBladeMaterial from "../../../../../../hooks/useOrderingChangeBladeMaterial";
import Box from "@mui/material/Box";

const PrimaryRowAvatars = (props) => {
    const {cutterState, cutterNumber, primaryRow, parentState, childrenState, cutterIndex, bladeIndex} = props;
    const newState = UseObjectState(null)
    const {pushNewCutter, getBladeStore} = useOrderingChangeBladeMaterial();
    const handleAvatarClick = () => {
        if (childrenState) {
            console.log(childrenState.name)
            newState.setState(childrenState.name);
            pushNewCutter(childrenState.name, bladeIndex, cutterIndex, true);
            console.log(getBladeStore().state);
        } else {
            newState.setState(parentState.name);
            pushNewCutter(parentState.name, bladeIndex, cutterIndex, true);
            console.log(getBladeStore().state);
        }
    }
    return (
        <>

            {cutterNumber === parentState && cutterState === 'X' ?
                <Tooltip title={<Box>
                    <Typography>Cutter State: {cutterState}</Typography>
                    {newState.state !== null && <Typography>Cutter Material Number: {newState.state}</Typography>}
                </Box>}>
                    {newState.state ?
                        <Avatar
                            onClick={handleAvatarClick}
                            sx={{
                                backgroundColor: 'red',
                            }}
                        >

                            <Typography
                                sx={{
                                    fontSize: 9,
                                }}
                            >
                                {newState.state}
                            </Typography>
                        </Avatar>
                        :
                        <Avatar
                            onClick={handleAvatarClick}
                            sx={{
                                backgroundColor: 'red',
                            }}
                        >
                            {cutterState}
                        </Avatar>
                    }
                </Tooltip>
                :
                <Tooltip title={cutterState ? cutterState : 'O'}>
                    <Avatar>{cutterState ? cutterState : 'O'}</Avatar>
                </Tooltip>
            }

        </>

    );
}


export default PrimaryRowAvatars;

/*{(cutterNumber === parentState) && (cutterState === 'X') ?
                <Tooltip title={<Box>
                    <Typography>Cutter State: {cutterState}</Typography>
                    {newState.state !== null && <Typography>Cutter Material Number: {newState.state}</Typography>}
                </Box>}>
                    {newState.state ?
                        <Avatar
                            onClick={handleAvatarClick}
                            sx={{
                                backgroundColor: 'red',
                            }}
                        >
                            
                            <Typography
                                sx={{
                                    fontSize: 9,
                                }}
                            >
                                {newState.state}
                            </Typography>
                        </Avatar>
                        :
                        <Avatar
                            onClick={handleAvatarClick}
                            sx={{
                                backgroundColor: 'red',
                            }}
                        >
                            {cutterState}
                        </Avatar>
                    }
                </Tooltip>
                :
                <Tooltip title={cutterState}>
                    <Avatar>{cutterState}</Avatar>
                </Tooltip>
            }*/