import React from 'react';
import { CircularProgress } from '@mui/material';

const LoadingComponent = ({ isLoading }) => {
  return (
    <>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress color="primary" size={80} thickness={5} />
        </div>
      ) : null}
    </>
  );
};

export default LoadingComponent;