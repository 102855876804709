import React, {useEffect, useMemo} from "react";
import {DataGrid} from "@mui/x-data-grid";
import {useRecoilValue} from "recoil";
import {tempBitEvalCutterMap} from "../../../../atoms/staticAtoms";
import _ from 'lodash';
import cutterMap from "../../Administration/CutterMapping/CutterMap";
import axios from "axios";
import UseObjectState from "../../../../hooks/useObjectState";

/* Тестовая строка для проверки сетки данных. */
const testRow = [
    {
        id: 1,
        description: "This is a test row",
        qty: "this is a test row",
    },
];


/**
 * Он берет лезвие и карту резаков и добавляет резаки из лезвия на карту.
 */
const extractAllCutters = (blade, allCutters) => {
    const addCutter = (cutter) => {
        if (!allCutters[cutter]) {
            allCutters[cutter] = 1
        } else allCutters[cutter] += 1;
    }

    if (blade.cutters[0].primary !== null) {
        blade.cutters[0].primary.map((cutter, index) => {
            if (checkCutters(blade.primaryEvalCutters[index])) {
                addCutter(cutter);
            }
        })
    }
    if (blade.cutters[1].secondary !== null) {
        blade.cutters[1].secondary.map((cutter, index) => {
            if (checkCutters(blade.secondaryEvalCutters[index])) {
                addCutter(cutter);
            }
        })
    }
}

const checkCutters = (cutterState) => {
    return _.includes(['X'], cutterState)
}
/**
 * Он создает таблицу с указанными столбцами и строками.
 */
const Estimate = (props) => {
    const tempCutterMap = useRecoilValue(tempBitEvalCutterMap)
    const columns = React.useMemo(() => [
        {
            field: "id",
            headerName: "Material #",
            flex: 1,
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
        },
        {
            field: "qty",
            headerName: "Quantity",
            flex: 1,
        },
    ]);
    const cuttersRowsArray = [];


    const createReplaceData = () => {
        const {primaryEvalCutters, secondaryEvalCutters, cutters} = tempCutterMap;
        let allCutters = {};
        tempCutterMap.map((blade) => {
            extractAllCutters(blade, allCutters);
        })
        if (Object.keys(allCutters).length > 0) {
            const cuttersId = Object.keys(allCutters)
            cuttersId.forEach((cutter, index) => {
                cuttersRowsArray.push({
                    id: cutter,
                    description: 'Test description',
                    qty: allCutters[cutter],
                })
            })

        }
    }
    createReplaceData();

    useEffect(() => {
        if (cuttersRowsArray.length > 0) {
            let idsArray = [];
            cuttersRowsArray.map((row, i) => {
                idsArray.push(row.id);
            })
            axios.post(`/api/cutters_new/getDescription`, {Ids: idsArray}).then((response) => {
                response.data.map((cutterF, index) => {
                    cuttersRowsArray.filter((cutter, index) => {
                        if (cutter.id === cutterF.name) {
                            cuttersRowsArray[index].description = cutterF.description;
                        }
                        ;
                    })
                })
            })
        }
    }, [tempCutterMap]);

    return <DataGrid columns={columns} rows={cuttersRowsArray} autoHeight/>;
};

export default Estimate;