import React, { useEffect } from "react";
import axios from "axios";
import {
  Select,
  CircularProgress,
  styled,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";

const Loading = styled(CircularProgress)({
  marginTop: -12,
  marginLeft: -12,
  color: (theme) => theme.palette.primary.main,
  animationDuration: "550ms",
  zIndex: 1,
});

export const RepairOrderLocationSelect = React.memo(
  function RepairOrderLocationSelect({ drillSerial: workOrder }) {
    const [locations, setLocations] = React.useState([]);
    const [locationName, setLocationName] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const drillSerialT = React.useMemo(() => {
      axios
        .get(`api/orders/getDrillByOrderId`, { params: { orderId: workOrder } })
        .then((response) => {
          debugger;
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);
    const getDrillLocationName = async () => {
      try {
        const response = await axios.get("/api/location/getDrillLocation", {
          params: { drillSerial: workOrder },
        });
        setLocationName(response.data.code);
      } catch (error) {
        console.log(error);
      }
    };

    const getLocations = async () => {
      try {
        const response = await axios.get("/api/location/getAllLocations");
        setLocations(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      setLoading(true);
      getLocations().then(() => {
        getDrillLocationName().then(() => {
          setLoading(false);
        });
      });
    }, [workOrder]);

    const handleLocationChange = (event) => {
      setLocationName(event.target.value);
    };

    if (loading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
          }}
        >
          <Loading />
        </Box>
      );
    }

    const getDrillByOrderId = async () => {
      try {
        const response = await axios.get("/api/orders/getDrillByOrderId", {
          params: { orderId: workOrder },
        });
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const handleConfirm = async () => {
      const serial = await getDrillByOrderId();
      try {
        const response = await axios.post(
          "/api/DrillsControllers/updateDrillLocation",
          {
            serial: JSON.stringify(serial),
            locationCode: locationName,
          }
        );
        console.log(response);
        toast.success("Location updated successfully");
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
      }
    };

    return (
      <>
        {locations.length > 0 && locationName !== "" && (
          <FormControl
            variant="outlined"
            sx={{ p: 1, minWidth: 120, width: "100%", gap: 1, padding: 1 }}
          >
            <InputLabel
              id="select-outlined-label"
              sx={{ width: "100%", fontSize: "1.2rem" }}
            >
              Select Location
            </InputLabel>
            <Select
              value={locationName}
              onChange={handleLocationChange}
              fullWidth
              label="Location"
            >
              {locations.map((location) => (
                <MenuItem key={location.id} value={location.code}>
                  {location.name}
                </MenuItem>
              ))}
            </Select>
            <Button variant="contained" color="primary" onClick={handleConfirm}>
              Confirm
            </Button>
          </FormControl>
        )}
      </>
    );
  }
);
