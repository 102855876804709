import Box from "@mui/material/Box";
import { Button, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Cutters,
  DbData,
  Material,
} from "../../../../atoms/repairEntryFetchData";
import Divider from "@mui/material/Divider";
import TableListCutters from "./TableListCuttersComponent/TableListCutters";
import { toast } from "react-toastify";
import useObjectState from "../../../../hooks/useObjectState";
import UseValidation from "../../../../hooks/useValidation";
import {
  DataGrid,
  GridToolbar,
  GridToolbarQuickFilter,
  ruRU,
} from "@mui/x-data-grid";
import axios from "axios";
import MaterialsHeaderLayout from "./MaterialsHeaderLayout";
import ConfirmationDialog from "../../../../components/ConfirmationDialog/ConfirmationDialog";
import ChangeMaterialPackModel from "./ChangeMaterialPackModel";

export const AddMaterial = () => {
  const [cutters, setCutters] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const modalStore = useObjectState(false);

  const [selectedCutter, setSelectedCutter] = useState([]);
  const [cuttersInterchange, setCuttersInterchange] = useState({});

  const addSelectedCutters = (cutter) => {
    if (selectedCutter.includes(cutter)) {
      setSelectedCutter(
        selectedCutter.filter((cutterName) => cutterName !== cutter)
      );
      return;
    }
    setSelectedCutter([...selectedCutter, cutter]);
  };
  // TODO: добавить проверку к загрузке елемента на isNullOrEmpty во все вкладки.

  const asyncGetCutters = async () => {
    let dbData = new DbData(Material.model, [Cutters.fields.Code]);
    await dbData.sendRequest();

    let tmpCutters = [];
    for (let i = 0; i < dbData.result.length; i++) {
      tmpCutters.push([
        {
          Name: dbData.result[i].Name,
          Interchangeble: JSON.parse(dbData.result[i].Interchangeble),
        },
        0,
      ]);
    }

    setCutters(tmpCutters);
  };

  useEffect(() => {
    //asyncGetCutters();
    asyncGetMaterials();
  }, []);

  const isError = useObjectState(false);
  const { isNumberValidation } = UseValidation();

  const [materialCode, setMaterialCode] = useState("");
  const handleChangeMaterialCode = (event) => {
    if (!isNumberValidation(event.target.value)) {
      isError.setState(false);
      setMaterialCode(event.target.value);
    } else {
      isError.setState(true);
    }
  };
  const asyncGetMaterials = async () => {
    axios
      .get(`api/material/getAllMaterials`, {})
      .then((response) => {
        setRows(response.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Ошибка получения артикулов.");
      });
  };
  const asyncGetMaterial = async () => {
    if (materialCode === "") {
      toast.error("Необходимо ввести код материала");
      return;
    }

    let dbData = new DbData("Material", ["Code"], "Code", materialCode);
    await dbData.sendRequest();
    console.log(dbData.result);
    if (dbData.result == "No find") {
      toast.error("Материал не найден");
      return;
    }

    let cuttersGetted = JSON.parse(dbData.result[0].CuttersCodes);

    let tempCutters = [...cutters];
    let tempSelectedCutters = [];
    for (let i = 0; i < cuttersGetted.length; i++) {
      let newCount = cuttersGetted[i].count;
      let cutterName = cuttersGetted[i].name;

      tempSelectedCutters.push(cutterName);
      for (let j = 0; j < tempCutters.length; j++) {
        if (tempCutters[j][0].Name === cutterName) {
          tempCutters[j][1] = newCount;
          break;
        }
      }
    }

    setSelectedCutter(tempSelectedCutters);
    setCutters(tempCutters);
  };
  const namesCutters = [];

  cutters.forEach((cutter) => {
    namesCutters.push([cutter[0].Name, cutter[1]]);
  });
  const materialColumns = [
    {
      field: "code",
      headerName: "Артикул",
      flex: 1,
    },
  ];
  const addMaterial = async () => {
    let cuttersTemp = [];
    for (let i = 0; i < selectedCutter.length; i++) {
      let count = cutters.find(
        (cutter) => cutter[0].Name === selectedCutter[i]
      )[1];
      cuttersTemp.push({ name: selectedCutter[i], count: count });
    }
    Material.addMaterial(materialCode, cuttersTemp);
  };

  const removeMaterial = () => {
    Material.removeMaterial(materialCode);
  };
  const handleRowSelectionChanged = (newRowSelectionModel) => {
    console.log(newRowSelectionModel);
    console.log(modalStore.state);
    modalStore.setState(true);
    setRowSelectionModel(newRowSelectionModel.id);
  };

  const editCount = (cutterEdits, count) => {
    const index = cutters.findIndex((cutter) => cutter[0].Name === cutterEdits);
    let tmpCutters = cutters;
    cutters[index][1] = count;

    setCutters([...tmpCutters]);
  };

  return (
    <Box height={"100%"} width={"100%"}>
      {rows && (
        <DataGrid
          onRowDoubleClick={handleRowSelectionChanged}
          rowSelectionModel={rowSelectionModel}
          components={{ Toolbar: CustomeToolbar }}
          columns={materialColumns}
          rows={rows}
          sx={{ height: "80vh" }}
          getRowId={(row) => row.code}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        />
      )}
      {modalStore.state && (
        <ChangeMaterialPackModel
          modalStore={modalStore}
          materialCode={rowSelectionModel}
        />
      )}
    </Box>
  );
};
function CustomeToolbar() {
  return (
    <Box>
      <GridToolbarQuickFilter variant="outlined" size="small" />
    </Box>
  );
}

// const Interchange = (props) => {
//   return (
//     <Box>
//       {props.cuttersInterchange.map((cutter) => {
//         let borders =
//           cutter ===
//           props.cuttersInterchange[props.cuttersInterchange.length - 1]
//             ? "0px 0px 10px 10px"
//             : "0px 0px 0px 0px";
//         return (
//           <Box
//             sx={{
//               backgroundColor: "#e0e0e0",
//               width: "100%",
//               borderRadius: borders,
//               padding: "2%",
//             }}
//           >
//             <Box
//               sx={{
//                 border: "1p solid #e0e0e0",
//                 borderRadius: "3px",
//                 padding: "5px",
//                 backgroundColor: "white",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 textAlign: "center",
//                 margin: "2%",
//               }}
//             >
//               <Typography variant={"body2"}>{cutter}</Typography>
//             </Box>
//           </Box>
//         );
//       })}
//     </Box>
//   );
// };

export default AddMaterial;
