import React from 'react';
import Box from "@mui/material/Box";
import {IconButton, Tab, Tabs, Tooltip} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const DetailsTabs = (props) => {
    const {tabsStore, modalStore} = props;

    const handleTabChange = (event, newValue) => {
        tabsStore.setState(newValue);
    }

    return (
        <Box
            justifyContent={"space-between"}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                borderBottom: 1,
                borderColor: 'divider',
                alignItems: 'center',
            }}
        >
            <Tabs value={tabsStore.state} onChange={handleTabChange}>
                <Tab value={'Evaluation'} label={'Evaluation'}/>
                {/*<Tab value={'Cutters'} label={'Cutters'}/>*/}
                <Tab value={'Documents'} label={'Documents'}/>
                <Tab value={'Notes'} label={'Notes'}/>
            </Tabs>
            <Tooltip title={'Закрыть'}>
                <IconButton onClick={() => modalStore.setState(false)}><CloseIcon/></IconButton>
            </Tooltip>

        </Box>
    );
}

export default DetailsTabs;