import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { IconButton, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import useRECOILState from "../../../../../hooks/useRECOILState";
import { orderingSearchTextFieldAtom } from "../../../../../atoms/Ordering_Atoms/OrderingTextFieldAtoms";
import { useRecoilState } from "recoil";
import {
  DbData,
  Drill,
  Order,
} from "../../../../../atoms/repairEntryFetchData";
import { orderingRows } from "../../../../../atoms/Ordering_Atoms/OrderingAtoms";
import useObjectState from "../../../../../hooks/useObjectState";
import UseValidation from "../../../../../hooks/useValidation";
import { toast } from "react-toastify";

const refactoredData = (data) => {
  return data.map((item, index) => ({
    ...item,
    id: index + 1,
    repairType: Object.keys(JSON.parse(item.repairType))
      .filter((key) => JSON.parse(item.repairType)[key])
      .map((type) => type[0].toUpperCase() + type.slice(1))
      .join(","),
  }));
};

/* Компонент, отвечающий за поле поиска и кнопку обновления. */
const OrderingTextFieldWithButtons = (props) => {
  const [textFieldValue, setTextFieldValue] = useState("");
  const [rows, setRows] = useRecoilState(orderingRows);
  const [error, setError] = useState(false);

  const { isNumberValidation } = UseValidation();

  const handleTextFieldChange = (e) => {
    const isNumber = isNumberValidation(e.target.value);
    setError(isNumber);
    if (!isNumber) {
      setTextFieldValue(e.target.value);
    }
  };

  const handleSearchButtonClick = () => {
    axios
      .get(`/api/orders/getPendingOrdersData`, {
        params: { serial: textFieldValue.toString() },
      })
      .then((response) => {
        if (response.status === 204) {
          toast.info("There are no pending orders");
          return;
        }
        const dataWithModifiedRepairTypeAndId = refactoredData(response.data);
        /* const dataWithModifiedRepairTypeAndId = response.data.map((item, index) => ({
                     ...item,
                     id: index + 1,
                     repairType: Object.keys(JSON.parse(item.repairType))
                         .filter(key => JSON.parse(item.repairType)[key])
                         .map(type => type[0].toUpperCase() + type.slice(1))
                         .join(',')
                 }));*/
        setRows(dataWithModifiedRepairTypeAndId);
        toast.success("Succesfully added to table");
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  const handleRefreshButton = () => {
    axios
      .get(`/api/orders/getAllPendingOrders`)
      .then((response) => {
        if (response.status === 204) {
          toast.info("There are no pending orders");
          return;
        }
        const dataWithModifiedRepairTypeAndId = refactoredData(response.data);
        /*response.data.map((item, index) => ({
                    ...item,
                    id: index + 1,
                    repairType: Object.keys(JSON.parse(item.repairType))
                        .filter(key => JSON.parse(item.repairType)[key])
                        .map(type => type[0].toUpperCase() + type.slice(1))
                        .join(',')
                }));*/
        setRows(dataWithModifiedRepairTypeAndId);
        toast.success("Succesfully added to table");
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  const firstInitialisation = () => {
    axios
      .get(`/api/orders/getAllPendingOrders`)
      .then((response) => {
        if (response.status === 204) {
          toast.info("There are no pending orders");
          return;
        }
        const dataWithModifiedRepairTypeAndId = refactoredData(response.data);
        setRows(dataWithModifiedRepairTypeAndId);
        toast.success("Succesfully added to table");
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    firstInitialisation();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Stack direction={"row"} alignItems={"center"}>
        <TextField
          size={"small"}
          variant={"outlined"}
          label={"Enter Serial"}
          value={textFieldValue}
          error={error}
          helperText={error ? "Please enter a valid serial" : ""}
          onInput={handleTextFieldChange}
        />
        <IconButton onClick={handleSearchButtonClick}>
          <SearchIcon />
        </IconButton>
      </Stack>
      <IconButton onClick={handleRefreshButton}>
        <RefreshIcon />
      </IconButton>
    </Box>
  );
};

export default OrderingTextFieldWithButtons;

/*   
       const handleSearchButtonClick = () => {
           
       }*/

/* let dbData = new DbData(
     Order.model,
     [Order.fields.Id, Order.fields.Serial, Order.fields.RepairType],
     Order.fields.Id,
     textFieldValue
 );
 await dbData.sendRequest();
 let result = dbData.result[0];

 let dbDataDrill = new DbData(
     Drill.model,
     [Drill.fields.Serial, Drill.fields.Size, Drill.fields.Type],
     Drill.fields.Serial,
     result.DrillSerial
 );
 await dbDataDrill.sendRequest();
 let drillResult = dbDataDrill.result[0];

 let RepairType = "";
 let repairCard = JSON.parse(result.RepairTypeCard);

 Object.keys(repairCard).forEach((key) => {
     if (repairCard[key] === true) {
         RepairType += `${key} `;
     }
 });

 let rowObject = {
     id: 0,
     orderId: result.OrderId,
     serial: result.DrillSerial,
     size: drillResult.Size,
     type: drillResult.Type,
     origin: "Later...",
     repairType: RepairType,
     workOrder: "Later...",
 };
*/
// setRows([rowObject]);
