import React from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useRecoilState, useSetRecoilState } from "recoil";
import { searchTextFieldStore } from "../../../../../atoms/bitEvaluationatoms";
import useRECOILState from "../../../../../hooks/useRECOILState";
import { DbData, Drill } from "../../../../../atoms/repairEntryFetchData";
import {
  bitEvalDataGridStore,
  bitEvalOrderObj,
} from "../../../../../atoms/staticAtoms";
import useObjectState from "../../../../../hooks/useObjectState";
import UseValidation from "../../../../../hooks/useValidation";
import { toast } from "react-toastify";

import axios from "axios";

const BitEvalTextFieldWithSearch = (props) => {
  const textFieldStore = useRECOILState(searchTextFieldStore);
  const [rows, setRows] = useRecoilState(bitEvalDataGridStore);
  const isError = useObjectState(false);
  const { isNumberValidation } = UseValidation();

  const handleTextFieldChange = (e) => {
    if (!isNumberValidation(e.target.value)) {
      isError.setState(false);
      textFieldStore.setState(e.target.value);
    } else {
      isError.setState(true);
    }
  };

  const handleRefreshButton = () => {
    setRows([]);
  };

  const getDrillBySerial = () => {
    let serial = textFieldStore.state;
    if (serial === "") {
      toast.warning("Please enter a serial number");
      return;
    }
    let db = new DbData(
      Drill.model,
      [
        "Serial",
        "MaterialCode",
        "Size",
        "Type",
        "LocationCode",
        "Scrap",
        "CutterMap",
      ],
      "Serial",
      serial
    );

    let isInRows = false;

    rows.forEach((r) => {
      if (r.Serial === serial) {
        isInRows = true;
      }
    });

    if (!isInRows) {
      // JSON.parse(r.data);
      /* let row = {
                 id: r.id,
                 name: r.name,
                 type: r.type,
                 size: r.size,
                 location: r.location,
                 scrap: r.scrap,
                 cutter: r.cutter
             }*/

      db.sendRequest().then(() => {
        debugger;
        if (db.result === "No find") {
          toast.error("Invalid serial number");
          return;
        }
        let row = {
          id: rows.length + 1,
          Serial: db.result[0].Serial,
          Material: db.result[0].MaterialCode,
          Size: db.result[0].Size,
          Type: db.result[0].Type,
          Origin: db.result[0].LocationCode,
          Scrap: db.result[0].Scrap,
          Map: db.result[0].CutterMap,
        };
        axios
          .get(`/api/orders/getOrderByDrillId`, { params: { id: serial } })
          .then((r) => {
            console.log(r.data);
          });
        setRows([...rows, row]);
        toast.success("Drill added to the list");
      });
    } else {
      toast.warning("Drill already in the list");
    }
  };

  return (
    <React.Fragment>
      <Stack
        display={"flex"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        // gap={1}
        // padding={1}
      >
        <Stack
          direction={"row"}
          // gap={1}
          alignItems={"center"}
        >
          {!isError.state ? (
            <TextField
              size={"small"}
              variant={"outlined"}
              label={"Enter Serial#"}
              onChange={handleTextFieldChange}
            />
          ) : (
            <TextField
              error
              helperText={"Incorrect number format"}
              size={"small"}
              variant={"outlined"}
              label={"Enter Serial#"}
              onChange={handleTextFieldChange}
            />
          )}

          <IconButton
            onClick={() => {
              getDrillBySerial();
            }}
          >
            <SearchIcon />
          </IconButton>
        </Stack>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography>Reset rows</Typography>
          <IconButton onClick={handleRefreshButton}>
            <RefreshIcon />
          </IconButton>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default BitEvalTextFieldWithSearch;
