import React from 'react';
import {Box, Card, CardContent, CardHeader, Stack, Typography} from "@mui/material";
import ScrapCheckboxWithIcon from "./ScrapCheckboxWithIcon";
import {ScrapDatePicker, ScrapDateReasonLayout} from "./components";

const EvaluationTabScrap = (props) => {
    
    return (
        <React.Fragment>
            <Card variant={"outlined"}
                  sx={{
                      display: "grid",
                      gridArea: 'scrap',
                  }}
            >

                <Typography variant={"subtitle1"} padding={1}
                            sx={{
                                display: "flex",
                                justifyContent: 'center',
                            }}
                >Scrap Card
                </Typography>
                <CardContent>
                    <Box
                        id={'ScrapBox'}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <ScrapCheckboxWithIcon/>
                        <ScrapDateReasonLayout/>
                    </Box>
                </CardContent>

            </Card>

        </React.Fragment>
    );
}

export default EvaluationTabScrap;