import React from 'react';
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import {useRecoilState} from "recoil";
import {
    repairsActiveRows,
} from "../../../../../atoms/RepairsAtoms/repairsAtoms";


/* Тестовые данные для таблицы. */
const testData = [
    {
        id: 1,
        serial: 123515,
        material: 3515616,
        size: '11/4/12',
        type: 'testType',
        origin: 'testOrigin',
        repType: 'testStandard, testMatrix',
    }
]

/**
 * Он возвращает компонент DataGrid с некоторыми столбцами и строками, а при двойном щелчке по строке открывается модальное
 * окно.
 * @returns Компонент DataGrid с определенными столбцами и строками.
 */
const RepairsTab = (props) => {
    const {modalStore, rowData} = props;
    const [rows, setRows] = useRecoilState(repairsActiveRows);
    
    //TODO: Получение данных из таблицы по текущим ремонтам

    const columns = React.useMemo(
        () => [
            {
                field: 'id',
            },
            {
                field: 'evalution',
                headerName: 'Order ID',
                flex: 1
            },
            {
                field: 'serial',
                headerName: 'Serial',
                flex: 1,
            },
            {
                field: 'material',
                headerName: 'Material',
                flex: 1,
            },
            {
                field: 'size',
                headerName: 'Size',
                flex: 1,
            },
            {
                field: 'type',
                headerName: 'Type',
                flex: 1,
            },
            {
                field: 'origin',
                headerName: 'Origin',
                flex: 1,
            },
            {
                field: 'repType',
                headerName: 'Repair Type',
                flex: 1,
            },
        ]
    )
    return (
        <DataGrid columns={columns} rows={rows}
                  onRowDoubleClick={(row) => {
                      modalStore.setState(true);
                      rowData.setState(row);
                  }}
                  initialState={{
                      columns: {
                          columnVisibilityModel: {
                              id: false,
                          }
                      }
                  }}
                  sx={{
                      padding: 1,
                      height: '60vh',
                  }}
        />
    );
}

export default RepairsTab;