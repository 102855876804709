import React, {useEffect, useRef, useState} from 'react';
import {Box, Dialog, Modal} from "@mui/material";
import {ConfigureCutterMap} from "./components/ConfigureCutterMap";
import MaterialReactTable from 'material-react-table';
import UseObjectState from "../../../../hooks/useObjectState";
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {
    childrenTableCutter, childrenTableCutterSelector,
    parentTableCutter,
    parentTableCutterSelector
} from "../../../../atoms/Ordering_Atoms/OrderingAtoms";
import ConfigureButtons from "./components/ConfigureCutterMap/ConfigureButtons";
import ConfigureCutterInfoArea from "./components/ConfigureCutterInfoArea/ConfigureCutterInfoArea";
import {DbData, Order} from "../../../../atoms/repairEntryFetchData";
import axios from "axios";

const data = [
    {
        name: 231451,
        description: 'TestCutter',
        type: 'testType',
        cls: 'testCLS',
        cat: 'testCat',
        unit: 'testUnit',
        rqa: 'testRQA',
        subRows: [
            {
                parentName: 231451,
                name: 12341,
                description: 'testChangeCutter',
                type: 'testChangeType',
                cls: 'testChangeCLS',
                cat: 'testChangeCat',
                unit: 'testChangeUnit',
                rqa: 'testChangeRQA',
            },
            {
                parentName: 231451,
                name: 12355,
                description: 'testChangeCutter',
                type: 'testChangeType',
                cls: 'testChangeCLS',
                cat: 'testChangeCat',
                unit: 'testChangeUnit',
                rqa: 'testChangeRQA',
            }
        ]
    },
    {
        name: 1235612,
        description: 'TestCutter',
        type: 'testType',
        cls: 'testCLS',
        cat: 'testCat',
        unit: 'testUnit',
        rqa: 'testRQA',
        subRows: [
            {
                parentName: 1235612,
                name: 65123,
                description: 'testChangeCutter',
                type: 'testChangeType',
                cls: 'testChangeCLS',
                cat: 'testChangeCat',
                unit: 'testChangeUnit',
                rqa: 'testChangeRQA',
            },
            {
                parentName: 1235612,
                name: 65124,
                description: 'testChangeCutter',
                type: 'testChangeType',
                cls: 'testChangeCLS',
                cat: 'testChangeCat',
                unit: 'testChangeUnit',
                rqa: 'testChangeRQA',
            }
        ]
    }
];


const OrderConfigureCutterMapLayout = (props) => {
    const {modalStore} = props;
    const atomParentTableCutter = useSetRecoilState(parentTableCutter)
    const selectorAtomParentTableCutter = useRecoilValue(parentTableCutterSelector);
    const setChildrenTableState = useSetRecoilState(childrenTableCutter);
    const getChildrenTableState = useRecoilValue(childrenTableCutterSelector);
    const state = UseObjectState(true)
    const [rowSelection, setRowSelection] = useState({});

    const [rows, setRows] = useState([]);

    const handleModalClose = () => modalStore.setState(false);

    const orderId = props.orderId;

    const loadInfoForTable = () => {
        let dbData = new DbData(Order.model, [Order.fields.Id, Order.fields.Serial], Order.fields.Id, orderId)
        dbData.sendRequest().then((data) => {
            let serial = dbData.result[0][Order.fields.Serial];
            let url = "/api/DrillsControllers/getTableInterchaglebleCutters?drillSerial=" + serial;

            fetch(url)
                .then(response => response.json())
                .then(data => {
                    let tempRows = data.result;
                    // console.log('tempROWS', tempRows);
                    for (let i = 0; i < tempRows.length; i++) {
                        tempRows[i].subRows = JSON.parse(tempRows[i].subRows);
                    }

                    setRows(tempRows);
                })
        })
    }


    useEffect(() => {
        loadInfoForTable();

        document.title = 'Configure Order'
        atomParentTableCutter(null);
        console.log(selectorAtomParentTableCutter);
    }, [])


    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Material'
            },
            {
                accessorKey: 'description',
                header: 'Description',
            },
            {
                accessorKey: 'type',
                header: 'Type',
            },
            {
                accessorKey: 'cls',
                header: 'CLS',
            },
            {
                accessorKey: 'cat',
                header: 'Cat',
            },
            {
                accessorKey: 'unit',
                header: 'Unit',
            },
            {
                accessorKey: 'rqa',
                header: 'RQA',
            }
        ],
        []
    );

    // console.log(selectorAtomParentTableCutter, getChildrenTableState);
    // console.log(rowSelection);
    return (
        <Dialog open={modalStore.state}
                fullScreen
                onClose={handleModalClose}
        >
            <Box
                sx={{
                    display: 'grid',
                    width: 'calc(100%-70px)',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        overflow: 'auto',
                    }}
                >
                    <MaterialReactTable
                        muiTablePaperProps={{
                            sx: {
                                zIndex: 1,
                            }
                        }
                        }

                        columns={columns} data={rows}
                        enableExpanding
                        enableSelectAll={false}
                        muiTablePaperProps={({paper}) => ({
                            style: {
                                width: '100%',
                                height: '100%',
                                overflow: 'auto',
                            }
                        })}
                        enableFullScreenToggle={false}
                        enableRowSelection
                        enableMultiRowSelection={false}
                        muiSelectCheckboxProps={({row}) => ({
                            color: "secondary",
                            onClick: (event) => {
                                console.log(row);
                                if (row.subRows.length > 0) {

                                } else {
                                    atomParentTableCutter(row.original.parentName);
                                    setChildrenTableState({...row.original});
                                    state.setState(!state.state)
                                }

                                /*if (!row.original.hasOwnProperty('parentName')) {
                                    atomParentTableCutter(row.original.name);
                                    setChildrenTableState(null);
                                    state.setState(!state.state)
                                } else {
                                    atomParentTableCutter(row.original.parentName);
                                    setChildrenTableState({...row.original});
                                    state.setState(!state.state)
                                }*/

                            }
                        })}
                        localization={MRT_Localization_RU}
                        initialState={{
                            density: "compact",
                        }}
                        onRowSelectionChange={setRowSelection}
                        state={{rowSelection}}
                        enableDensityToggle={true}
                        enableExpandAll
                        getSubRows={(originalRow) => originalRow.subRows}
                    />
                </Box>

                {getChildrenTableState !== null && <ConfigureCutterInfoArea/>}
                <ConfigureCutterMap childrenState={getChildrenTableState} parentState={selectorAtomParentTableCutter}
                                    orderId={orderId}/>
                <ConfigureButtons orderId={orderId} modalStore={modalStore}/>
            </Box>
        </Dialog>
    );
}

export default OrderConfigureCutterMapLayout;