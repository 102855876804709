import React, { useEffect, useMemo } from "react";
import {
  Avatar,
  Box,
  Card,
  Divider,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingComponent from "../../../../../../components/Loading/LoadingComponent";
import { styled } from "@mui/material/styles";

const CutterMapCard = styled(Card)(({ theme, ...props }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "nowrap",
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  "&:hover": {
    boxShadow: theme.shadows[5],
  },
  ...props.sx,
}));

const AvatarC = styled(Avatar)(({ theme, ...props }) => ({
  fontSize: "1.5rem",
  ...props.sx,
}));

const CutterMapHeader = () => {
  return (
    <Box
      id={"CutterMapHeader"}
      sx={{
        display: "grid",
        gridTemplateColumns: `2fr 1fr 7fr`,
        gap: 1,
        width: "100%",
      }}
    >
      <CutterMapCard
        id={"CutterMapHeaderCard"}
        sx={{
          // width: "100%",
          flexWrap: "nowrap",
        }}
      >
        <Typography variant={"h5"}>Blade Number</Typography>
      </CutterMapCard>
      <CutterMapCard
        id={"CutterMapHeaderCard"}
        sx={
          {
            // width: "100%",
          }
        }
      >
        <Typography variant={"h5"}>Row</Typography>
      </CutterMapCard>
      <CutterMapCard
        id={"CutterMapHeaderCard"}
        sx={
          {
            // width: "100%",
          }
        }
      >
        <Typography variant={"h5"}>Cutters</Typography>
      </CutterMapCard>
    </Box>
  );
};

const CutterMapRow = ({ row, max, bladeNumber }) => {
  const numbersArray = useMemo(() => {
    return Array.from(Array(max).keys());
  }, [max]);
  return (
    <Box
      id={"CutterMapRow"}
      sx={{
        display: "grid",
        gridTemplateColumns: `2fr 1fr 7fr`,
        gridTemplateRows: `repeat(2,1fr)`,
        gridTemplateAreas: `
                "bladeNumber P primaryCuttersArea"
                "bladeNumber S secondaryCuttersArea"
                `,
        gap: 1,
        width: "100%",
      }}
    >
      <CutterMapCard
        id={"CutterMapRowCard"}
        sx={{
          gridArea: "bladeNumber",
        }}
      >
        <Typography variant={"h5"}>Blade_{bladeNumber + 1}</Typography>
      </CutterMapCard>
      <CutterMapCard
        id={"CutterMapRowCard"}
        sx={{
          gridArea: "P",
        }}
      >
        <Typography variant={"h5"}>P</Typography>
      </CutterMapCard>
      <CutterMapCard
        id={"CutterMapRowCard"}
        sx={{
          gridArea: "primaryCuttersArea",
          display: "flex",
          flexDirection: "row",
          justifyContent: "initial",
          gap: 1,
        }}
      >
        {row.primaryEvalCutters.map((cutter, index) => {
          return (
            <Tooltip
              title={
                <Typography variant={"h6"}>{cutter ? cutter : "O"}</Typography>
              }
              key={index}
            >
              <AvatarC>{cutter ? cutter : "O"}</AvatarC>
            </Tooltip>
          );
        })}
      </CutterMapCard>
      <CutterMapCard
        id={"CutterMapRowCard"}
        sx={{
          gridArea: "S",
        }}
      >
        <Typography variant={"h5"}>S</Typography>
      </CutterMapCard>
      <CutterMapCard
        id={"CutterMapRowCard"}
        sx={{
          gridArea: "secondaryCuttersArea",
          display: "flex",
          flexDirection: "row",
          justifyContent: "initial",
          gap: 1,
        }}
      >
        {row.secondaryEvalCutters.map((cutter, index) => {
          return (
            //Q: Как сделать title больше по размеру?
            //A: Можно модифицировать:
            // 1. Передать в Tooltip компонент, который будет отображаться при наведении:
            <Tooltip
              title={
                <Typography variant={"h6"}>{cutter ? cutter : "O"}</Typography>
              }
              key={index}
            >
              <AvatarC>{cutter ? cutter : "O"}</AvatarC>
            </Tooltip>
          );
        })}
      </CutterMapCard>
    </Box>
  );
};

export const RepairOrderReadonlyCutterMap = React.memo((props) => {
  const { orderId } = props;
  const [loading, setLoading] = React.useState(false);
  const [cutterMapArray, setCutterMapArray] = React.useState(null);
  const getCutterMap = () => {
    setLoading(true);
    return axios
      .get(`/api/orders/getCutterMap?id=${orderId}`)
      .then((res) => {
        setLoading(false);
        let cutterMap = null;
        cutterMap = JSON.parse(res.data.cutterMap);
        setCutterMapArray(
          Object.keys(cutterMap).map((key) => {
            return cutterMap[key];
          })
        );
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(err);
      });
  };

  useEffect(() => {
    getCutterMap();
  }, [orderId]);

  // console.log('cutterMap', cutterMapArray);
  if (loading || !cutterMapArray) {
    return <LoadingComponent isLoading={loading} />;
  }

  return (
    <>
      <Card
        variant={"outlined"}
        sx={{
          // display: "flex",
          display: "grid",
          gridArea: "RepairOrderReadonlyCutterMap",
          width: "100%",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <CutterMapHeader />
        {cutterMapArray.map((row, index) => {
          return (
            <>
              <Divider variant={"middle"} sx={{}} />
              <CutterMapRow
                key={index}
                row={row}
                max={row.max}
                bladeNumber={index}
              />
            </>
          );
        })}
      </Card>
    </>
  );
});
