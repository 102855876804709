import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Box,
  Stack,
  Typography,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useSearchParams, redirect } from "react-router-dom";

// async function login(userName, password, clearState) {
//   const requestParam = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify({
//       name: userName,
//       password: password,
//     }),
//   };

//   // await fetch("/api/login/loginNow", requestParam)
//   //   .then((response) => {
//   //     if (response.ok) return response.json();
//   //   })
//   //   .then((data) => {
//   //     if (data !== undefined) {
//   //       setLogin(data.token, data.userName, data.role, "/");
//   //       return;
//   //     }
//   //   });
// }

const Auth = () => {
  const navigate = useNavigate();
  const [link, setLink] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const tempCode = searchParams.get("code");
  const setLogin = (code) => {
    if (code) {
      fetch(
        "/api/login/GetUserInfo?" +
          new URLSearchParams({
            code: code,
          })
      )
        .then((response) => {
          console.log(response);

          if (response.ok) return response.json();
        })
        .then((data) => {
          if (data) {
            console.log(data);
            sessionStorage.setItem("tokenKey", data.tokens.access_token);
            sessionStorage.setItem(
              "userName",
              data.userInfo.preferred_username
            );
            sessionStorage.setItem("role", data.role);
            window.location.replace("/");
          }
        })
        .catch((error) => console.log(error));
    }
  };
  const getAuthLink = () => {
    const requestParam = {
      method: "GET",
    };
    fetch("/api/login/GetOAuthLink", requestParam)
      .then((response) => response.json())
      .then((data) => {
        setLink(data.link);
      });
  };

  useEffect(() => {
    const handleAuth = async () => {
      if (sessionStorage.getItem("tokenKey")) {
        navigate("/");
      } else if (tempCode) {
        await setLogin(tempCode);
      } else {
        await getAuthLink();
        if (link) {
          window.location.replace(link);
        }
      }
    };

    handleAuth();
  }, [link, tempCode]);

  return (
    <Stack alignItems="center" width="100%" height="100%" marginTop="5rem">
      <Box>
        <Typography variant="h4">
          Перенаправление на страницу авторизации...
        </Typography>
      </Box>
      <CircularProgress />
    </Stack>
  );
};

export default Auth;
