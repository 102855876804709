import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloseIcon from "@mui/icons-material/Close";
import useRECOILState from "../../../../../hooks/useRECOILState";
import { checkBoxStoreAtom } from "../../../../../atoms/RepairsAtoms/repairsAtoms";
import { urlSetParam } from "../../../../../atoms/repairEntryFetchData";
import { toast } from "react-toastify";
import axios from "axios";

const data = ["Order ID", "Serial", "Material", "Size", "Type"];
const dataKeys = ["evalution", "serial", "material", "size", "type"];
const initialErrors = {
  workOrderErrorText: "Work order is required",
  repairDateErrorText: "Repair date is required",
};
const RepairCompleteProperty = (props) => {
  const { label, rowData, index } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "justify",
        gap: 1,
        border: 1,
        borderRadius: 4,
        borderColor: `#CE0E2D`,
        padding: 1,
      }}
    >
      <Typography>{label}</Typography>
      <Typography variant={"h6"}>
        {rowData.state.row[dataKeys[index]]}
      </Typography>
    </Box>
  );
};

const RepairsCompleteModal = (props) => {
  const error = { ...initialErrors };
  const submitShipped = async () => {
    const orderId = props.rowData.state.row.evalution;
    if (workOrder === "") {
      setWorkOrderError((prevState) => ({
        ...prevState,
        workOrderError: true,
      }));
      toast.error("Fill work order");
      setWorkOrderError((prevState) => ({
        ...prevState,
        workOrderError: false,
      }));
      return;
    }
    await axios
      .post(
        `../api/orders/setWorkOrder?workOrder=${workOrder}&orderId=${orderId}`
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Work order added");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong in set work order");
      });

    await axios
      .get("/api/orders/setOrderShipped", {
        params: {
          orderId: props.rowData.state.row.evalution,
          completedDate: datePickerStore.state,
        },
      })
      .then((response) => {
        toast.success("Order Shipped");
      })
      .catch((error) => {
        toast.error("Order Shipped Failed");
      });

    // let urlApi = "/api/orders/setOrderShipped?orderId=%0";
    // debugger
    // let url = urlSetParam(urlApi, [props.rowData.state.row.evalution]);
    // fetch(url)
    //     .then((response) => {
    //         if (response.ok) {
    //             .success("Order Shipped");
    //             return;
    //         }
    //         .error("Order Shipped Failed");
    //     });
  };

  const { completeModalStore, datePickerStore, rowData } = props;
  const [workOrder, setWorkOrder] = useState("");
  const [workOrderError, setWorkOrderError] = useState(false);
  const [repairDateError, setRepairDateError] = useState(false);
  const [errorText, setErrorText] = useState(initialErrors);
  const checkBoxStore = useRECOILState(checkBoxStoreAtom);

  const handleDateChange = (date) => {
    if (date === null) {
      setRepairDateError(true);
      return;
    }
    setRepairDateError(false);
    datePickerStore.setState(date);
  };

  const handleWorkOrderChange = (e) => {
    e.target.value === "" ? setWorkOrderError(true) : setWorkOrderError(false);
    setWorkOrder(e.target.value);
  };

  const handleCloseModal = () => {
    completeModalStore.setState(false);
  };

  return (
    <Dialog open={completeModalStore.state} onClose={handleCloseModal}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          padding: 1,
        }}
      >
        <Card variant={"outlined"}>
          <Stack
            direction={"row"}
            // flexGrow={1}
            sx={{
              alignItems: "center",
              padding: 1,
            }}
          >
            <Typography variant={"h6"}>Get Location</Typography>
            <Stack justifyContent={"flex-end"} flexGrow={1}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <IconButton onClick={() => handleCloseModal()}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Stack>
          </Stack>
        </Card>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: `3fr 1fr`,
            gap: 1,
          }}
        >
          <Card variant={"outlined"}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: `repeat(2,1fr)`,
                gridTemplateRows: `repeat(3,1fr)`,
                gap: 1,
                padding: 1,
                alignItems: "center",
                // borderRadius: 1,
              }}
            >
              {data.map((label, i) => {
                return (
                  <RepairCompleteProperty
                    key={i}
                    label={label}
                    rowData={rowData}
                    index={i}
                  />
                );
              })}
              {/*<RepairCompleteProperty label={'Order ID'}/>*/}
            </Box>
          </Card>
          <Card variant={"outlined"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                padding: 1,
              }}
            >
              <Card
                variant={"outlined"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant={"h6"} textAlign={"center"}>
                  FM3000
                </Typography>
                <Typography
                  textAlign={"center"}
                  bgcolor={`#CE0E2D`}
                  color={"white"}
                >
                  Drill bits and service
                </Typography>
              </Card>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  alignItems: "center",
                  border: 1,
                  padding: 1,
                  borderColor: `#CE0E2D`,
                  borderRadius: 4,
                }}
              >
                <Typography>Complete</Typography>
                <Typography variant={"h6"} textAlign={"inherit"}>
                  Today is {new Date().toLocaleDateString()}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack
            direction={"row"}
            spacing={1}
            // gap={1}
          >
            {/*
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={checkBoxStore.state}
                    onClick={() => checkBoxStore.setState(!checkBoxStore.state)}
                  />
                }
                label={"Item shipped"}
              />
            </FormGroup>
              */}
            <Box display={"flex"}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat={"dd/MM/yyyy"}
                  onChange={handleDateChange}
                  value={datePickerStore.state}
                  // disabled={!checkBoxStore.state}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Select date of completing repair"}
                      fullWidth
                      helperText={
                        repairDateError ? error.repairDateErrorText : ""
                      }
                      error={repairDateError}
                      size={"small"}
                      variant={"outlined"}
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField
                label={"Work Order"}
                variant={"outlined"}
                size={"small"}
                onChange={handleWorkOrderChange}
                error={workOrderError}
                helperText={workOrderError ? error.workOrderErrorText : ""}
                sx={{
                  marginLeft: 1,
                }}
              />
            </Box>
          </Stack>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            {/*TODO: Добавить обработчик*/}
            <Button size={"small"} variant={"outlined"} onClick={submitShipped}>
              Submit
            </Button>
          </Box>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default RepairsCompleteModal;
