import React, { useState, useEffect } from "react";
import UseObjectState from "../../../../../hooks/useObjectState";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  cutterMapRows,
  cutterMapTextFieldsAtoms,
  cutterMaterialTextFieldStore,
  cutterSerialNumberTextFieldStore,
} from "../../../../../atoms/cutterMapTextFieldsAtoms";
import { Autocomplete, TextField, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const CutterMapMaterialTextfield = (props) => {
  const [serial, setSerial] = useRecoilState(cutterSerialNumberTextFieldStore);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [materials, setMaterials] = useState([]);
  const [drills, setDrills] = useState([]);
  const [size, setSize] = useState("");
  const [type, setType] = useState("");
  const [rows, setRows] = useRecoilState(cutterMapRows);

  const handleMaterialChange = (e, newValue) => {
    setSelectedMaterial(newValue);
    axios
      .get(
        `api/DrillsControllers/GetDrillsByMaterial?materialCode=${newValue.code}`,
        {}
      )
      .then((response) => {
        console.log(response);
        let data = response.data.result;
        setDrills(data);
        const { size, type } = data[0];
        setSize(size);
        setType(type);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Ошибка получения информации по артикулу.");
      });
    axios
      .get(`api/material/getCutterPackByMaterial?materialCode=${newValue.code}`)
      .then((response) => {
        console.log(response);
        let numeratedRows = response.data.cutters.map((cutter, index) => ({
          ...cutter,
          index: index,
        }));
        setRows(numeratedRows);
        console.log(response.data.cutters);
        console.log(numeratedRows);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Ошибка получения наборов резцов.");
      });
  };

  const asyncGetMaterials = async () => {
    axios
      .get(`api/material/getAllMaterials`, {})
      .then((response) => {
        setMaterials(response.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Ошибка получения артикулов.");
      });
  };
  useEffect(() => {
    //asyncGetCutters();
    asyncGetMaterials();
  }, []);
  return (
    <React.Fragment>
      <Autocomplete
        disablePortal
        value={selectedMaterial}
        onChange={handleMaterialChange}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="combo-box"
        options={materials}
        sx={{ width: 300 }}
        getOptionLabel={(cutter) => cutter.code.toString()}
        getOptionKey={(cutter) => cutter.code}
        renderInput={(params) => (
          <TextField {...params} label="Выберите артикул долота" />
        )}
      />
      <Typography variant="h7">Размер: {size}</Typography>
      <Typography variant="h7">Тип: {type}</Typography>
    </React.Fragment>
  );
};

export default CutterMapMaterialTextfield;
