import { Button, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DbData, Drill, Scrap } from "../../../../atoms/repairEntryFetchData";
import Divider from "@mui/material/Divider";
import { toast } from "react-toastify";
import {
  DataGrid,
  GridToolbar,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import axios from "axios";
import { AddCircle, Delete, FileUpload } from "@mui/icons-material";
import { StyledProgress } from "./Import";

/* Компонент, который позволяет пользователю добавлять детализацию в базу данных. */
export const AddDrill = () => {
  const [rows, setRows] = useState([]);
  const [progress, setProgress] = React.useState(0);

  const [importedFile, setImportedFile] = React.useState("");
  const [imported, setImported] = React.useState(false);
  const [lastImportDate, setLastImportDate] = React.useState("");
  const stopUpload = (interval) => {
    clearInterval(interval);
    setProgress(0);
  };
  const getProgress = async (interval) => {
    if (progress === 100) {
      clearInterval(interval);
      return;
    }
    try {
      const response = await axios.get("/api/Excel/getProgress");
      setProgress(response.data);
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong");
    }
  };
  const drillColumns = [
    {
      field: "DrillName",
      headerName: "Наименование долота",
      flex: 1,
    },
    {
      field: "Serial",
      headerName: "Серийный номер долота",
      flex: 1,
    },
    {
      field: "MaterialCode",
      headerName: "Артикул",
      flex: 1,
    },
    {
      field: "Location",
      headerName: "Эксплуатирующее подразделение",
      flex: 1,
    },
    {
      field: "Size",
      headerName: "Размер",
      flex: 1,
    },
    {
      field: "Type",
      headerName: "Тип",
      flex: 1,
    },
  ];
  const handleFileChange = (e) => {
    if (e.target.files.length === 0) {
      toast.error("Файл не выбран");
      return;
    }
    setImportedFile(e.target.files[0]);
  };
  const importExcel = async () => {
    if (!importedFile) {
      toast.error("Файл не выбран");
      return;
    }
    if (!importedFile.name.endsWith(".xlsx")) {
      toast.error("Формат должен быть .xlsx");
      return;
    }

    try {
      const formData = new FormData();

      formData.append("file", importedFile);
      setImported(false);
      const interval = setInterval(() => getProgress(interval), 3000);

      toast.info("Импорт начался");
      const response = await axios.post(
        "/api/Excel/importExcelDrills",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setImported(true);

      if (response.status === 200) {
        toast.success("Импорт завершен!");
        clearInterval(interval);
        asyncGetDrills();
      }
    } catch (e) {
      console.log(e);
      toast.error(
        "Файл не может быть обработан. Пожалуйста, проверьте соответствие шаблону"
      );
    }
  };
  const asyncGetDrills = async () => {
    axios
      .get(`api/DrillsControllers/getDrills`, {})
      .then((response) => {
        setRows(response.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Ошибка получения долот.");
      });
  };
  const asyncGetLastImport = async () => {
    axios
      .get(`api/importhistory/getLastImportDrills`, {})
      .then((response) => {
        let lastImportDate = response.data.lastImport;
        if (!lastImportDate) {
          lastImportDate = "отсутствует";
        } else {
          lastImportDate = new Date(lastImportDate);
          lastImportDate = lastImportDate.toLocaleString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          });
        }
        setLastImportDate(lastImportDate);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Ошибка получения последней даты.");
      });
  };

  useEffect(() => {
    asyncGetLastImport();
    asyncGetDrills();
  }, []);

  return (
    <Box sx={{ padding: "10px", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          marginX: "30px",
          marginBottom: "20px",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item xs={6}>
            <Typography variant="h5" component="div">
              Импорт файла из 1С
            </Typography>
            <Typography variant="body2">
              Файл должен быть в формате .xlsx
            </Typography>
            <Typography variant="body2">
              Последний импорт: {lastImportDate}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
                gap: "30px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <input
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    component="span"
                    startIcon={<AddCircle />}
                  >
                    Импорт из 1С
                  </Button>
                </label>
                <Button
                  variant={"outlined"}
                  color={"secondary"}
                  startIcon={<FileUpload />}
                  onClick={importExcel}
                  disabled={!importedFile}
                >
                  Загрузить
                </Button>
              </Box>
              {importedFile && (
                <StyledProgress variant="determinate" value={progress} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {rows && (
        <DataGrid
          columns={drillColumns}
          rows={rows}
          getRowId={(row) => row.Serial}
          sx={{
            height: "70vh",
          }}
        />
      )}
    </Box>
  );
};

export default AddDrill;
