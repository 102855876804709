import React, { useMemo } from "react";
import {
  Avatar,
  Box,
  Card,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useRecoilValue } from "recoil";
import {
  cutterBladeNumber,
  cutterDataGridStore,
  cutterMapMaxCuttersCount,
  cutterPrimaryArray,
  cutterSecondaryArray,
} from "../../../../../../atoms/cutterMapTextFieldsAtoms";
import BladeTemplateNumberAvatar from "./BladeTemplateNumberAvatar";
import BladeTemplateCutterAvatar from "./BladeTemplateCutterAvatar";
import useObjectState from "../../../../../../hooks/useObjectState";
import useRECOILState from "../../../../../../hooks/useRECOILState";
import BladeTemplateSecondaryCutterAvatar from "./BladeTemplateSecondaryCutterAvatar";
import BladeTemplateCuttersPrimary from "./BladeTemplateCuttersPrimary";
import BladeTemplateCuttersNumbers from "./BladeTemplateCuttersNumbers";
import BladeTemplateCuttersSecondary from "./BladeTemplateCuttersSecondary";
import useChangeBladesState from "../../../../../../hooks/useChangeBladesState";
import { PrimaryRowButtons } from "./PrimaryRowButtons";
import { SecondaryRowButtons } from "./SecondaryRowButtons";
import { toast } from "react-toastify";

const BladeTemplateCreation = (props) => {
  const max = useRecoilValue(cutterMapMaxCuttersCount);
  const dataGridState = useRecoilValue(cutterDataGridStore);
  const {
    pushToPrimaryCutters,
    pushToCutters,
    addPrimaryCutter,
    addSecondaryCutter,
    removePrimaryCutter,
    removeSecondaryCutter,
  } = useChangeBladesState();

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          padding: 1,
          // maxHeight: '70%',
          // overflow: 'auto',
          flexDirection: "column",
        }}
      >
        <Card
          variant={"outlined"}
          sx={{
            display: "grid",
            // overflow: 'auto',
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: `0.5fr 0.3fr 3fr 0.2fr`,
              // gridTemplateRows: `repeat(3,1fr)`,
              gridTemplateAreas: `"n c c b"
                        "n c c b"
                        "n c c b"`,
              gap: 1,
              padding: 1,
              alignItems: "center",
            }}
          >
            <Box
              id={"Blade_Number"}
              sx={{
                display: "grid",
                gridArea: `n`,
                // gridTemplateColumns: '1fr',
                // gridTemplateRows: `0.5fr repeat(2,1fr)`,
                // backgroundColor: 'pink',
                alignItems: "center",
              }}
            >
              <Typography align="center" variant={"h5"}>
                Лопасть №{props.index + 1}
              </Typography>
            </Box>
            <Paper
              variant={"outlined"}
              sx={{
                display: "grid",
                gridArea: "c",
                gap: 1,
                padding: 1,
              }}
            >
              <Box
                id={"Cutters_Box"}
                sx={{
                  display: "grid",
                  gridTemplateColumns: `0.1fr 1fr 0.1fr`,
                  gridTemplateRows: `repeat(3,1fr)`,
                  alignItems: "center",
                  overflow: "auto",
                  gap: 1,
                  /*gridTemplateAreas: `"row numbers ."
                                    "P primary buttonP"
                                    "S secondary buttonS`,*/
                }}
              >
                <Typography
                  variant={"h5"}
                  sx={{
                    pr: 1,
                  }}
                >
                  Ряд
                </Typography>
                <BladeTemplateCuttersNumbers blade={props.blade} />
                <Box />
                <Typography variant={"h5"}>1</Typography>

                <BladeTemplateCuttersPrimary
                  blade={props.blade}
                  dataGridState={dataGridState}
                  pushToCutters={pushToPrimaryCutters}
                  bladeIndex={props.index}
                  max={max}
                />
                <PrimaryRowButtons
                  index={props.index}
                  addPrimaryCutter={addPrimaryCutter}
                  removePrimaryCutter={removePrimaryCutter}
                />
                <Typography variant={"h5"}>2</Typography>
                <BladeTemplateCuttersSecondary
                  blade={props.blade}
                  dataGridState={dataGridState}
                  pushToCutters={pushToCutters}
                  bladeIndex={props.index}
                  max={max}
                />
                <SecondaryRowButtons
                  index={props.index}
                  addSecondaryCutter={addSecondaryCutter}
                  removeSecondaryCutter={removeSecondaryCutter}
                />
              </Box>
            </Paper>
          </Box>
        </Card>
      </Box>
    </React.Fragment>
  );
};
export default BladeTemplateCreation;
