import React from 'react';
import {Box, Card, Typography} from "@mui/material";
import BitEvalTextFieldWithSearch from "./BitEvalTextFieldWithSearch/BitEvalTextFieldWithSearch";
import UseRECOILState from "../../../../hooks/useRECOILState";
import TextItem from "../../OtherComponents/TextItem";

const BitEvalHeader = (props) => {


    return (
        <React.Fragment>
            <Card variant={"outlined"}
                  sx={{
                      display: "grid",
                      padding: 1,
                      gap: 1,
                  }}
            >
                <Box
                    id={'BitEvalHeaderBox'}
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: `2fr 1fr`,
                        width: '100%',
                        // gap: 1,
                        alignItems: 'center',
                        // gridTemplateRows: '1fr',
                        // backgroundColor: 'pink',
                    }}
                >
                    <Box sx={{width: "20%"}}>
                        <TextItem size={"h6"} text={"Current Evaluations"}/>
                    </Box>
                    <BitEvalTextFieldWithSearch/>
                </Box>
            </Card>

        </React.Fragment>
    );
}

export default BitEvalHeader;