import { Button, MenuItem, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { DbData, Order } from "../../../../../atoms/repairEntryFetchData";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  allOrdersBitEval,
  bitEvalOrder,
  bitEvalOrderObj,
} from "../../../../../atoms/staticAtoms";
import React from "react";

const FooterNotes = ({ setIsUpdated, isUpdated }) => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [allOrders, setAllOrders] = useRecoilState(allOrdersBitEval);

  const orderObj = useRecoilValue(bitEvalOrderObj);
  const orderIdGlobal = orderObj.OrderId;
  const [orderIdLocal, setOrderIdLocal] = useState("");

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleOrderChange = (e) => setOrderIdLocal(e.target.value);
  const handleTextChange = (e) => setText(e.target.value);

  useEffect(() => {
    setOrderIdLocal(orderIdGlobal);
    let order = allOrders.find((order) => order.OrderId === orderIdGlobal);
    if (order === undefined) {
      setAllOrders([
        ...allOrders,
        {
          OrderId: orderIdGlobal,
          DrillSerial: orderObj.DrillSerial,
        },
      ]);
    }
  }, []);

  const addNote = async () => {
    let dbData = new DbData(
      Order.model,
      [Order.fields.Id, Order.fields.Notes],
      Order.fields.Id,
      orderIdLocal
    );
    await dbData.sendRequest();

    let result = dbData.result;
    if (result === "No find" && orderIdLocal !== orderIdGlobal) {
      console.log("Order not found");
      return;
    } else if (result === "No find" && orderIdLocal === orderIdGlobal) {
      result = [
        {
          OrderId: orderIdLocal,
          FieldNotes: [],
        },
      ];
    }

    let notes = [];
    if (result[0].FieldNotes !== "NO DATA" && orderIdLocal !== orderIdGlobal) {
      notes = JSON.parse(result[0].FieldNotes);
    }

    let notesObj = {
      Title: title,
      Text: text,
    };

    notes.push(notesObj);
    orderObj.FieldNotes.push(notesObj);

    if (orderIdLocal !== orderIdGlobal) {
      Order.setNotes(orderIdLocal, notes);
    }
    setIsUpdated(!isUpdated);
  };

  return (
    <Box
      sx={{
        padding: "10px",
        border: "1px solid #e0e0e0",
        marginY: "10px",
      }}
    >
      <Box>
        <TextField
          label={"Title"}
          onChange={(e) => {
            handleTitleChange(e);
          }}
        ></TextField>
        <TextField
          value={orderIdLocal}
          select
          label={"Order id"}
          onChange={(e) => handleOrderChange(e)}
          sx={{
            width: "200px",
            marginX: "10px",
          }}
        >
          {allOrders.map((item) => {
            return (
              <MenuItem key={item.OrderId} value={item.OrderId}>
                {item.OrderId}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
      <TextField
        label="New Note"
        multiline
        rows={3}
        variant="outlined"
        sx={{ marginY: "8px", width: "30%" }}
        onChange={(e) => handleTextChange(e)}
      />
      <Box>
        <Button
          variant="contained"
          color={"error"}
          disabled={
            orderIdLocal.length === 0 || title.length === 0 || text.length === 0
          }
          onClick={() => addNote()}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default FooterNotes;
