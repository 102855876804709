import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ImageAndTableLayout from "./components/ImageAndTableLayout";
import { useRecoilState } from "recoil";
import { cutterMapTextFieldsAtoms } from "../../../../atoms/cutterMapTextFieldsAtoms";
import BladeTemplateLayout from "./components/CutterMapBladeTemplateLayout/BladeTemplateLayout";
import TextItem from "../../OtherComponents/TextItem";
import useObjectState from "../../../../hooks/useObjectState";

/**
 * Основная компонента Cutter-Map-а
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CutterMap = (props) => {
  useEffect(() => {
    document.title = "Cutter Mapper";
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        gap: 1,
        padding: 1,
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", height: "100%" }}>
        <ImageAndTableLayout />
        <BladeTemplateLayout />
      </Box>
    </Box>
  );
};

export default CutterMap;
