import React, {useMemo} from 'react';
import {Box} from "@mui/material";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import GppBadIcon from "@mui/icons-material/GppBad";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import moment from "moment";


/* Определение столбцов, которые будут отображаться в таблице. */

/* Компонент, отображающий таблицу данных. */
const ArchiveTable = (props) => {
    const {rowData, modalStore} = props;
    const testColumns = useMemo(() => {
        return [
            {
                field: 'orderId',
                headerName: 'orderId',
                flex: 1,
            },
            {
                field: 'serialNumber',
                headerName: 'Serial',
                flex: 1,
            },
            {
                field: 'materialNumber',
                headerName: 'Material',
                flex: 1,
            },
            {
                field: 'size',
                headerName: 'Size',
                flex: 1,
            },
            {
                field: 'type',
                headerName: 'Type',
                flex: 1,
            },
            {
                field: 'location',
                headerName: 'Location',
                flex: 1,
            },
            {
                field: 'scrap',
                headerName: 'Scrap',
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    return params.value === "True" ? <GppBadIcon style={{color: "red"}}/> :
                        <VerifiedUserIcon style={{color: "green"}}/>
                },
                flex: 1,
            },
            {
                field: 'start_repair_date',
                headerName: 'Start repair date',
                flex: 1,
                valueFormatter: (params) => {
                     return moment(params.value).format('DD.MM.YYYY');
                }
            },
            {
                field: 'end_repair_date',
                headerName: 'End repair date',
                flex: 1,
                valueFormatter: (params) => {
                    return moment(params.value).format('DD.MM.YYYY');
                }
            }
        ];

    })
    return (
        <Box
            sx={{
                display: 'flex',
                height: '100%',
            }}
        >
            <DataGrid columns={testColumns} rows={props.rows}
                      onRowDoubleClick={(row) => {
                          modalStore.setState(true);
                          rowData.setState(row);
                      }}
                      components={{Toolbar: GridToolbar}}
                      componentsProps={{
                          toolbar: {
                              showQuickFilter: true,
                              quickFilterProps: {debounceMs: 500},
                          },
                      }}
                      sx={{
                          width: '100%',
                          height: '100%',
                      }}
            />
        </Box>
    );
}

export default ArchiveTable;