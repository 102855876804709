import React, {useEffect, useState} from 'react';
import {Box, Card, Typography} from "@mui/material";
import {useRecoilState} from "recoil";
import {selectedOrder, selectedOrderDrill} from "../../../../../../atoms/staticAtoms";
import {DbData, Drill} from "../../../../../../atoms/repairEntryFetchData";
import TextItem from "../../../../OtherComponents/TextItem";

const RepairOrderHeader = (props) => {

    const [orderId, setOrderId] = useRecoilState(selectedOrder);
    const [drillSerial, setDrillSerial] = useRecoilState(selectedOrderDrill);

    const [drillData, setDrillData] = useState({
        serial: "",
        material: "",
        size: "",
        type: "",
    });

    const loadInfo = async () => {
        let dbDataDrillInfo = new DbData(Drill.model, [Drill.fields.Serial, Drill.fields.Material, Drill.fields.Size, Drill.fields.Type], Drill.fields.Serial, drillSerial);
        await dbDataDrillInfo.sendRequest();

        let drillObj = {
            serial: dbDataDrillInfo.result[0][Drill.fields.Serial],
            material: dbDataDrillInfo.result[0][Drill.fields.Material],
            size: dbDataDrillInfo.result[0][Drill.fields.Size],
            type: dbDataDrillInfo.result[0][Drill.fields.Type],
        }

        setDrillData(drillObj);
    }

    useEffect(() => {
        loadInfo();
    }, [])
    return (
        <Card variant={"outlined"}>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(5,1fr)`,
                    gridTemplateRows: `repeat(2,1fr)`,
                    gap: 1,
                    height: 'auto',
                    padding: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{display: "flex"}}>
                    <TextItem text={"Order ID"} size={"h6"}/>
                    <TextItem text={orderId} size={"h6"} ml={5}/>
                </Box>
                <Box sx={{display: "flex"}}>
                    <TextItem text={"Drill Serial"} size={"h6"}/>
                    <TextItem text={drillData.serial} size={"h6"} ml={5}/>
                </Box>
                <Box sx={{display: "flex"}}>
                    <TextItem text={"Drill Material"} size={"h6"}/>
                    <TextItem text={drillData.material} size={"h6"} ml={5}/>
                </Box>
                <Box sx={{display: "flex"}}>
                    <TextItem text={"Drill Size"} size={"h6"}/>
                    <TextItem text={drillData.size} size={"h6"} ml={5}/>
                </Box>
                <Box sx={{display: "flex"}}>
                    <TextItem text={"Drill Type"} size={"h6"}/>
                    <TextItem text={drillData.type} size={"h6"} ml={5}/>
                </Box>
            </Box>
        </Card>
    );
}

export default RepairOrderHeader;