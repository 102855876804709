import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import {
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction,
  CircularProgress,
  Card,
  styled,
  Toolbar,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import {
  ArrowDownward,
  ArrowUpward,
  Category,
  DateRange,
  Download,
  PictureAsPdf,
  TextSnippet,
  Title,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";

const StyledCard = styled(Card)(({ theme, sx }) => ({
  boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  transition: "0.3s",
  borderRadius: "16px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  minWidth: 256,
  wordWrap: "break-word",
  backgroundColor: theme.palette.background.paper,
  backgroundClip: "border-box",
  border: "0 solid rgba(0,0,0,0.125)",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "& .MuiTypography-root": {
      color: theme.palette.primary.contrastText,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.contrastText,
    },
  },
  "& .MuiCardContent-root:last-child": {
    paddingBottom: "16px",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },

  ...sx,
}));

const StyledToolbar = styled(Toolbar)(({ theme, sx }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 16px",
  ...sx,
}));

const DocumentsListRepairs = (props) => {
  const { drillSerial } = props;
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [sort, setSort] = React.useState({
    by: "created",
    order: "desc",
  });

  const sortFiles = async (files) => {
    const sortedFiles = _.orderBy(files, sort.by, sort.order);
    console.log("sorted", sortedFiles);
    console.log("unsorted", files);
    setFiles(sortedFiles);
  };
  const handleSort = (by) => {
    if (sort.by === by) {
      setSort({
        ...sort,
        order: sort.order === "desc" ? "asc" : "desc",
      });
    } else {
      setSort({
        by,
        order: "desc",
      });
    }
  };

  const renderSortIcon = (by) => {
    if (sort.by === by) {
      if (sort.order === "desc") {
        return <ArrowDownward />;
      } else {
        return <ArrowUpward />;
      }
    } else {
      return null;
    }
  };

  const loadFiles = async () => {
    setLoading(true);
    const response = await axios.get(
      `/api/DrillsControllers/getAllDrillDocuments`,
      { params: { serial: drillSerial } }
    );
    setFiles(response.data.result);
    setLoading(false);
  };

  useEffect(() => {
    loadFiles()
      .then(() => {
        console.log("Files loaded");
      })
      .catch((err) => {
        toast.error("Error loading files");
        console.log(err);
      });
  }, []);

  useEffect(() => {
    sortFiles(files).then(() => {
      console.log("Files sorted");
    });
  }, [sort]);
  const handleFileDownload = (filePath) => {
    window.open(filePath);
  };

  const renderFileIcon = (file) => {
    switch (file.type) {
      case ".pdf":
        return <PictureAsPdf />;
      case ".docx":
      case ".doc":
      case ".txt":
        return <TextSnippet />;
      case ".gif":
        return (
          <Box
            component="img"
            src={file.path}
            sx={{ width: 100, height: 100, objectFit: "contain" }}
          />
        );
      default:
        return (
          <Box
            component="img"
            src={file.path}
            sx={{ width: 100, height: 100, objectFit: "contain" }}
          />
        );
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        padding: 1,
        height: "100%",
        gap: 1,
        overflow: "auto",
      }}
    >
      <List
        sx={{
          gap: 2,
          overflow: "auto",
          width: "100%",
          padding: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : files.length === 0 ? (
          <Typography
            variant="h6"
            color="text.secondary"
            component="div"
            sx={{ textAlign: "center" }}
          >
            No documents found
          </Typography>
        ) : (
          <StyledToolbar>
            <Typography variant="h6" color="text.secondary" component="div">
              Documents
            </Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Tooltip title="Sort by date">
                <IconButton onClick={() => handleSort("created")}>
                  <DateRange />
                  {renderSortIcon("created")}
                </IconButton>
              </Tooltip>
              <Tooltip title="Sort by type">
                <IconButton onClick={() => handleSort("type")}>
                  <Category />
                  {renderSortIcon("type")}
                </IconButton>
              </Tooltip>
              <Tooltip title="Sort by name">
                <IconButton onClick={() => handleSort("name")}>
                  <Title />
                  {renderSortIcon("name")}
                </IconButton>
              </Tooltip>
            </Box>
          </StyledToolbar>
        )}
        {files.map((file, index) => {
          return (
            <StyledCard
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                padding: 1,
                gap: 2,
              }}
            >
              <ListItem
                key={index}
                secondaryAction={
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="download"
                      onClick={() => handleFileDownload(file.path)}
                    >
                      <Download />
                    </IconButton>
                  </ListItemSecondaryAction>
                }
              >
                <ListItemIcon>{renderFileIcon(file)}</ListItemIcon>
                <ListItemText
                  primary={file.name}
                  secondary={moment(file.created).format("DD/MM/YYYY")}
                />
              </ListItem>
            </StyledCard>
          );
        })}
      </List>
    </Box>
  );
};

export default DocumentsListRepairs;
