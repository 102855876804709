import React, { useEffect, useState } from "react";
import { DrawerCustom } from "../../components";
import { Outlet } from "react-router-dom";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import RouteIcon from "@mui/icons-material/Route";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BuildIcon from "@mui/icons-material/Build";
import ArchiveIcon from "@mui/icons-material/Archive";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SpeedIcon from "@mui/icons-material/Speed";
import DataArrayIcon from "@mui/icons-material/DataArray";
import { useRecoilState } from "recoil";
import { savedPages } from "../../atoms/staticAtoms";
const Layout = () => {
  let RepairEntry = {
    text: "Создание заявки",
    icon: KeyboardIcon,
    url: "/repair-entry",
  };
  let BitEval = {
    text: "Оценка долота",
    icon: RouteIcon,
    url: "/bit-evaluation",
  };
  let Ordering = {
    text: "Заказ",
    icon: ReceiptIcon,
    url: "/ordering",
  };
  let Repairs = {
    text: "Текущий ремонт",
    icon: BuildIcon,
    url: "/repairs",
  };
  let Archive = { text: "Архив", icon: ArchiveIcon, url: "/archive" };
  let CutterMap = {
    text: "Карта резцов",
    icon: AdminPanelSettingsIcon,
    url: "/cuttermap",
  };
  let BitRun = { text: "Bit Run", icon: SpeedIcon, url: "/bit-run" };
  let DB = { text: "Администрирование", icon: DataArrayIcon, url: "/TestDB" };

  const pagesList = {
    REPAIRENTRY: RepairEntry,
    BITEVALUATION: BitEval,
    ORDERING: Ordering,
    REPAIRS: Repairs,
    ARCHIVE: Archive,
    CUTTERMAP: CutterMap,
    BITRUN: BitRun,
    DB: DB,
  };

  const [allowPages, setAllowPages] = useState([]);
  const [savedAllowPages, setSavedAllowsPages] = useRecoilState(savedPages);
  const getAllowPages = async () => {
    let accessPages = [];
    if (savedAllowPages.length > 0) {
      savedAllowPages.forEach((page) => {
        accessPages.push(pagesList[page]);
      });
      setAllowPages(accessPages);
      return;
    }

    let params = "";

    Object.keys(pagesList).forEach((key) => {
      params += "pages=" + key + "&";
    });

    let data = await fetch("/api/login/checkAccess?" + params)
      .then((response) => {
        return response.ok ? response.json() : false;
      })
      .then((data) => {
        if (data !== false && data != null) {
          return data["result"];
        }
        return false;
      });

    if (!data) return;

    let accessPagesKeys = [];
    Object.keys(data).forEach((key) => {
      if (data[key] === true) {
        accessPagesKeys.push(key);
        accessPages.push(pagesList[key]);
      }
    });
    setSavedAllowsPages(accessPagesKeys);
    setAllowPages(accessPages);
  };

  useEffect(() => {
    console.log("Layout useEffect");
    getAllowPages();
  }, []);

  return (
    <>
      <header>
        <DrawerCustom pages={allowPages} />
      </header>
      <main
        style={{
          marginTop: "70px",
          width: "100%",
          overflow: "auto",
        }}
      >
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
