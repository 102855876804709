import React from "react";
import { Avatar, Card, Tooltip } from "@mui/material";
import UseObjectState from "../../../../../../hooks/useObjectState";
import { useSetRecoilState } from "recoil";

const BladeTemplateAvatar = (props) => {
  const { dataGridState, index, pushToCutters, bladeIndex, cutter } = props;

  const avatarState = UseObjectState(cutter);
  const handleAvatarClick = () => {
    if (dataGridState) {
      const replaceResults = pushToCutters(
        bladeIndex,
        index,
        dataGridState,
        cutter
      );
      switch (true) {
        case replaceResults.replacedAnother: {
          avatarState.setState(dataGridState.name);
          break;
        }
        case replaceResults.replacedYourself: {
          avatarState.setState("None");
          break;
        }
        default:
          break;
      }
    } else {
      alert("Пожалуйста, выберите резец");
    }
  };

  return (
    <React.Fragment>
      <Tooltip title={avatarState.state}>
        <Avatar
          onClick={handleAvatarClick}
          sx={{
            fontSize: 12,
          }}
        >
          {avatarState.state}
        </Avatar>
      </Tooltip>
    </React.Fragment>
  );
};

const BladeTemplateCuttersSecondary = (props) => {
  const { pushToCutters, bladeIndex } = props;
  const { secondary, max, cutters } = props.blade;
  const maxArray = Array.from({ length: secondary }, (_, i) => i + 1);

  return (
    <React.Fragment>
      <Card
        variant={"outlined"}
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${max},1fr)`,
          gap: 1,
          padding: 1,
        }}
      >
        {maxArray.map((number, i) => {
          return (
            <React.Fragment key={number}>
              <BladeTemplateAvatar
                index={i}
                dataGridState={props.dataGridState}
                pushToCutters={pushToCutters}
                bladeIndex={bladeIndex}
                cutter={cutters[1].secondary[i]}
              />
            </React.Fragment>
          );
        })}
      </Card>
    </React.Fragment>
  );
};

export default BladeTemplateCuttersSecondary;
