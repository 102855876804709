import React from 'react';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import UseRECOILState from "../../../../../../../hooks/useRECOILState";
import {Box, TextField} from "@mui/material";
import {scrapDatePickerStore} from "../../../../../../../atoms/bitEvaluationatoms";
import {useRecoilValue} from "recoil";
import {bitEvalOrderObj, bitEvalSelectedDrill} from "../../../../../../../atoms/staticAtoms";


const ScrapDatePicker = (props) => {
    const selectedDrill = useRecoilValue(bitEvalSelectedDrill);
   
    const datePickerStore = UseRECOILState(scrapDatePickerStore);
    const orderObj = useRecoilValue(bitEvalOrderObj);

    const handleChange = (newValue) => {
        datePickerStore.setState(newValue.toDateString());
        orderObj.ScrapDate = newValue.toDateString();
    }
    if (selectedDrill.Scrap && selectedDrill.ScrapDate) {
        datePickerStore.setState(selectedDrill.ScrapDate);
    }
    return (
        <React.Fragment>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker onChange={handleChange} value={datePickerStore.state} renderInput={(params) =>
                    <TextField  {...params} label={'Scrap date'} size={"small"} variant={"outlined"}/>
                }/>
            </LocalizationProvider>
        </React.Fragment>
    )
        ;
}

export default ScrapDatePicker;