import React from "react";
import { Box, Button, Card, Stack } from "@mui/material";
import UseChangeBladesState from "../../../../../../hooks/useChangeBladesState";
import UseRECOILState from "../../../../../../hooks/useRECOILState";
import { bladeStore } from "../../../../../../atoms/cutterMapState";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  cutterMapRows,
  cutterSerialNumberTextFieldStore,
} from "../../../../../../atoms/cutterMapTextFieldsAtoms";
import { Drill } from "../../../../../../atoms/repairEntryFetchData";
import { toast } from "react-toastify";

import axios from "axios";

const BladeTemplateFooter = (props) => {
  const { removeLastBlade } = UseChangeBladesState();
  const tempBladeStore = UseRECOILState(bladeStore);
  const [serial, setSerial] = useRecoilState(cutterSerialNumberTextFieldStore);
  const cutterRows = useRecoilValue(cutterMapRows);

  const formatCutterRows = () => {
    let temp = [];
    cutterRows.forEach((row) => {
      temp.push({
        name: row.Name,
        count: row.Qty,
      });
    });
    return temp;
  };
  const addCutterMap = () => {
    const formattedCutterRows = formatCutterRows();
    console.log(tempBladeStore);
    console.log(formattedCutterRows);
    // axios
    //   .put(`api/material/updateMaterial`, {
    //     Code: serial,
    //     CutterMap: JSON.stringify(tempBladeStore.state),
    //     CuttersCodes: JSON.stringify(formattedCutterRows),
    //   })
    //   .then((res) => {
    //     res.status === 204
    //       ? toast.error("Not found Material number")
    //       : toast.success(
    //           `Cutter map to ${serial.toString()} material number added successfully`
    //         );
    //   })
    //   .catch((error) => {
    //     toast.error(`${error.message}: ${error.response.data.message}`);
    //   });
    /*Drill.setCutterMap(serial, tempBladeStore.state).then
        (response => {
                if (response.ok) {
                    toast.success("Cutter Map added");
                    return
                }
                toast.error("Cutter Map not added");
            }
        )*/
  };

  const handleRemoveLastBladeButtonClick = () => {
    removeLastBlade();
  };

  const handleResetButton = () => {
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Card
        variant={"outlined"}
        sx={{
          width: "100%",
        }}
      >
        <Box
          id={"FooterGrid"}
          sx={{
            display: "grid",
            gridTemplateColumns: `0.5fr 2fr 1fr`,
            gridTemplateAreas: `". . buttons"`,
            gridTemplateRows: "1fr",
            maxHeight: "100%",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridArea: `buttons`,
              maxHeight: "100%",
            }}
          >
            <Stack
              display={"flex"}
              direction={"row"}
              gap={1}
              padding={1}
              alignItems={"center"}
              justifyContent={"end"}
            >
              <Button
                variant={"outlined"}
                onClick={handleRemoveLastBladeButtonClick}
                sx={{
                  whiteSpace: "nowrap", // Предотвращает перенос текста на новую строку
                  textOverflow: "ellipsis", // Показывает многоточие, если текст не помещается в кнопку
                  overflow: "hidden", // Скрытие текста, который не помещается в кнопку
                }}
              >
                Удалить последнюю лопасть
              </Button>
              <Button
                variant={"outlined"}
                onClick={addCutterMap}
                sx={{
                  whiteSpace: "nowrap", // Предотвращает перенос текста на новую строку
                  textOverflow: "ellipsis", // Показывает многоточие, если текст не помещается в кнопку
                  overflow: "hidden", // Скрытие текста, который не помещается в кнопку
                }}
              >
                Сохранить
              </Button>
              <Button
                size={"medium"}
                variant={"outlined"}
                onClick={handleResetButton}
                sx={{
                  whiteSpace: "nowrap", // Предотвращает перенос текста на новую строку
                  textOverflow: "ellipsis", // Показывает многоточие, если текст не помещается в кнопку
                  overflow: "hidden", // Скрытие текста, который не помещается в кнопку
                }}
              >
                Очистить
              </Button>
            </Stack>
          </Box>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default BladeTemplateFooter;
