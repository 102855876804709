import React, {useState} from "react";
import {
    Box,
    Button,
    Card,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import TextItem from "../../OtherComponents/TextItem";
import useObjectState from "../../../../hooks/useObjectState";
import useValidation from "../../../../hooks/useValidation";

const ArchiveHeader = (props) => {
    const [searchValue, setSearchValue] = useState("");
    const isError = useObjectState(false);

    const {isNumberValidation} = useValidation();

    const handleSearchValue = (e) => {
        if (!isNumberValidation(e.target.value)) {
            isError.setState(false);
            setSearchValue(e.target.value);
        } else isError.setState(true);
    };

    return (
        <Card
            variant={"outlined"}
            sx={{
                display: "flex",
                width: "100%",
            }}
        >
            <Box
                justifyContent={"space-between"}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    padding: 1,
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <TextItem size={"h6"} text={"Repair History"}/>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {!isError.state ? (
                            <TextField
                                value={searchValue}
                                onChange={handleSearchValue}
                                label={"Enter bit serial"}
                                size={"small"}
                            />
                        ) : (
                            <TextField
                                error
                                helperText={"Incorrect serial format"}
                                value={searchValue}
                                onChange={handleSearchValue}
                                label={"Enter bit serial"}
                                size={"small"}
                            />
                        )}

                        <Button
                            variant={"contained"}
                            sx={{marginX: "5px"}}
                            onClick={() => {
                                props.loadHistory(searchValue);
                            }}
                        >
                            Load
                        </Button>
                    </Box>

                    {/*TODO:Добавить обработчик*/}
                    <Typography>Export History</Typography>
                    <IconButton>
                        <SaveAltIcon/>
                    </IconButton>
                </Box>
            </Box>
        </Card>
    );
};

export default ArchiveHeader;
