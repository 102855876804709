import React, {useEffect} from 'react';
import {Box, Card} from "@mui/material";
import BladeTemplateHeader from "./BladeTemplateHeader";
import BladeTemplateCreator from "./BladeTemplateCreator";
import BladeTemplateCreation from "./BladeTemplateCreation";
import UseRECOILState from "../../../../../../hooks/useRECOILState";
import {cutterBladeNumber} from "../../../../../../atoms/cutterMapTextFieldsAtoms";
import {bladeStore} from "../../../../../../atoms/cutterMapState";
import * as PropTypes from "prop-types";
import BladeTemplateCutterMap from "./BladeTemplateCutterMap";
import BladeTemplateFooter from "./BladeTemplateFooter";
import useObjectState from "../../../../../../hooks/useObjectState";


const BladeTemplateLayout = (props) => {
    const bladesStore = UseRECOILState(bladeStore);
    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    padding: 1,
                    alignItems: 'center',
                    // overflow: 'auto',
                }}
            >
                <BladeTemplateHeader/>
                <BladeTemplateCreator/>
                <BladeTemplateCutterMap bladesStore={bladesStore} prop1={(blade, i) => {
                    return (
                        <BladeTemplateCreation index={i} blade={blade} key={i}/>
                    )
                }}/>
                <BladeTemplateFooter bladesStore={bladesStore}/>
            </Box>
        </React.Fragment>
    );
}

export default BladeTemplateLayout;