import {
    atom,
} from 'recoil'

export const allNotes = atom({
    key: 'allNotes',
    default: [],
});

export const selectedNotes = atom({
    key: 'selectedNotes',
    default: {Title: '', Text: ''},
})

