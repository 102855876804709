import React from "react";

/**
 * Кастомный хук, который возвращает объект с двумя свойствами: state и setState.
 * @param initState - Исходное состояние объекта.
 * @returns Объект с двумя свойствами: state и setState.
 */
const useObjectState = (initState) => {
    const [state, setState] = React.useState(initState);

    return {
        state,
        setState
    }
}

export default useObjectState;