import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RepairTextFieldWithIcon from "./RepairTextFieldWithIcon";
import useObjectState from "../../../../../hooks/useObjectState";
import RepairDatetimePicker from "./RepairDatetimePicker";
import WarningIcon from "@mui/icons-material/Warning";
import { CheckBox } from "@mui/icons-material";
import GppBadIcon from "@mui/icons-material/GppBad";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  DbData,
  Scrap,
  Drill,
} from "../../../../../atoms/repairEntryFetchData";
import { bitEvalDataGridStore } from "../../../../../atoms/staticAtoms";
import Theme from "../../../../../theme/theme";
import theme from "../../../../../theme/theme";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const columnsNames = ["Serial", "MaterialCode", "Size", "Type", "Scrap"];

const RepairDialogInformation = (props) => {
  const scrapCheckbox = useObjectState(false);
  const retroFitStore = useObjectState(false);
  const retroFitTextField = useObjectState(null);

  const { store, dataGridStore, dbData } = props;
  const navigate = useNavigate();
  const [scrapReasons, setscrapReasons] = useState([]);
  const [selectedScrap, setSelectedScrap] = useState("None");

  const [bitEvalRows, setBitEvalRows] = useRecoilState(bitEvalDataGridStore); // this is bit evalution rows

  useEffect(() => {
    let dbData = new DbData(Scrap.model, [
      Scrap.fields.Name,
      Scrap.fields.Code,
    ]);

    dbData.sendRequest().then(() => {
      setscrapReasons(Scrap.getNames(dbData.result));
    });

    console.log(dataGridStore.state.row);
  }, []);

  const handleSelectChange = (e) => {
    setSelectedScrap(e.target.value);
  };

  const submitButtonClickHandler = async (props) => {
    if (scrapCheckbox.state)
      Drill.setScrap(dataGridStore.state.row["Serial"], selectedScrap);
    console.log(dbData);
    if (dataGridStore.state.row["CutterMap"] === "NO DATA") {
      toast.error("Отсутствует карта резцов для этого долота");
    } else {
      let row = {
        id: 0,
        Serial: dataGridStore.state.row["Serial"],
        Material: dataGridStore.state.row["Material"],
        Size: dataGridStore.state.row["Size"],
        Type: dataGridStore.state.row["Type"],
        Origin: dataGridStore.state.row["Location"],
        Map: dataGridStore.state.row["CutterMap"],
        // Origin: dataGridStore.state.row["Origin"] ? dataGridStore.state.row["Origin"] : 'Origin Name' // TODO: Add location to drill
      };

      setBitEvalRows([row]);
      console.log(bitEvalRows);
      toast.success("Repair added successfully");
      navigate("/bit-evaluation");
    }
  };

  const cancelButtonClickHandler = (props) => {
    store.setState(false);
  };

  const handleClose = () => {
    store.setState(false);
  };

  return (
    <Dialog open={store.state} onClose={handleClose} fullWidth>
      <DialogContent>
        <Stack display={"flex"} direction={"column"}>
          <Stack
            display={"flex"}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Stack
              display={"flex"}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={1}
              mt={0.3}
              mb={0.3}
            >
              <Typography variant={"h5"}>Bit Data</Typography>
            </Stack>
            <Stack
              display={"flex"}
              direction={"row"}
              flex={1}
              alignItems={"center"}
              justifyContent={"end"}
            >
              <IconButton onClick={cancelButtonClickHandler}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>

          <Divider />
          <Stack
            display={"grid"}
            gridTemplateColumns={"3fr 1fr"}
            padding={1}
            gap={1}
            // sx={{backgroundColor: 'aqua'}}
          >
            <Card variant={"outlined"}>
              <Stack
                display={"grid"}
                padding={1}
                gridTemplateColumns={"repeat(2,1fr)"}
                gap={1}
                // alignItems={"center"}
                sx={
                  {
                    // backgroundColor: 'aqua',
                  }
                }
              >
                {dataGridStore.state &&
                  columnsNames.map((name, index) => {
                    /* if (name === 'id') {
                                             return <Typography
                                                 key={index}>ID: {dataGridStore.state.row[name] ? dataGridStore.state.row[name] : 'NONE'}</Typography>
                                         } 
                                         else {*/
                    return (
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          border: 1,
                          padding: 1,
                          borderColor: theme.palette.primary.main,
                          borderRadius: 4,
                        }}
                        key={index}
                      >
                        {name.toUpperCase()}{" "}
                        {dataGridStore.state.row[name]
                          ? dataGridStore.state.row[name]
                          : "NONE"}
                      </Typography>
                    );

                    // }
                  })}
              </Stack>
            </Card>

            <Stack display={"flex"} direction={"column"}>
              <Paper
                sx={{
                  height: "100%",
                  // display: 'flex',
                }}
              >
                <Stack
                  display={"grid"}
                  gridTemplateColumns={"1fr"}
                  gridTemplateRows={"1fr 1fr"}
                  gap={1}
                  justifyContent={"center"}
                  height={"100%"}
                >
                  <Typography
                    variant={"h5"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    FS3000R
                  </Typography>
                  <Typography
                    color={"white"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      backgroundColor: theme.palette.primary.main,
                    }}
                  >
                    Drill Bits and Service
                  </Typography>
                </Stack>
              </Paper>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            display={"flex"}
            justifyContent={"space-between"}
          ></Stack>
          <Divider />
          <Stack
            direction={"row"}
            display={"flex"}
            alignItems={"center"}
            mt={1}
            justifyContent={"space-between"}
          >
            <Button
              size={"medium"}
              color={"error"}
              variant={"outlined"}
              onClick={cancelButtonClickHandler}
            >
              Cancel
            </Button>
            <Button
              size={"medium"}
              color={"success"}
              variant={"outlined"}
              onClick={submitButtonClickHandler}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default RepairDialogInformation;

//----------------------------------------------------------------
// Заполнение из таблицы.
{
  /*{dataGridStore.state && 
                            columnsNames.map((name, index) => {
                            if (name === 'id') {
                                return <Typography
                                    key={index}>ID: {dataGridStore.state.row[name] ? dataGridStore.state.row[name] : 'NONE'}</Typography>
                            }
                            return <Typography
                                key={index}>{name} {dataGridStore.state.row[name] ? dataGridStore.state.row[name] : 'NONE'}</Typography>;
                        })}*/
}
