import React, { useState, useEffect } from "react";
import { Layout } from "../Layout";
import { useSearchParams } from "react-router-dom";
import Auth from "../../components/Auth";

const PrivateRoute = ({ children }) => {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const tempCode = searchParams.get("code");
  return sessionStorage.getItem("tokenKey") ? (
    <Layout>{children}</Layout>
  ) : (
    <Auth />
  );
};

export default PrivateRoute;
