import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  Slide,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  CardMedia,
  CardActionArea,
  styled,
  Autocomplete,
  Collapse,
} from "@mui/material";
import axios from "axios";
import {
  Delete,
  Add,
  Edit,
  Save,
  Cancel,
  Close,
  AddCircle,
} from "@mui/icons-material";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContainerCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  maxHeight: "100%",
  // justifyContent: 'space-between',
  // alignItems: 'center',
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    boxShadow: theme.shadows[5],
    transform: "scale(1.01)",
    transition: "all 0.3s ease-in-out",
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  maxHeight: "50%",
  gap: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.background.paper,
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    outline: "1px solid slategrey",
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  border: "1px solid #e0e0e0",
  borderRadius: "4px",
  overflow: "auto",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transition: "all 0.3s ease-in-out",
    "& .MuiListItemText-secondary": {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const AddDrillComponentNEW = () => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [importLoading, setImportLoading] = React.useState(false);
  const [drill, setDrill] = React.useState({
    serial: "",
    name: "",
    type: "",
    size: "",
    material: "",
    scrap: "",
    location: "",
    cutter_map: false,
  });
  const [drillSearchSerial, setDrillSearchSerial] = React.useState("");
  const [drills, setDrills] = React.useState([]);
  const [drillMaterials, setDrillMaterials] = React.useState([]);
  const [drillLocations, setDrillLocations] = React.useState([]);
  const [drillScrap, setDrillScrap] = React.useState([]);

  const getDrills = async () => {
    try {
      const response = await axios.get("/api/DrillsControllers/getDrills");
      setDrills(response.data);
    } catch (e) {
      console.log(e);
      toast.error("Error getting drills");
    }
  };

  const getDrillMaterials = async () => {
    try {
      const response = await axios.get("/api/material/getAllMaterials");
      setDrillMaterials(response.data);
    } catch (e) {
      console.log(e);
      toast.error("Error getting drill materials");
    }
  };

  const getDrillLocations = async () => {
    try {
      const response = await axios.get("/api/location/getAllLocations");
      setDrillLocations(response.data);
    } catch (e) {
      console.log(e);
      toast.error("Error getting drill locations");
    }
  };

  const getDrillScrap = async () => {
    try {
      const response = await axios.get("/api/Scrap/getScraps");
      setDrillScrap(response.data);
    } catch (e) {
      console.log(e);
      toast.error("Error getting drill scrap");
    }
  };

  const handleOpenCloseClick = () => {
    setOpen(!open);
  };

  const handleInputChange = (event) => {
    if (event.target.name === "serial") {
      if (isNaN(event.target.value)) {
        toast.error("Serial must be a number");
        return;
      }
    }
    setDrill({ ...drill, [event.target.name]: event.target.value });
  };

  const handleCheckboxChange = (event) => {
    setDrill({ ...drill, [event.target.name]: event.target.checked });
  };

  const isLoaded = () => {
    if (loading) {
      return <LinearProgress sx={{ width: "100%" }} />;
    }
  };

  useEffect(() => {
    getDrills().then(() => console.log(drills));
  }, []);

  return <>ABOBA</>;
};

export default AddDrillComponentNEW;
