import React from "react";
import {IconButton, Stack} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export const SecondaryRowButtons = (props) => {
    const {addSecondaryCutter, removeSecondaryCutter, index} = props;
    return (
        <Stack direction={"row"} gap={1} id={"secondary-control"}>
            <IconButton>
                <AddIcon onClick={() => addSecondaryCutter(index)}/>
            </IconButton>
            <IconButton>
                <RemoveIcon onClick={() => removeSecondaryCutter(index)}/>
            </IconButton>
        </Stack>
    );
};