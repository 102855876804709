import React, { memo, Suspense, useCallback, useMemo } from 'react';
import { Box, Card, Dialog } from "@mui/material";
import useObjectState from "../../../../../hooks/useObjectState";
import DetailsTabs from "../../../Repairs/components/repairs-detail/components/DetailsTabs";
import EvaluationContent from "../../../Repairs/components/repairs-detail/components/EvaluationContent";
import DocumentsListRepairs from "../../../Repairs/components/repairs-detail/components/DocumentListRepairs";
import EvalutionNotesRepairs from "../../../Repairs/components/repairs-detail/components/NotesListRepairs";

const ArchiveModal = memo((props) => {
    const { modalStore, rowData } = props;
    const tabsStore = useObjectState('Evaluation');

    const handleClose = useCallback(() => {
        modalStore.setState(false);
    }, [modalStore]);

    /*const renderTabContent = useMemo(() => {
        if (tabsStore.state === 'Evaluation') {
            return <EvaluationContent rowData={rowData} arhive={true} />;
        } else if (tabsStore.state === 'Documents') {
            return <DocumentsListRepairs drillSerial={rowData.state.row.serialNumber} />;
        }
    }, [tabsStore.state, rowData]);*/

    return (
        <Dialog open={modalStore.state} onClose={handleClose} sx={{ maxHeight: '100%', overflow: 'auto' }} fullScreen>
            <Box sx={{}}>
                <Card variant={"outlined"} sx={{}}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 1 }}>
                        <DetailsTabs tabsStore={tabsStore} modalStore={modalStore} />
                        <Card variant={"outlined"} sx={{ padding: 1 }}>
                            {tabsStore.state === 'Evaluation'&& <EvaluationContent rowData={rowData} arhive={true}/>}
                            {tabsStore.state === 'Documents' && <DocumentsListRepairs drillSerial={rowData.state.row.serialNumber} />}
                            {tabsStore.state === 'Notes' && <EvalutionNotesRepairs drillSerial={rowData.state.row.serialNumber}/>}
                        </Card>
                    </Box>
                </Card>
            </Box>
        </Dialog>
    );
});

export default ArchiveModal;
