import React, { useState } from "react";
import {
  Box,
  Button,
  TextareaAutosize,
  Input,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material/";
import { FormControlLabel, Grid, TextField } from "@mui/material";
import Divider from "@mui/material/Divider";
import UserAdd from "./components/AddUserComponent";
import AddDrill from "./components/AddDrillComponent";
import AddCutters from "./components/AddCuttersComponent";
import AddMaterial from "./components/AddMaterialComponent";
import DbTabs from "./components/Tabs/DbTabs";
import { useRecoilState } from "recoil";
import { selectedTab } from "../../../atoms/DbAtoms/atoms";
import LocationAdd from "./components/AddLocationComponent";
import AddDrillComponentNEW from "./components/AddDrillComponentNEW";
import ImportExcel from "./components/Import";
import AddScrapComponent from "./components/AddScrapComponent";

const TestDB = (props) => {
  // TODO: создать атом для cutters'ов, чтоб при добавлении резца он добавлялся в обе таблицы

  const [tab, setTab] = useRecoilState(selectedTab);
  return (
    <React.Fragment>
      <Box sx={{ height: "100%", width: "100%" }}>
        <Box>
          <DbTabs />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",

            maxWidth: "100%",
          }}
        >
          {tab === "Users" && <UserAdd />}
          {tab === "Drills" && <AddDrill />}
          {tab === "Cutters" && <AddCutters />}
          {tab === "Materials" && <AddMaterial />}
          {tab === "Locations" && <LocationAdd />}
          {tab === "Import" && <ImportExcel />}
          {tab === "Scrap" && <AddScrapComponent />}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default TestDB;
