import React from 'react';
import Box from "@mui/material/Box";
import {Card, Typography} from "@mui/material";
import {useRecoilValue} from "recoil";
import {childrenTableCutterSelector} from "../../../../../../atoms/Ordering_Atoms/OrderingAtoms";

const ConfigureCutterInfoArea = (props) => {
    const getSelectedTableCutter = useRecoilValue(childrenTableCutterSelector);
    return (

        <Card variant={"outlined"}
              sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                  padding: 1,
              }}
        >
            <Typography>Evaluation Cutter Results</Typography>
            <Typography>Currently
                Selected: {getSelectedTableCutter.name} {getSelectedTableCutter.description}</Typography>
        </Card>
    );
}

export default ConfigureCutterInfoArea;