import React from "react";
import Box from "@mui/material/Box";
import useObjectState from "../../../../../hooks/useObjectState";
import {
    Card,
    IconButton,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import useRECOILState from "../../../../../hooks/useRECOILState";
import UseValidation from "../../../../../hooks/useValidation";

const RepairsHeader = (props) => {
    const {tabValueStore, textFieldStore} = props;
    const isError = useObjectState(false);
    const {isNumberValidation} = UseValidation();
    // const tabValueStore = useRECOILState(tabValueStore)
    // const tabValueStore = useObjectState('Repair')
    const handleTabChange = (event, newValue) => {
        tabValueStore.setState(newValue);
    };
    const handleTextFieldChange = (e) => {
        if (!isNumberValidation(e.target.value)) {
            isError.setState(false);
            textFieldStore.setState(e.target.value);
        } else {
            isError.setState(true);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                // padding: 1,
            }}
        >
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                }}
            >
                <Tabs value={tabValueStore.state} onChange={handleTabChange}>
                    <Tab value={"Repair"} label={"Repair"}/>
                    <Tab value={"Complete"} label={"Complete"}/>
                </Tabs>
            </Box>

            <Card
                variant={"outlined"}
                sx={{
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: `repeat(2,1fr)`,
                        gridTemplateRows: "1fr",
                        gap: 1,
                        padding: 1,
                        alignItems: "center",
                    }}
                >
                    <Typography variant={"h6"}>Current Repairs</Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "end",
                        }}
                    >
                        <TextField
                            label={"Search"}
                            variant={"outlined"}
                            size={"small"}
                            onChange={handleTextFieldChange}
                            error={isError.state}
                            value={textFieldStore.state}
                            helperText={isError.state ? "Only numbers" : ""}
                        />
                        <IconButton>
                            <SearchIcon/>
                        </IconButton>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                            }}
                        >
                            <Typography>Refresh</Typography>
                            <IconButton>
                                <RefreshIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};

export default RepairsHeader;