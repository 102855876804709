import React from "react";
import {Box, Stack, TextField} from "@mui/material";
import UseObjectState from "../../../../../../hooks/useObjectState";
import UseValidation from "../../../../../../hooks/useValidation";

/**
 * Поле ввода с названием
 * @param props (store - переменная для записи состояния текста, label - название поля ввода, block - переменная блокировки поля)
 * @returns {JSX.Element}
 * @constructor
 */
const BladeTemplateTextFieldWithLabel = (props) => {
    const {isNumberValidation} = UseValidation();
    const isErrorText = UseObjectState(false);
    const handleChange = (e) => {
        if (!isNumberValidation(e.target.value)) {
            isErrorText.setState(false);
            props.store.setState(e.target.value);
        } else {
            isErrorText.setState(true);
        }
        /*const regExp = new RegExp(/^\d+$/);
        if (regExp.test(e.target.value) || e.target.value === "") {
            isErrorText.setState(false);
            props.store.setState(e.target.value);
        } else {
            isErrorText.setState(true);
        }*/
    };

    return (
        <React.Fragment>
            <Stack
                display={"flex"}
                direction={"row"}
                gap={1}
                justifyContent={"space-between"}
            >
                {!isErrorText.state ? (
                    <TextField
                        // inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                        // InputLabelProps={{shrink: true}}
                        // type={"number"}
                        size={"small"}
                        value={props.store.state}
                        variant={"outlined"}
                        onChange={handleChange}
                        disabled={props.disable}
                        label={props.label}
                    />
                ) : (
                    <TextField
                        error
                        helperText={"Incorrect number format"}
                        // inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                        // InputLabelProps={{shrink: true}}
                        // type={"number"}
                        size={"small"}
                        value={props.store.state}
                        variant={"outlined"}
                        onChange={handleChange}
                        disabled={props.disable}
                        label={props.label}
                    />
                )}
            </Stack>
        </React.Fragment>
    );
};

export default BladeTemplateTextFieldWithLabel;
