import React from 'react';
import {Avatar, Card} from "@mui/material";

const BladeTemplateCuttersNumbers = (props) => {
    const {primary, secondary, max} = props.blade;
    const maxArray = Array.from({length: max},(_, i) => i+1);
    return (
            <Card variant={"outlined"}
                  sx={{
                      display: 'grid',
                      gridTemplateColumns: `repeat(${max},1fr)`,
                      gap: 1,
                      padding: 1,
                      width: '100%',
                      overflow: 'auto',

                  }}>
                {maxArray.map((number)=>{
                    return (
                        <React.Fragment key={number}>
                            <Avatar variant={"square"}>{number}</Avatar>
                        </React.Fragment>
                    );
                })}
            </Card>
    );
}

export default BladeTemplateCuttersNumbers;