import useRECOILState from "./useRECOILState";
import {orderBladeCutterMap} from "../atoms/Ordering_Atoms/OrderingAtoms";


/* Специальный хук, который используется для смены материала лезвия. */
const useOrderingChangeBladeMaterial = (props) => {
    /* Переменная, которая используется для хранения состояния атома `orderBladeCutterMap`. */
    const tempBladeStore = useRECOILState(orderBladeCutterMap);


    /**
     * Он принимает объект резака, индекс лезвия, индекс резака и логическое значение. Затем он помещает объект резака в
     * соответствующий массив в массиве tempBladeStore.state.
     * @param cutter - Материальный номер резца
     * @param bladeIndex - индекс лезвия в массиве лезвий
     * @param cutterIndex - Индекс резака в массиве резаков.
     * @param isPrimary - логическое для проверки на верхнюю и нижнюю линию резцов.
     */
    const pushNewCutter = (cutter, bladeIndex, cutterIndex, isPrimary) => {
        if (isPrimary) {
            tempBladeStore.state[bladeIndex].orderCutters.primaryOrderCutters[cutterIndex] = cutter;
        } else {
            tempBladeStore.state[bladeIndex].orderCutters.secondaryOrderCutters[cutterIndex] = cutter;
        }
    }

    /**
     * Он берет объект данных, а затем создает новый объект с теми же данными, но с другой структурой.
     * @param data - данные, которые передаются из родительского компонента
     */
    const createOrderingObject = (data) => {
        let temp = null;
        temp = data;
        tempBladeStore.setState(temp);
    }

    /**
     * Возвращает значение переменной tempBladeStore
     * @returns Функция getBladeStore возвращается.
     */
    const getBladeStore = () => {
        return tempBladeStore;
    }

    return {
        pushNewCutter,
        getBladeStore,
        createOrderingObject,
    }
}

export default useOrderingChangeBladeMaterial;