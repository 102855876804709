import React, { useEffect } from "react";
import { Box, Card, Paper, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  cutterDataGridStore,
  cutterMapRows,
  cutterSerialNumberTextFieldStore,
} from "../../../../../../atoms/cutterMapTextFieldsAtoms";
import axios from "axios";
import { adminDrillMaterialNumber } from "../../../../../../atoms/Administration_Atoms/atoms";
import useObjectState from "../../../../../../hooks/useObjectState";

const columnsNames = [
  { field: "index", headerName: "Index", hide: true },

  {
    field: "id",
    flex: 0.1,
    headerName: "ID",
    hide: true,
  },
  {
    field: "name",
    flex: 1,
    headerName: "Наименование",
  },

  {
    field: "value",
    flex: 0.3,
    headerName: "Кол-во",
    type: "number",
  },
  {
    field: "Used",
    type: "number",

    flex: 0.1,
    headerName: "Used",
    defaultValue: 0,
  },
];

const testRows = [
  {
    id: 1,
    Material: "123451",
    Description: "This is a description",
    Unit: "SomeUnit",
    Qty: 25,
    Used: 0,
  },
  {
    id: 2,
    Material: "123451",
    Description: "This is a description",
    Unit: "SomeUnit",
    Qty: 25,
  },
];

const CutterMapDataGrid = (props) => {
  const [dataGridStore, setDataGridStore] = useRecoilState(cutterDataGridStore);
  const drillSerial = useRecoilValue(adminDrillMaterialNumber);
  const drillDataStore = useObjectState(null);
  const rows = useRecoilValue(cutterMapRows);

  const handleDataGridCellClick = (e) => {
    setDataGridStore(e.row);
    console.log(e);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          padding: 1,
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Paper
          variant={"outlined"}
          sx={{
            display: "flex",
            gap: 1,
            padding: 1,
            minHeight: "400px",

            height: "inherit",
            flexDirection: "column",
          }}
        >
          <DataGrid
            hideFooter={true}
            columns={columnsNames}
            rows={rows}
            onCellClick={handleDataGridCellClick}
            sx={{
              display: "flex",
              height: "90%",
            }}
          />
        </Paper>
      </Box>
    </React.Fragment>
  );
};

/*
<Box
    id={'CutterMapDataGridBox'}
    sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: 1,
        height: '100%',
        width: '100%',
    }}
>
</Box>
*/

export default CutterMapDataGrid;
