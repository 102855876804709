import {useLocation} from 'react-router-dom';
import {createContext, useContext} from 'react';

const LocationContext = createContext();

export const useGlobalLocation = () => {
    return useContext(LocationContext);
}

export const LocationProvider = ({children}) => {
    const location = useLocation();

    return (
        <LocationContext.Provider value={location}>
            {children}
        </LocationContext.Provider>
    );
}

