import React, { useEffect, useState } from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import CutterMapMaterialTextfield from "./CutterMapMaterialTextfield";
import CutterMapManufSelect from "./CutterMapManufSelect";
import {
  Cutters,
  DbData,
  Drill,
  urlSetParam,
} from "../../../../../atoms/repairEntryFetchData";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  cutterMapRows,
  cutterMaterialTextFieldStore,
  cutterSerialNumberTextFieldStore,
} from "../../../../../atoms/cutterMapTextFieldsAtoms";
import { toast } from "react-toastify";

import { adminDrillMaterialNumber } from "../../../../../atoms/Administration_Atoms/atoms";
import axios from "axios";
import UseRECOILState from "../../../../../hooks/useRECOILState";
import { bladeStore } from "../../../../../atoms/cutterMapState";

const CutterMapSelect = (props) => {
  const [drill, setDrill] = useRecoilState(cutterSerialNumberTextFieldStore);
  const [rows, setRows] = useRecoilState(cutterMapRows);
  const materialNumber = useSetRecoilState(adminDrillMaterialNumber);
  const bladesStore = UseRECOILState(bladeStore);
  const getCuttersFromMaterial = async () => {
    if (drill.length > 0) {
      await axios
        .get(`api/material/getCutterMap`, { params: { materialCode: drill } })
        .then((res) => {
          if (Object.keys(res.data).length === 0) {
            toast.warning("No cutter map found for this material");
          } else {
            bladesStore.setState(res.data);
            toast.success("Cutter map loaded");
          }
        })
        .catch((err) => {
          toast.error(`${err.message}: ${err.response.data.message}`);
          return false;
        });

      await axios
        .get(`api/material/getCuttersFromMaterial`, {
          params: { material: drill },
        })
        .then((res) => {
          const filteredRows = res.data["result"].filter((row) => {
            if (row["isReclaimed"] === false || !row["Name"].endsWith("RCL")) {
              return row;
            }
          });
          setRows(filteredRows);
          toast.success("Material loaded to table");
        })
        .catch((err) => {
          toast.error(`${err.message}: ${err.response.data.message}`);
          return false;
        });
    } else toast.error("Please enter material number");

    /*let dbData = new DbData(Drill.model, [Drill.fields.Serial, Drill.fields.Material, Drill.fields.Size], Drill.fields.Serial, drill);
        await dbData.sendRequest();
        let material = dbData.result[0][Drill.fields.Material];
        materialNumber(dbData.result[0])
        fetch(urlSetParam("api/material/getCuttersFromMaterial?material=%0", [material])).then(
            (response) => {
                if (response.ok) {
                    toast.success("Material loaded");
                    return response.json();
                }
                toast.error("Material not loaded");
                return false;
            }
        ).then(data => {
            if (data !== false && data != null) {
                setRows(data['result']);
            }
        })*/
  };

  return (
    <React.Fragment>
      <Box
        id={"BoxSelect"}
        sx={{
          display: "flex",
          gap: 1,
          padding: 1,
          flexDirection: "column",
        }}
      >
        <Card
          variant={"outlined"}
          sx={{
            display: "flex",
            gap: 1,
            padding: 1,
            flexDirection: "column",
          }}
        >
          {/* <Typography align={"justify"}>
            To CREATE or EDIT a Bit Cutter Map, just enter the bit Material
            Number below and click the Select button. This will load the current
            BOM cutter list and generate a Blade Template. If a cutter map
            already exists, the cutter template will be populated with the
            current cutter assignments (by row number) as shown in the BOM
            cutter listing at right.
          </Typography> */}
          <CutterMapMaterialTextfield />
        </Card>
      </Box>
    </React.Fragment>
  );
};

export default CutterMapSelect;
