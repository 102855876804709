import React from 'react';
import Box from "@mui/material/Box";
import {Card, IconButton, Stack, TextField, Typography} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import OrderingTextFieldWithButtons from "./OrderingTextFieldWithButtons";


/*function OrderingTextFieldWithButtons() {
    return <Box
        sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
        }}
    >
        {/!*TODO:Добавить обработчики*!/}
        <Stack
            direction={"row"}
            alignItems={"center"}
        >
            <TextField size={"small"} variant={"outlined"} label={"Enter Serial"}/>
            <IconButton><SearchIcon/></IconButton>
        </Stack>

        <IconButton><RefreshIcon/></IconButton>
    </Box>;
}*/

/* Компонент, отображающий заголовок страницы заказа. */
const OrderingHeaderLayout = (props) => {
    return (
        <Card variant={"outlined"}
              sx={{
                  // padding: 1,
                  // display: "flex",
              }}
        >
            <Box
                sx={{
                    display: 'flex',
                    padding: 1,
                    flexDirection: 'row',
                    gap: 1,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant={'h6'}>Pending Orders</Typography>
                <OrderingTextFieldWithButtons/>
            </Box>
        </Card>

    );

}

export default OrderingHeaderLayout