import {atom, selector} from "recoil";

const orderBladeCutterMap = atom({
    key: "orderBladeCutterMap",
    default: null,
    dangerouslyAllowMutability: true,
})

const getOrderBladeCutterMap = selector({
    key: "getOrderBladeCutterMap",
    get: ({get}) => {
        return get(orderBladeCutterMap);
    }
})


const parentTableCutter = atom({
    key: "parentTableCutter",
    default: null,
})

const childrenTableCutter = atom({
    key: "childrenTableCutter",
    default: null,
})

const childrenTableCutterSelector = selector({
    key: "childrenTableCutterSelector",
    get: ({get}) => {
        const value = get(childrenTableCutter);
        if (value) return value;
        else return null;
    }
})

const parentTableCutterSelector = selector({
    key: "parentTableCutterSelector",
    get: ({get}) => {
        const value = get(parentTableCutter)
        if (value) {
            return value
        } else return null;
    }
})


const orderingRows = atom({
    key: "orderingRows",
    default: [],
})

export {
    orderingRows,
    parentTableCutter,
    parentTableCutterSelector,
    childrenTableCutter,
    childrenTableCutterSelector,
    orderBladeCutterMap,
    getOrderBladeCutterMap,
}