const useValidation = () => {
  const isNumberValidation = (text) => {
    const regExp = /^\d+$/;
    return !regExp.test(text) && text !== "";
  };
  const isEmptyTextValidation = (text) => {
    return text.trim().length === 0 && text.length > 0;
  };

  return {
    isNumberValidation,
    isEmptyTextValidation,
  };
};

export default useValidation;
