import {atom} from "recoil";


const bladeStore = atom({
    key: 'ds',
    default: [],
    dangerouslyAllowMutability: true,
})

const cutterMapStore = atom({
    key: 'cutterMapStore',
    default: []
})

const cutterMapBladeCuttersStore = atom({
    key: 'cutterMapBladeCuttersStore',
    default: []
})


export {
    bladeStore,
    cutterMapStore,
    cutterMapBladeCuttersStore,
}