import React, { useEffect, useState } from "react";
import { Box, Card, Skeleton } from "@mui/material";
import BladeCard from "./BladeCard";
import UseObjectState from "../../../../../../hooks/useObjectState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  getOrderBladeCutterMap,
  orderBladeCutterMap,
} from "../../../../../../atoms/Ordering_Atoms/OrderingAtoms";
import useOrderingChangeBladeMaterial from "../../../../../../hooks/useOrderingChangeBladeMaterial";
import { DbData, Order } from "../../../../../../atoms/repairEntryFetchData";
import axios from "axios";

const ConfigureCutterMap = (props) => {
  const { childrenState, parentState } = props;
  const setBladesData = useOrderingChangeBladeMaterial();
  const [testDrill, setTestDrill] = useState(null);

  const orderId = props.orderId;
  const loadCutterMap = async () => {
    const response = await axios.get(
      `/api/orders/upgradeOrderCutterMap?order=${orderId}`
    );
    setTestDrill(JSON.parse(response.data.cutterMap));
  };
  useEffect(() => {
    loadCutterMap();
  }, []);

  useEffect(() => {
    setBladesData.createOrderingObject(testDrill);
  }, [testDrill]);
  // const bladesData = useRecoilValue(getOrderBladeCutterMap);
  if (testDrill === null) {
    return (
      <Card
        variant={"outlined"}
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <Skeleton animation={"wave"} />
      </Card>
    );
  }
  return (
    <Card
      variant={"outlined"}
      sx={{
        // display: "flex",
        // position: 'relative',
        flexDirection: "column",
        overflow: "auto",
        gap: 2,
        padding: 1,
        height: "100%",
        // justifyContent: 'center',
      }}
    >
      {testDrill.map((blade, i) => {
        return (
          <BladeCard
            childrenState={childrenState}
            parentState={parentState}
            blade={blade}
            index={i}
            key={i}
            bladeIndex={i}
          />
        );
      })}
    </Card>
  );
};

export default ConfigureCutterMap;
