import React from 'react';
import {Box, Card} from "@mui/material";

const BladeTemplateCutterMap = (props) => {
    return (
        <>
            {props.bladesStore.state.length > 0 &&
                <Card variant={"outlined"}
                      sx={{
                          display: "grid",
                          width: "100%",
                          height: "50vh"
                      }}
                >
                    <Box
                        sx={{
                            display: "grid",
                            alignItems: "center",
                            maxHeight: "100%",
                            overflow: "auto",
                            width: "100%",
                        }}
                    >
                        {props.bladesStore.state.length > 0 && props.bladesStore.state.map(props.prop1)}
                    </Box>
                </Card>}
        </>
    );
}


export default BladeTemplateCutterMap;