import {atom} from "recoil";

const adminDrillMaterialNumber = atom({
    key: "adminDrillMaterialNumber",
    default: null,
})
const userRows = atom({
  key: "userRows",
  default: [],
});
export { adminDrillMaterialNumber, userRows };