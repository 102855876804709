import React, {useEffect} from 'react';
import {Box, Tab, Tabs} from "@mui/material";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {scrapCheckboxStore} from "../../../../../atoms/bitEvaluationatoms";
import {bitEvalCutterMap, bitEvalOrder, bitEvalOrderObj, tempBitEvalCutterMap} from "../../../../../atoms/staticAtoms";
import {DbData, Order} from "../../../../../atoms/repairEntryFetchData";


const BitEvalRepairTabs = (props) => {
    const {tabStore} = props;
    const scrapCheckbox = useRecoilValue(scrapCheckboxStore);

    const [cutterMapTemp, setCutterMapTemp] = useRecoilState(tempBitEvalCutterMap);
    const [cutterMap, setCutterMap] = useRecoilState(bitEvalCutterMap);
    const [orderId, setOrderId] = useRecoilState(bitEvalOrder);

    const orderObject = useRecoilValue(bitEvalOrderObj);
    const handleChange = (event, newValue) => {
        tabStore.setState(newValue)
    }

    useEffect(() => {
        let cutterMap = orderObject.CutterMap;

        setCutterMap(cutterMap);
        setCutterMapTemp(cutterMap);
    }, [])

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                }}
            >
                <Tabs
                    value={tabStore.state}
                    textColor={"secondary"}
                    indicatorColor={"secondary"}
                    onChange={handleChange}

                >
                    <Tab value={'Evaluation'} label="Evaluation"/>
                    {!scrapCheckbox && <Tab value={'Cutters'} label="Cutters"/>}
                    {!scrapCheckbox && <Tab value={'Estimate'} label="Estimate"/>}
                    <Tab value={'Documents'} label="Documents"/>
                    <Tab value={'Notes'} label="Notes"/>
                </Tabs>
            </Box>
        </React.Fragment>
    );
}

export default BitEvalRepairTabs;