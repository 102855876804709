import React, { useEffect, useState } from "react";
import { Avatar, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useRECOILState from "../../../../hooks/useRECOILState";
import { useNavigate } from "react-router-dom";
import {
  DbData,
  Drill,
  Order,
  Scrap,
  urlSetParam,
} from "../../../../atoms/repairEntryFetchData";
import { useRecoilState, useRecoilValue } from "recoil";
import GppBadIcon from "@mui/icons-material/GppBad";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  allOrdersBitEval,
  bitEvalCutterMap,
  bitEvalDataGridStore,
  bitEvalOrder,
  bitEvalOrderConfirm,
  bitEvalOrderObj,
  bitEvalSelectedDrill,
  tempBitEvalCutterMap,
} from "../../../../atoms/staticAtoms";
import { Palette } from "@mui/icons-material";
import theme from "../../../../theme/theme";
import { toast } from "react-toastify";

const columnsNames = [
  {
    field: "id",
    headerName: "ID",
    visible: false,
  },

  {
    field: "Serial",
    headerName: "Serial Number",
    flex: 1,
  },
  {
    field: "Material",
    headerName: "Material",
    flex: 1,
  },
  {
    field: "Size",
    headerName: "Size",
    flex: 1,
  },
  {
    field: "Type",
    headerName: "Type",
    flex: 1,
  },
  {
    field: "Origin",
    headerName: "Origin",
    flex: 1,
  },
  {
    field: "scrap",
    headerName: "Scrap",
    align: "center",
    editable: false,
    renderCell: (params) => {
      console.log(params.row.Scrap);
      return params.row.Scrap === "True" ? (
        <ErrorIcon color={"error"} />
      ) : (
        <CheckCircleIcon color={"success"} />
      );
    },
  },
  {
    field: "Map",
    headerName: "Cutter Map",
    align: "center",
    editable: false,
    renderCell: (params) => {
      return params.row.Map ? (
        <CheckCircleIcon sx={{ color: theme.palette.success.main }} />
      ) : (
        <ErrorIcon sx={{ color: theme.palette.error.main }} />
      );
    },
  },
];

const BitEvalDataGrid = (props) => {
  const rows = useRECOILState(bitEvalDataGridStore);
  const [selectedDrill, setSelectedDrill] =
    useRecoilState(bitEvalSelectedDrill);
  const [orderId, setOrderId] = useRecoilState(bitEvalOrder);
  const [submit, setSubmit] = useRecoilState(bitEvalOrderConfirm);

  let navigate = useNavigate();
  // const dataGridStore = useRECOILState(bitEvalDataGridStore);

  const [allOrders, setAllOrders] = useRecoilState(allOrdersBitEval);

  const loadOrders = (serial) => {
    setAllOrders([]);

    let dbData = new DbData(
      Order.model,
      [Order.fields.Id, Order.fields.Serial],
      Order.fields.Serial,
      serial
    );
    dbData.sendRequest().then((data) => {
      let tempAllOrders = [];
      dbData.result.forEach((item) => {
        tempAllOrders.push(item);
      });
      setAllOrders(tempAllOrders);
    });
  };

  useEffect(() => {
    setOrderId(null);
    setSelectedDrill(null);
  }, []);

  const [orderObj, setOrderObj] = useRecoilState(bitEvalOrderObj);

  const getDrillInfo = async (serial) => {
    // TODO: эту херь надо переписать на api :(
    let fields = Drill.fields;
    let db = new DbData(
      Drill.model,
      [
        fields.Serial,
        fields.Material,
        fields.Size,
        fields.Type,
        fields.Location,
        fields.Scrap,
        fields.ScrapCode,
        fields.Map,
      ],
      fields.Serial,
      serial
    );

    await db.sendRequest();

    let dbScrap = new DbData(
      Scrap.model,
      [Scrap.fields.Name, Scrap.fields.Code],
      Scrap.fields.Code,
      db.result[0].ScrapCode
    );
    await dbScrap.sendRequest();

    let result = {
      Serial: db.result[0][fields.Serial],
      Material: db.result[0][fields.Material],
      Size: db.result[0][fields.Size],
      Type: db.result[0][fields.Type],
      Location: db.result[0][fields.Location],
      Scrap: db.result[0][fields.Scrap] == "True",
      ScrapCode: dbScrap.result[0][Scrap.fields.Code],
      ScrapName: dbScrap.result[0][Scrap.fields.Name],
      Map: db.result[0][fields.Map] === "True",
    };
    setSelectedDrill(result);
    return result;
  };
  const orderGenerator = async (serial) => {
    let urlGenerateOrderId = "/api/orders/generateOrderId";
    let urlUpgradeCutterMap =
      "/api/DrillsControllers/upgradeCutterMap?serial=%0";

    let orderId = await fetch(urlGenerateOrderId)
      .then((response) => response.json())
      .then((data) => {
        return data.id;
      });

    let urlUpgradeCutterMapFinal = urlSetParam(urlUpgradeCutterMap, [serial]);
    console.log(urlUpgradeCutterMapFinal);

    let cutterMap = await fetch(urlUpgradeCutterMapFinal)
      .then((response) => response.json())
      .then((data) => JSON.parse(data.result));

    let drillInfo = await getDrillInfo(serial);

    orderObj.Scrap = drillInfo.Scrap;
    orderObj.ScrapReason = drillInfo.ScrapCode;
    orderObj.OrderId = orderId;
    orderObj.DrillSerial = serial;
    orderObj.CutterMap = cutterMap;

    console.log("[ ---- ORDER OBJ ---- ]");
    console.log(orderObj);
  };

  const createOrder = async (serial) => {
    return await orderGenerator(serial);
  };

  const handleDataGridCellClick = async (e) => {
    //check for cutter map not empty in one line
    if (e.row.CutterMap === "NO DATA") {
      toast.error("Нет Cutter Map для этой долота");
      return;
    }
    let drillSerial = e.row.Serial;

    let orderObj = await createOrder(drillSerial);
    await loadOrders(drillSerial);

    navigate(`/start/${drillSerial}`);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          // height: '50vh',
        }}
      >
        <DataGrid
          columns={columnsNames}
          rows={rows.state}
          autoHeight
          onCellClick={(e) => {
            handleDataGridCellClick(e);
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
        />
      </Box>
    </React.Fragment>
  );
};

export default BitEvalDataGrid;
