import {
  AddCircleOutlineSharp,
  ClearOutlined,
  DeleteForeverOutlined,
  DeleteOutlineSharp,
  RemoveCircleOutlineSharp,
} from "@mui/icons-material";
import { Box, IconButton, Input, Typography } from "@mui/material";
import React, { useState } from "react";

function CutterCounter({
  title,
  id,
  value,
  onDelete,
  counterId,
  onCounterChange,
}) {
  const [count, setCount] = useState(value);

  const handleIncrement = () => {
    setCount(count + 1);
    onCounterChange(counterId, count + 1);
  };

  const handleDecrement = () => {
    if (count > 1) {
      setCount(count - 1);
      onCounterChange(counterId, count - 1);
    }
  };
  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 1) {
      setCount(value);
      onCounterChange(counterId, value);
    }
  };
  return (
    <Box
      sx={{
        marginTop: "10px",
        padding: "10px",
        backgroundColor: "#e0e0e0",
        borderRadius: "10px 10px 10px 10px",
        display: "flex",
      }}
    >
      <IconButton onClick={() => onDelete(counterId)}>
        <ClearOutlined />
      </IconButton>
      <Box
        sx={{
          border: "1p solid #e0e0e0",
          borderRadius: "3px",
          padding: "5px",
          backgroundColor: "white",
          width: "70%",
          display: "flex",
          marginRight: "10%",
          overflowX: "auto",
        }}
      >
        <Typography variant={"body1"}>{title}</Typography>
      </Box>
      <IconButton onClick={handleDecrement}>
        <RemoveCircleOutlineSharp />
      </IconButton>
      <Box
        sx={{
          border: "1p solid #e0e0e0",
          borderRadius: "3px",
          padding: "5px",
          backgroundColor: "white",
          width: "15%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Input
          type="text"
          pattern="[0-9]*"
          value={count}
          onChange={handleInputChange}
          inputProps={{
            min: 0,
            style: { textAlign: "center", border: "none" },
          }}
        />
      </Box>

      <IconButton onClick={handleIncrement}>
        <AddCircleOutlineSharp />
      </IconButton>
    </Box>
  );
}

export default CutterCounter;
