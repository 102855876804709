import { Box, Card, Typography } from "@mui/material";
import React from "react";
import UserTextFieldWithButton from "./UserTextFieldWithButton";

const UsersHeaderLayout = (props) => {
  return (
    <Card
      variant={"outlined"}
      sx={
        {
          // padding: 1,
          // display: "flex",
        }
      }
    >
      <Box
        sx={{
          display: "flex",
          padding: 1,
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant={"h6"}>Список пользователей</Typography>
        <UserTextFieldWithButton />
      </Box>
    </Card>
  );
};
export default UsersHeaderLayout;
