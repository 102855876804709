import {atom, selector} from "recoil";

const bitEvalCuttersBladeStore = atom({
    key: "bitEvalCuttersBladeStore",
    default: [],
})

/* Атом состояния, для строки поиска на странице Bit Evaluation в приложении. */
const searchTextFieldStore = atom({
    key: 'bitEvalSearchTextFieldStore',
    default: '',
})

/* Создание хранилища для сетки данных на странице Bit Evaluation. */

/* Создание хранилища для флажка на странице Bit Evaluation. */
const scrapCheckboxStore = atom({
    key: 'scrapCheckboxStore',
    default: false,
})

/* Создание хранилища для средства выбора даты на странице Bit Evaluation. */
const scrapDatePickerStore = atom({
    key: 'scrapDatePickerStore',
    default: null,
})

/* Это хранилище выпадающего меню на странице Bit Evaluation. */
const scrapSelectionStore = atom({
    key: 'scrapSelectionStore',
    default: 'None',
})

/* Создание хранилища для уровня ремонта выберите на странице Bit Evaluation. */
const bitEvalRepairLevelSelectStore = atom({
    key: 'bitEvalRepairLevelSelectStore',
    default: 'Standard',
})

/* Создание хранилища для флажков на странице Bit Evaluation. */
const bitEvalCheckBoxRepairTypeStore = atom({
    key: 'bitEvalCheckBoxRepairTypeStore',
    default: {
        standard: true,
        hardface: false,
        matrix: false,
    }
})

/* Селектор, который используется для получения состояния флажков. */
const bitEvalGetAllRepairTypesState = selector({
    key: 'bitEvalGetAllRepairTypesState',
    get: ({get}) => {
        const state = get(bitEvalCheckBoxRepairTypeStore);
        if (state) return state;
    }
})



export {
    searchTextFieldStore,
    scrapCheckboxStore,
    scrapDatePickerStore,
    scrapSelectionStore,
    bitEvalRepairLevelSelectStore,
    bitEvalCheckBoxRepairTypeStore,
    bitEvalGetAllRepairTypesState,
    bitEvalCuttersBladeStore,
}