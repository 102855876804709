import { memo, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const AddScrapComponent = () => {
  const [scrapReasons, setScrapReasons] = useState([]);
  const [selectedScrapReasons, setSelectedScrapReasons] = useState([]);
  const [scrapReason, setScrapReason] = useState("");

  const getLastCode = (scrapReasons) => {
    if (scrapReasons.length > 0) {
      const lastCode = scrapReasons[scrapReasons.length - 1].Code;
      return lastCode + 1;
    } else {
      return 1;
    }
  };

  const getScrapCode = (scrapReason) => {
    if (scrapReasons.length > 0) {
      return scrapReasons.find((scrap) => scrap.Name === scrapReason).Code;
    } else {
      return -1;
    }
  };

  const checkScrapReason = (scrapReason) => {
    if (scrapReasons.length > 0) {
      const scrapReasonsNames = scrapReasons.map(
        (scrapReason) => scrapReason.Name
      );
      return scrapReasonsNames.includes(scrapReason);
    } else {
      return false;
    }
  };

  const handleScrapReasonChange = (event) => {
    setScrapReason(event.target.value);
    if (checkScrapReason(event.target.value)) {
      setSelectedScrapReasons([...selectedScrapReasons, event.target.value]);
    }
  };

  const handleScrapReasonCheckboxChange = (event) => {
    const value = event.target.value;
    const currentIndex = selectedScrapReasons.indexOf(value);
    const newSelectedScrapReasons = [...selectedScrapReasons];

    if (currentIndex === -1) {
      newSelectedScrapReasons.push(value);
    } else {
      newSelectedScrapReasons.splice(currentIndex, 1);
    }

    setSelectedScrapReasons(newSelectedScrapReasons);
  };

  const handleAddScrapReason = async () => {
    try {
      if (scrapReason === "") {
        toast.error("Scrap Reason не может быть пустым");
        return;
      } else if (checkScrapReason(scrapReason)) {
        toast.error("Scrap Reason уже существует");
        return;
      } else {
        const response = await axios.post(`api/Scrap/addScrap`, {
          Code: getLastCode(scrapReasons),
          Name: scrapReason,
        });
        toast.success("Scrap Reason Added Successfully");
        await getScrapReasons();
      }
    } catch (error) {
      console.log(error);
      toast.error("Error while adding Scrap Reason");
    }
  };

  const handleDeleteScrapReason = async () => {
    try {
      if (selectedScrapReasons.length === 0) {
        toast.error("Выберите Scrap Reason");
        return;
      } else if (selectedScrapReasons.length > 1) {
        const codes = selectedScrapReasons.map((scrapReason) =>
          getScrapCode(scrapReason)
        );
        const response = await axios.delete(`api/Scrap/deleteScrapReasons`, {
          data: codes,
        });
        toast.success("Scrap Reasons Deleted Successfully");
        await getScrapReasons();
      } else {
        const code = getScrapCode(selectedScrapReasons[0]);
        const response = await axios.delete(`api/Scrap/deleteScrapReasons`, {
          data: [code],
        });
        toast.success("Scrap Reason Deleted Successfully");
        await getScrapReasons();
      }
    } catch (error) {
      console.log(error);
      toast.error("Error while deleting Scrap Reason");
    }
  };

  const getScrapReasons = async () => {
    try {
      const response = await axios.get(`api/Scrap/getScraps`);
      if (response.data.length > 0) {
        setScrapReasons(response.data);
      } else {
        setScrapReasons([]);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error while fetching Scrap Reasons");
    }
  };

  useEffect(() => {
    getScrapReasons();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: "100%",
        width: "100%",
        backgroundColor: "background.default",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          maxWidth: "100%",
          p: 2,
          margin: 2,
          boxShadow: 3,
        }}
      >
        <CardHeader
          title="Scrap Reasons"
          titleTypographyProps={{ align: "center" }}
          subheaderTypographyProps={{ align: "center" }}
          sx={{
            backgroundColor: "primary.main",
            color: "primary.contrastText",
          }}
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Scrap Reason"
            margin="normal"
            name="scrapReason"
            onChange={handleScrapReasonChange}
            type="text"
            variant="outlined"
            required
            helperText={
              scrapReason === "" ? "Scrap Reason не может быть пустым" : " "
            }
          />
          <List>
            {scrapReasons.map((scrapReason) => (
              <ListItem key={scrapReason.Name} disablePadding>
                <ListItemIcon>
                  <Checkbox
                    checked={
                      selectedScrapReasons.indexOf(scrapReason.Name) !== -1
                    }
                    disableRipple
                    value={scrapReason.Name}
                    onChange={handleScrapReasonCheckboxChange}
                  />
                </ListItemIcon>
                <ListItemText primary={scrapReason.Name} />
              </ListItem>
            ))}
          </List>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleAddScrapReason}
            >
              Добавить
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleDeleteScrapReason}
            >
              Удалить
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default memo(AddScrapComponent);
