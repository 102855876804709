import React, {useEffect, useMemo} from "react";
import {Avatar, Box, Card, Tooltip, Typography} from "@mui/material";
import UseObjectState from "../../../../../../hooks/useObjectState";
import useChangeBitEvalBladesState from "../../../../../../hooks/useChangeBitEvalBladesState";

const CustomAvatarSecondary = (props) => {
    const {
        stateArray,
        secondaryEvalCutters,
        bladeIndex,
        pushToSecondaryBladeState,
        index,
        secondaryCutters,
    } = props;
    const avatarState = UseObjectState("");

    useEffect(() => {
        if (secondaryEvalCutters[index]) {
            avatarState.setState(secondaryEvalCutters[index]);
        }
    }, [secondaryEvalCutters]);

    const handleAvatarClick = (props) => {
        switch (avatarState.state) {
            case "":
                pushToSecondaryBladeState(stateArray[1], bladeIndex, index);
                avatarState.setState(stateArray[1]);
                break;
            case "R":
                pushToSecondaryBladeState(stateArray[2], bladeIndex, index);
                avatarState.setState(stateArray[2]);
                break;
            /*case "S":
                pushToSecondaryBladeState(stateArray[3], bladeIndex, index);
                avatarState.setState(stateArray[3]);
                break;*/
            case "X":
                pushToSecondaryBladeState(stateArray[0], bladeIndex, index);
                avatarState.setState(stateArray[0]);
                break;
           /* case "T":
                pushToSecondaryBladeState(stateArray[0], bladeIndex, index);
                avatarState.setState(stateArray[0]);
                break;*/
            default:
                break;
        }
    };

    return (
        <>
            <Tooltip title={avatarState.state}>
                <ColoredAvatar handleAvatarClick={handleAvatarClick} avatarState={avatarState}/>
                {/*<Avatar onClick={handleAvatarClick}>{avatarState.state}</Avatar>*/}
            </Tooltip>
        </>
    );
};

/**
 * Это функция, которая возвращает компонент всплывающей подсказки, содержащий компонент аватара с обработчиком события
 * onClick, который изменяет состояние компонента аватара.
 * @returns Пользовательский компонент аватара, который используется для отображения состояния основного резака.
 */
const CustomAvatarPrimary = (props) => {
    const {
        stateArray,
        primaryEvalCutters,
        index,
        pushToPrimaryBladeState,
        bladeIndex,
        primaryCutters,
    } = props;
    const avatarState = UseObjectState("");

    useEffect(() => {
        if (primaryEvalCutters[index]) {
            avatarState.setState(primaryEvalCutters[index]);
        }
    }, [primaryEvalCutters]);

    const handleAvatarClick = (props) => {
        switch (avatarState.state) {
            case "":
                pushToPrimaryBladeState(stateArray[1], bladeIndex, index);
                avatarState.setState(stateArray[1]);
                break;
            case "R":
                pushToPrimaryBladeState(stateArray[2], bladeIndex, index);
                avatarState.setState(stateArray[2]);
                break;
           /* case "S":
                pushToPrimaryBladeState(stateArray[3], bladeIndex, index);
                avatarState.setState(stateArray[3]);
                break;*/
            case "X":
                pushToPrimaryBladeState(stateArray[0], bladeIndex, index);
                avatarState.setState(stateArray[0]);
                break;
         /*   case "T":
                pushToPrimaryBladeState(stateArray[0], bladeIndex, index);
                avatarState.setState(stateArray[0]);
                break;*/
            default:
                break;
        }
    };

    return (
        <>
            <Tooltip title={avatarState.state}>
                <ColoredAvatar avatarState={avatarState} handleAvatarClick={handleAvatarClick}/>
            </Tooltip>
        </>
    );
};


const listColors = {
    R: 'violet',
    X: 'yellow'
}

const ColoredAvatar = (props) => {
    const {handleAvatarClick, avatarState} = props;

    return (
        <>
            {avatarState.state !== '' ? <Avatar onClick={handleAvatarClick}
                                                sx={{bgcolor: listColors[avatarState.state],color:'black',boxShadow: 6}}>{avatarState.state}</Avatar> :
                <Avatar onClick={handleAvatarClick}>O</Avatar>}
        </>
    )
}

const CutterAvatars = (props) => {
    const {blade, index, stateArray} = props;
    const {primary, secondary, max, primaryEvalCutters, secondaryEvalCutters} =
        blade;
    const cutters = blade.cutters;
    const primaryArray = Array.from({length: primary}, (_, i) => i + 1);
    const secondaryArray = Array.from({length: secondary}, (_, i) => i + 1);
    const {pushToBladePrimaryCutterState, pushToBladeSecondaryCutterState} =
        useChangeBitEvalBladesState();

    return (
        <>
            <Card
                variant={"outlined"}
                sx={{
                    padding: 1,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        alignItems: "stretch",
                    }}
                >
                    <Typography variant={"h6"}>Blade Number {index+1}</Typography>
                    <Typography>Primary Row</Typography>
                    <Box
                        id={"PrimaryBox"}
                        sx={{
                            display: "grid",
                            gridTemplateColumns: `repeat(${max},1fr)`,
                            gap: 1,
                        }}
                    >
                        {primaryArray.map((primary, i) => {
                            return (
                                <CustomAvatarPrimary
                                    key={i}
                                    stateArray={stateArray}
                                    primaryEvalCutters={primaryEvalCutters}
                                    bladeIndex={index}
                                    index={i}
                                    primaryCutters={cutters[0]}
                                    pushToPrimaryBladeState={pushToBladePrimaryCutterState}
                                />
                            );
                        })}
                    </Box>
                    <Box
                        id={"SecondaryBox"}
                        sx={{
                            display: "grid",
                            gridTemplateColumns: `repeat(${max},1fr)`,
                            gap: 1,
                        }}
                    >
                        {secondaryArray.map((primary, i) => {
                            return (
                                <CustomAvatarSecondary
                                    key={i}
                                    stateArray={stateArray}
                                    index={i}
                                    bladeIndex={index}
                                    secondaryEvalCutters={secondaryEvalCutters}
                                    secondaryCutters={cutters[1]}
                                    pushToSecondaryBladeState={pushToBladeSecondaryCutterState}
                                />
                            );
                        })}
                    </Box>
                    <Typography>Secondary Row</Typography>
                </Box>
            </Card>
        </>
    );
};

export default CutterAvatars;