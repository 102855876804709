import React, {useEffect} from 'react';
import {Box, Card, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import UseRECOILState from "../../../../../../../hooks/useRECOILState";
import {bitEvalRepairLevelSelectStore} from "../../../../../../../atoms/bitEvaluationatoms";
import {bitEvalOrderObj} from "../../../../../../../atoms/staticAtoms";
import {useRecoilValue} from "recoil";

/* Компонент, который позволяет пользователю выбирать уровень ремонта. */
const RepairLevelComponent = (props) => {
    
    /* Хук, который используется для получения состояния атома. */
    const repairLevelStore = UseRECOILState(bitEvalRepairLevelSelectStore);
    
    const orderObj = useRecoilValue(bitEvalOrderObj);
    
    const handleChangeRepairLevel = (e) => {
        repairLevelStore.setState(e.target.value);
        orderObj.RepairLevel = e.target.value;
    }
    
    useEffect(() => {
        orderObj.RepairLevel = repairLevelStore.state;
    },[orderObj.OrderId])

    return (
        <React.Fragment>
            <Card variant={"outlined"}
                  sx={{
                      display: 'flex',
                      gridArea: 'level',
                      width: '100%',
                      // justifyContent: 'center',
                  }}
            >
                
            <Box
                id={'RepairLevelComponent'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    padding: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <FormControl
                    id={'FormControl'}
                    fullWidth
                   
                >
                    <InputLabel>Select Repair Level</InputLabel>
                    <Select
                        label={'Select Repair Level'}
                        value={repairLevelStore.state}
                        onChange={handleChangeRepairLevel}
                    >
                        <MenuItem value={'Standard'}>Standard</MenuItem>
                        <MenuItem value={'Premium'}>Premium</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            </Card>

        </React.Fragment>
    );
}

export default RepairLevelComponent;