import React, {useEffect} from 'react';
import {Box, Card} from "@mui/material";
import CutterAvatars from "./CutterAvatars";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {bitEvalCuttersBladeStore} from "../../../../../../atoms/bitEvaluationatoms";
import {bitEvalCutterMap, bitEvalOrder, tempBitEvalCutterMap} from "../../../../../../atoms/staticAtoms";
import {DbData, Order} from "../../../../../../atoms/repairEntryFetchData";

/* Компонент отображает карточку с информацией о блэйде. */
const CuttersCard = (props) => {
    const max = 6;
    const stateArray = ['','R', 'X']
    const bladeStore = useSetRecoilState(bitEvalCuttersBladeStore);
    let tempObject = {
        primaryEvalCutters: [],
        secondaryEvalCutters: [],
    }

    const tempCutterMap = useRecoilValue(tempBitEvalCutterMap);
    if(tempCutterMap.length === 0) {
        alert("Карта пуста");
        return (
            <></>
        )
    }
    
    //TODO: Здесь recoil для запроса блэйда из БД.
    /* Это тестовые данные для компонента CutterAvatars. */
    /*    primary: primaryTextFieldStore.state,
            secondary: secondaryTextFieldStore.state,
            max: max,
            cutters: [
            {
                primary: new Array(parseInt(primaryTextFieldStore.state)).fill('testCutter', 0, parseInt(primaryTextFieldStore.state)),
            },
            {
                secondary: new Array(parseInt(secondaryTextFieldStore.state)).fill('testCutter',0,parseInt(secondaryTextFieldStore.state))
            }]*/


    let someTestBlade = [
        {
            "primary": 6,
            "secondary": 7,
            "max": 7,
            "cutters": [
                {
                    "primary": [111, 222, 333]
                },
                {
                    "secondary": [111, 222, 333]
                },
            ],
            "primaryEvalCutters": [],
            "secondaryEvalCutters": [],
        }
    ]
    someTestBlade[0].primaryEvalCutters = new Array(someTestBlade[0].primary).fill('null', 0);
    someTestBlade[0].secondaryEvalCutters = new Array(someTestBlade[0].secondary).fill('null', 0);


    
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                padding: 1,
            }}
        >
            {tempCutterMap.map((blade, i) => {
                return <CutterAvatars blade={blade} key={i} index={i} stateArray={stateArray}/>
            })}
        </Box>

    );
}

export default CuttersCard;



/*    const testBlade = [
        {
            "primary": 6,
            "secondary": 7,
            "max": 7,
            cutters: [
                {
                    primary: [111, 222, 333]
                },
                {
                    secondary: [111, 222, 333]
                }
            ],
            primary_eval_cutters: {
                R: [],
                S: [],
                X: [],
                T: [],
            },
            secondary_eval_cutters: {
                R: [],
                S: [],
                X: [],
                T: [],
            }
        },
        {
            "primary": 6,
            "secondary": 7,
            "max": 7,
            cutters: [
                {
                    primary: [111, 222, 333]
                },
                {
                    secondary: [111, 222, 333]
                }
            ],
            eval_cutters: {
                R: [],
                S: [],
                X: [],
                T: [],
            }
        }
    ]*/