import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import useObjectState from "../../../../hooks/useObjectState";
import { BitEvalRepairTabs } from "./components";
import { useLocation, useParams } from "react-router-dom";
import { EvaluationTab } from "./components/EvaluationTabComponents";
import EvalutionNotes from "../BitEvalNotesForm/EvalutionNotesTab";
import EvalutionDocuments from "../BitEvalDocumentsForm/EvalutionDocumentsTab";
import { CuttersTabLayout } from "./components/CuttersTabComponents";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  bitEvalCutterMap,
  bitEvalDataGridStore,
  bitEvalOrder,
  tempBitEvalCutterMap,
} from "../../../../atoms/staticAtoms";
import { DbData, Order } from "../../../../atoms/repairEntryFetchData";
import { Estimate } from "../BitEvalEstimate";
import { toast } from "react-toastify";

import axios from "axios";
import useRECOILState from "../../../../hooks/useRECOILState";

const BitEvalRepairForm = (props) => {
  const tabStore = useObjectState("Evaluation");
  const { rows, setRows } = useRECOILState(bitEvalDataGridStore);
  let scrapStore = false;
  const { serial } = useParams();
  const getDrillData = () => {
    axios
      .get(`api/DrillsControllers/getDrill`, {
        params: { serial: serial.toString() },
      })
      .then((res) => {
        if (res.data.Scrap) scrapStore = !scrapStore;
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  return (
    <Box
      id={"BitEvalRepairFormBox"}
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <BitEvalRepairTabs tabStore={tabStore} />
      <Box
        sx={{
          padding: 1,
        }}
      >
        {tabStore.state === "Evaluation" && <EvaluationTab serial={serial} />}
        {tabStore.state === "Estimate" && !scrapStore && (
          <Estimate serial={serial} />
        )}
        {tabStore.state === "Notes" && <EvalutionNotes serial={serial} />}
        {tabStore.state === "Documents" && (
          <EvalutionDocuments serial={serial} />
        )}
        {tabStore.state === "Cutters" && !scrapStore && <CuttersTabLayout />}
      </Box>
    </Box>
  );
};

export default BitEvalRepairForm;
