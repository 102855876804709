import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import DocumentsList from "./components/DocumentList";
import DocumentsFooter from "./components/DocumentFooter";
import { allDocs } from "../../../../atoms/BitEvalutionDocsAtoms/atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  bitEvalOrderObj,
  bitEvalSelectedDrill,
} from "../../../../atoms/staticAtoms";
import { DbData, Order } from "../../../../atoms/repairEntryFetchData";

export const EvalutionDocuments = (props) => {
  const [docs, setDocs] = useRecoilState(allDocs);
  const [selectedDrill, setSelectedDrill] =
    useRecoilState(bitEvalSelectedDrill);
  const [isUpdated, setIsUpdated] = useState(false);
  const orderObj = useRecoilValue(bitEvalOrderObj);

  const loadImages = async () => {
    let dbData = new DbData(
      Order.model,
      [Order.fields.Id, Order.fields.Serial, Order.fields.Images],
      Order.fields.Serial,
      selectedDrill.Serial
    );
    await dbData.sendRequest();
    let data = dbData.result;
    let tempImages = [];

    if (data !== "No find") {
      for (let i = 0; i < data.length; i++) {
        let tempOrder = data[i];

        if (tempOrder.Images === "NO DATA") continue;

        let orderImages = JSON.parse(tempOrder.Images);
        for (let j = 0; j < orderImages.length; j++) {
          let imgObj = {
            order: tempOrder.OrderId,
            fileName: orderImages[j].FileName,
            title: orderImages[j].Title,
          };
          tempImages.push(imgObj);
        }
      }
    }

    for (let i = 0; i < orderObj.Images.length; i++) {
      let obj = {
        order: orderObj.OrderId,
        fileName: orderObj.Images[i].FileName,
        title: orderObj.Images[i].Title,
      };
      tempImages.push(obj);
      }
      console.log(orderObj);
    setDocs(tempImages);
  };
  useEffect(() => {
    loadImages();
  }, [isUpdated]);
 
  return (
    <React.Fragment>
      <Box
        sx={{
          border: "1px solid #e0e0e0",
          borderRadius: "1px",
          padding: "10px",
        }}
      >
              <DocumentsList docs={docs} />
              <DocumentsFooter isUpdated={ isUpdated} setIsUpdated={setIsUpdated} />
      </Box>
    </React.Fragment>
  );
};

export default EvalutionDocuments;
