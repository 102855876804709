import React from 'react';
import {Checkbox, FormControlLabel, FormGroup, Stack} from "@mui/material";

/**
 * Чекбокс с названием
 * @param props (store - переменная для записи состояния чекбокса, label - подпись к чекбоксу)
 * @returns {JSX.Element}
 * @constructor
 */
const BladeTemplateCheckboxWithLabel = (props) => {
    
    const handleChange = (e) => {
        props.store.setState(e.target.checked);
    }
    
    return (
        <React.Fragment>
            <Stack display={"grid"} gap={1}
                   direction={"row"}
                   alignItems={"center"}
            >
                <FormGroup
                    sx={{
                        display: "grid",
                        alignItems: "center",
                    }}
                >
                    <FormControlLabel control={<Checkbox checked={props.store.state} onChange={handleChange}/> } label={props.label}/>
                </FormGroup>
            </Stack>
        </React.Fragment>
    );
}

export default BladeTemplateCheckboxWithLabel;