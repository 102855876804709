import React from "react";
import {Box, Button, Card, Typography} from "@mui/material";
import BladeTemplateCheckboxWithLabel from "./BladeTemplateCheckboxWithLabel";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    cutterBladeNumber,
    cutterMapMaxCuttersCount,
    cutterPrimaryRowCheckboxStore,
    cutterPrimaryTextFieldStore,
    cutterSecondaryRowCheckboxStore,
    cutterSecondaryTextFieldStore,
} from "../../../../../../atoms/cutterMapTextFieldsAtoms";
import useRECOILState from "../../../../../../hooks/useRECOILState";
import BladeTemplateTextFieldWithLabel from "./BladeTemplateTextFieldWithLabel";
import useObjectState from "../../../../../../hooks/useObjectState";
import {cutterMapBladeCuttersStore} from "../../../../../../atoms/cutterMapState";
import useChangeBladesState from "../../../../../../hooks/useChangeBladesState";


const BladeTemplateCreator = (props) => {
    const primaryCheckboxStore = useRECOILState(cutterPrimaryRowCheckboxStore);
    const primaryTextFieldStore = useRECOILState(cutterPrimaryTextFieldStore);
    const secondaryTextFieldStore = useRECOILState(cutterSecondaryTextFieldStore);
    const secondaryCheckboxStore = useRECOILState(
        cutterSecondaryRowCheckboxStore
    );
    const bladeNumberSetter = useSetRecoilState(cutterBladeNumber);
    let bladeNumberGetter = useRecoilValue(cutterBladeNumber);
    const pushToBladeStore = useChangeBladesState();
    const max = useRecoilValue(cutterMapMaxCuttersCount);

    const handleAddButton = (e) => {
        if (primaryCheckboxStore.state || secondaryCheckboxStore.state) {
            if (secondaryTextFieldStore.state <= 0) {
                pushToBladeStore.pushToBladesState({
                    primary: primaryTextFieldStore.state,
                    secondary: secondaryTextFieldStore.state,
                    max: max,
                    cutters: [
                        {
                            primary: new Array(parseInt(primaryTextFieldStore.state)).fill(
                                "None",
                                0,
                                parseInt(primaryTextFieldStore.state)
                            ),
                        },
                        {
                            secondary: [],
                        },
                    ],
                });
            } else if (primaryTextFieldStore.state <= 0) {
                pushToBladeStore.pushToBladesState({
                    primary: primaryTextFieldStore.state,
                    secondary: secondaryTextFieldStore.state,
                    max: max,
                    cutters: [
                        {
                            primary: [],
                        },
                        {
                            secondary: new Array(
                                parseInt(secondaryTextFieldStore.state)
                            ).fill("None", 0, parseInt(secondaryTextFieldStore.state)),
                        },
                    ],
                });
            } else {
                pushToBladeStore.pushToBladesState({
                    primary: primaryTextFieldStore.state,
                    secondary: secondaryTextFieldStore.state,
                    max: max,
                    cutters: [
                        {
                            primary: new Array(parseInt(primaryTextFieldStore.state)).fill(
                                "None",
                                0,
                                parseInt(primaryTextFieldStore.state)
                            ),
                        },
                        {
                            secondary: new Array(
                                parseInt(secondaryTextFieldStore.state)
                            ).fill("None", 0, parseInt(secondaryTextFieldStore.state)),
                        },
                    ],
                });
            }
            if (bladeNumberGetter.length === 0) {
                bladeNumberSetter([0]);
            } else {
                bladeNumberSetter([
                    ...bladeNumberGetter,
                    bladeNumberGetter[bladeNumberGetter.length - 1] + 1,
                ]);
            }
        } else alert("Please select Primary/Secondary Row");
    };

    return (
        <React.Fragment>
            <Card
                variant={"outlined"}
                sx={{
                    display: "grid",
                }}
            >
                <Box
                    id="CreateNewBox"
                    sx={{
                        display: "grid",
                        gridTemplateColumns: `0.5fr repeat(2,1fr) 0.2fr`,
                        gridTemplateAreas: `"n c c b"
                        "n c c b"`,
                        gap: 1,
                        padding: 1,
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "grid",
                            gridArea: "n",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Typography variant={"h5"}>NEW</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "grid",
                            gridArea: "c",
                            gridTemplateColumns: `repeat(2,1fr)`,

                            alignItems: "center",
                            gap: 1,
                            padding: 1,
                        }}
                    >
                        <BladeTemplateCheckboxWithLabel
                            store={primaryCheckboxStore}
                            label={"Primary Row"}
                        />
                        <BladeTemplateTextFieldWithLabel
                            store={primaryTextFieldStore}
                            label={"Primary Cutters"}
                            disable={!primaryCheckboxStore.state}
                        />
                        <BladeTemplateCheckboxWithLabel
                            store={secondaryCheckboxStore}
                            label={"Secondary Row"}
                        />
                        <BladeTemplateTextFieldWithLabel
                            store={secondaryTextFieldStore}
                            label={"Secondary Cutters"}
                            disable={!secondaryCheckboxStore.state}
                        />
                    </Box>
                    <Button
                        size={"small"}
                        variant={"outlined"}
                        onClick={handleAddButton}
                        sx={{
                            display: "grid",
                            gridArea: "b",
                            alignItems: "center",
                        }}
                    >
                        Add
                    </Button>
                </Box>
            </Card>
        </React.Fragment>
    );
};

export default BladeTemplateCreator;
