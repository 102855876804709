import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import RepairOrderTable from "./RepairOrderTable";
import RepairOrderTypeTable from "./RepairOrderTypeTable";
import Divider from "@mui/material/Divider";
import TextItem from "../../../../OtherComponents/TextItem";
import { useRecoilState } from "recoil";
import { selectedOrder } from "../../../../../../atoms/staticAtoms";
import { DbData, Order } from "../../../../../../atoms/repairEntryFetchData";
import useObjectState from "../../../../../../hooks/useObjectState";
import { OrderConfigureCutterMapLayout } from "../../../OrderConfigureCutterMap";
import { orderingWorkOrderAtom } from "../../../../../../atoms/Ordering_Atoms/OrderingTextFieldAtoms";
import { RepairOrderReadonlyCutterMap } from "../repair-order-readonly-cutter-map";
import { RepairOrderLocationSelect } from "../repair-order-location";
import axios from "axios";
import { toast } from "react-toastify";
import { orderingRows } from "../../../../../../atoms/Ordering_Atoms/OrderingAtoms";
import { useNavigate } from "react-router-dom";

const RepairOrderLayout = (props) => {
  const [workOrder, setWordOrder] = useRecoilState(orderingWorkOrderAtom);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [orderId, setOrderId] = useRecoilState(selectedOrder);
  const [rows, setRows] = useRecoilState(orderingRows);

  const [repairList, setRepairList] = useState([]);
  const modalStore = useObjectState(false);
  const navigate = useNavigate();

  /* const handleWorkOrderChange = (event) => {
           {
               if (event.target.value === '') {
                   setWordOrder(event.target.value);
                   setError(true);
                   setHelperText('Please enter a valid work order');
   
               } else {
                   setWordOrder(event.target.value);
                   setError(false);
                   setHelperText('');
               }
           }
       }*/

  //

  const handleConfigureButtonClick = () => {
    modalStore.setState(!modalStore.state);
  };

  const loadInfo = async () => {
    let dbData = new DbData(
      Order.model,
      [Order.fields.Id, Order.fields.RepairType],
      Order.fields.Id,
      orderId
    );
    await dbData.sendRequest();

    let repairCard = JSON.parse(dbData.result[0][Order.fields.RepairType]);

    let repairList = [];

    Object.keys(repairCard).forEach((key) => {
      if (repairCard[key] === true) {
        repairList.push(key);
      }
    });

    setRepairList(repairList);
  };
  const onReleaseHandler = () => {
    const url = `/api/orders/releaseOrder?orderID=${orderId}`;
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    axios
      .post(url, null, axiosConfig) // Передаем null вместо тела запроса
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          toast.success("Order successfully released");
          navigate('/repairs');
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        toast.error("Failed to release order");
      });
      setRows(orderingRows.filter((row) => row.orderId !== orderId));

  };

  useEffect(() => {
    loadInfo();
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <OrderConfigureCutterMapLayout
        modalStore={modalStore}
        orderId={orderId}
      />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `2fr 1fr`,
          gridTemplateAreas: `"RepairOrderTable RepairOrderDetail"
                    "RepairOrderReadonlyCutterMap RepairOrderReadonlyCutterMap"`,
          gap: 2,
          height: "100%",
        }}
        id={"RepairOrderLayout"}
      >
        <Box
          sx={{
            // display: "grid",
            gridTemplateColumns: "1fr",
            gridTemplateRows: `repeat(2,1fr)`,
            gap: 1,
            gridArea: "RepairOrderTable",
            // alignItems: 'center',
          }}
          id={"RepairOrderLayoutLeft"}
        >
          <RepairOrderTypeTable orderId={orderId} />
        </Box>
        <Box>
          <Card
            variant={"outlined"}
            sx={{ padding: "10px", gridArea: "RepairOrderDetail" }}
          >
            <Box>
              <TextItem
                size={"h6"}
                text={"Repair Order Detail"}
                mb={10}
                fullWidth
              />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {repairList.includes("matrix") && (
                  <TextItem
                    size={"h6"}
                    text={"Matrix"}
                    fullWidth
                    bcolor={"#ffeb9d"}
                  />
                )}
                {repairList.includes("standard") && (
                  <TextItem
                    size={"h6"}
                    text={"Standard"}
                    fullWidth
                    mx={10}
                    bcolor={"#c7ffb0"}
                  />
                )}
                {repairList.includes("hardface") && (
                  <TextItem
                    size={"h6"}
                    text={"Hard Face"}
                    fullWidth
                    bcolor={"#ff4e4e"}
                  />
                )}
              </Box>
            </Box>
            <Divider sx={{ marginBottom: "5%", marginTop: "10px" }} />
            {/* <TextField
                            label="Work order"
                            fullWidth
                            value={workOrder}
                            onChange={handleWorkOrderChange}
                            error={error}
                            helperText={helperText}
                        />*/}
            <Divider sx={{ marginBottom: "10px", marginTop: "5%" }} />
            <Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Button
                  variant={"contained"}
                  onClick={handleConfigureButtonClick}
                  fullWidth
                >
                  Configure
                </Button>
                <Button
                  variant={"outlined"}
                  fullWidth
                  onClick={onReleaseHandler}
                >
                  Release
                </Button>
              </Box>
              <Divider sx={{ marginY: "10px" }} />
            </Box>

            <RepairOrderLocationSelect drillSerial={orderId} />
          </Card>
        </Box>
        <RepairOrderReadonlyCutterMap orderId={orderId} />
      </Box>
    </Box>
  );
};

export default RepairOrderLayout;
