import React from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";
import RepairTypeCheckboxes from "./RepairTypeCheckboxes";

const RepairTypeComponent = (props) => {
    return (
        <React.Fragment>
            <Card variant={"outlined"}
                  sx={{
                      display: "grid",
                      gridArea: 'type',
                  }}
            >

                <Typography variant={"subtitle1"}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                >
                    Repair Type Card
                </Typography>
                <CardContent>
                    <Box
                        id="repairTypeComponent"
                        sx={{
                            gap: 1,
                            padding: 1,
                            alignItems: "center",
                        }}
                    >
                        <RepairTypeCheckboxes/>
                    </Box>
                </CardContent>

            </Card>
        </React.Fragment>
    );
}

export default RepairTypeComponent;