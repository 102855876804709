import React from 'react';
import {useNavigate} from 'react-router-dom';

export const useNavigatingAway = () => {
    const navigate = useNavigate();

    const [isNavigatingAway, setIsNavigatingAway] = React.useState(false);

    const handleBeforeUnload = React.useCallback(() => {
        setIsNavigatingAway(true);
    }, []);

    React.useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [handleBeforeUnload]);

    return [isNavigatingAway, navigate];
}
