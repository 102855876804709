import React, { useEffect, useState } from "react";
import { Button, MenuItem, TextField, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {
  DbData,
  urlSetParam,
  User,
} from "../../../../atoms/repairEntryFetchData";
import { toast } from "react-toastify";
import UseValidation from "../../../../hooks/useValidation";
import useObjectState from "../../../../hooks/useObjectState";

export const LocationAdd = (props) => {
  const isError = useObjectState(false);

  const { isNumberValidation } = UseValidation();

  const [inputLocation, setInputLocation] = useState("");
  const [inputLocationName, setInputLocationName] = useState("");
  const [inputLocationCode, setInputLocationCode] = useState("");

  const handleLocationChange = (event) => {
    setInputLocation(event.target.value);
    setInputLocationCode(event.target.value);
  };

  const handleLocationNameChange = (event) => {
    setInputLocationName(event.target.value);
  };

  const handleLocationCodeChange = (event) => {
    setInputLocationCode(event.target.value);
  };

  const addOrEditLocation = () => {
    let url = "/api/location/addLocation";
    let params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Name: inputLocationName,
        Code: inputLocationCode,
      }),
    };

    fetch(url, params).then((response) => {
      if (response.ok) {
        toast.success("Location added successfully");
        return;
      }
      toast.error("Error while adding location");
    });
  };

  const removeLocation = () => {
    let url = "/api/location/deleteLocation?locationCode=%0";
    let urlRequest = urlSetParam(url, [inputLocation]);

    fetch(urlRequest).then((response) => {
      if (response.ok) {
        toast.success("Location removed successfully");
        return;
      }
      toast.error("Error while removing location");
    });
  };

  const loadLocation = () => {
    let url = "/api/location/getLocation?locationCode=%0";
    let urlRequest = urlSetParam(url, [inputLocation]);

    fetch(urlRequest).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          setInputLocationName(data.name);
          setInputLocationCode(data.code);
        });
        return;
      }
      toast.error("Error while loading location");
    });
  };

  return (
    <Box sx={{ padding: "10px", width: "100%" }}>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            border: "1px solid #e0e0e0",
            borderRadius: "3px",
            padding: "10px",
            width: "25%",
          }}
        >
          <Typography variant={"h6"}>Локации</Typography>
          <Divider sx={{ marginY: "10px" }} />
          <Box sx={{ display: "flex" }}>
            <TextField
              label={"Код локации"}
              value={inputLocation}
              onChange={handleLocationChange}
              // sx={{marginX: "10px"}}
              fullWidth
            />
            <Button
              variant={"contained"}
              sx={{ marginX: "10px" }}
              onClick={() => {
                loadLocation();
              }}
              fullWidth
            >
              Загрузить
            </Button>
          </Box>
          <Divider sx={{ marginY: "10px" }} />
          <Box>
            <TextField
              label={"Наименование"}
              value={inputLocationName}
              onChange={handleLocationNameChange}
              sx={{ marginY: "5px" }}
              fullWidth
            ></TextField>
            {/*<TextField
                            label={"Код"}
                            value={inputLocationCode}
                            onChange={(e) => {
                                handleLocationCodeChange(e);
                            }}
                            fullWidth
                        ></TextField>*/}
          </Box>
          <Divider sx={{ marginY: "10px" }} />
          <Box>
            <Button
              variant={"contained"}
              sx={{ marginY: "5px" }}
              onClick={() => {
                addOrEditLocation();
              }}
              fullWidth
            >
              Добавить или изменить
            </Button>
            <Button
              variant={"contained"}
              onClick={() => {
                removeLocation();
              }}
              fullWidth
            >
              Удалить
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LocationAdd;
