import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import NotesList from "./components/ListNotes";
import FullNotes from "./components/FullNotes";
import { Typography } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  allNotes,
  selectedNotes,
} from "../../../../atoms/BitEvalutionNotesAtoms/atoms";
import FooterNotes from "./components/FooterNotes";
import { DbData, Order } from "../../../../atoms/repairEntryFetchData";
import {
  bitEvalOrderObj,
  bitEvalSelectedDrill,
} from "../../../../atoms/staticAtoms";
export const EvalutionNotes = (props) => {
  const [notes, setNotes] = useRecoilState(allNotes);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedDrill, setSelectedDrill] =
    useRecoilState(bitEvalSelectedDrill);

  const orderObj = useRecoilValue(bitEvalOrderObj);
  const getNotes = async () => {
    let dbData = new DbData(
      Order.model,
      [Order.fields.Id, Order.fields.Serial, Order.fields.Notes],
      Order.fields.Serial,
      selectedDrill.Serial
    );
    await dbData.sendRequest();

    let result = dbData.result;

    let tempNotes = [];
    if (result !== "No find") {
      result.map((item) => {
        if (item.FieldNotes === "NO DATA") return;
        let notes = JSON.parse(item.FieldNotes);
        notes.map((note) => {
          let noteObj = {
            Title: note.Title,
            Text: note.Text,
            Order: item.OrderId,
          };
          tempNotes.push(noteObj);
        });
      });
    }

    for (let i = 0; i < orderObj.FieldNotes.length; i++) {
      let noteObj = {
        Title: orderObj.FieldNotes[i].Title,
        Text: orderObj.FieldNotes[i].Text,
        Order: orderObj.OrderId,
      };
      tempNotes.push(noteObj);
    }

    setNotes(tempNotes);
  };
  useEffect(() => {
    getNotes();
  }, [isUpdated]);
  return (
    <React.Fragment>
      <Box
        sx={{
          border: "1px solid #e0e0e0",
          borderRadius: "1px",
          padding: "10px",
          display: "flex",
        }}
      >
        <NotesList notes={notes} />
        <FullNotes />
      </Box>
      <FooterNotes setIsUpdated={setIsUpdated} isUpdated={isUpdated} />
    </React.Fragment>
  );
};

export default EvalutionNotes;
