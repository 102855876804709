import React from "react";
import {
  Box,
  Button,
  LinearProgress,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  styled,
} from "@mui/material";
import {
  AddCircle,
  Delete,
  FileUpload,
  TextSnippet,
} from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import CuttersImport from "./CuttersImport";

export const StyledProgress = styled(LinearProgress)(({ theme }) => ({
  width: "100%",
  height: "0.5rem",
  borderRadius: "0.5rem",
}));

export const StyledCard = styled(Card)(({ theme, sx }) => ({
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  maxHeight: "100%",
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    boxShadow: "0 0 11px rgba(33,33,33,.2)",
    transform: "scale(1.01)",
    transition: "all 0.3s ease-in-out",
  },
  ...sx,
}));

const ImportExcel = () => {
  const [progress, setProgress] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [imported, setImported] = React.useState(false);
  const [lastImport, setLastImport] = React.useState("");
  const [lastImportCutters, setLastImportCutters] = React.useState("");
  const [importedFile, setImportedFile] = React.useState("");
  let interval;
  const handleOpenClose = () => {
    setOpen(!open);
  };

  const stopUpload = (interval) => {
    clearInterval(interval);
    setProgress(0);
  };

  const getProgress = async (interval) => {
    if (progress === 100) {
      clearInterval(interval);
      return;
    }
    try {
      const response = await axios.get("/api/Excel/getProgress");
      setProgress(response.data);
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong");
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files.length === 0) {
      toast.error("No file selected");
      return;
    }
    setImportedFile(e.target.files[0]);
  };

  const getLastImport = async () => {
    try {
      const response = await axios.get("/api/Excel/lastImportDate");
      if (response.status === 204) {
        toast.info("No import yet");
        return;
      }
      const formattedDate = new Date(response.data).toLocaleDateString("ru-RU");
      setLastImport(formattedDate);
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong");
    }
  };

  const importExcel = async () => {
    if (!importedFile) {
      toast.error("No file selected");
      return;
    }
    if (!importedFile.name.endsWith(".xlsx")) {
      toast.error("Wrong file format, must be .xlsx");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", importedFile);
      setImported(false);
      setLastImport("Importing...");
      const interval = setInterval(() => getProgress(interval), 3000);
      toast.info("Import started");
      const response = await axios.post("/api/Excel/importExcel", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setImported(true);

      if (response.status === 200) {
        toast.success("Import completed");
        clearInterval(interval);
      }

      await getLastImport();
    } catch (e) {
      console.log(e);
      setLastImport("Error");
      toast.error("Something went wrong");
      stopUpload(interval);
    }
  };

  const getLastImportCutters = async () => {
    try {
      const response = await axios.get("/api/Excel/lastCutterImportDate");
      if (response.status === 204) {
        toast.info("No cutters import yet");
        return;
      }
      const formattedDate = new Date(response.data).toLocaleDateString("ru-RU");
      setLastImportCutters(formattedDate);
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong");
    }
  };

  React.useEffect(() => {
    getLastImport();
    getLastImportCutters();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "fit-content",
        }}
      >
        <StyledCard
          sx={{
            width: "fit-content",
            height: "fit-content",
          }}
        >
          <CardHeader
            title={"Last import"}
            subheader={
              !lastImport ? (
                "No imports yet"
              ) : (
                <Typography
                  variant={"body2"}
                  sx={{
                    color: "text.secondary",
                    fontWeight: "bold",
                    "&:hover": {
                      color: "primary.main",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(lastImport);
                    toast.success("Copied to clipboard");
                  }}
                >
                  {lastImport}
                </Typography>
              )
            }
          />
        </StyledCard>
        <StyledCard
          sx={{
            width: "fit-content",
            height: "fit-content",
          }}
        >
          <CardHeader
            title={"Last cutters import"}
            subheader={
              !lastImportCutters ? (
                "No imports yet"
              ) : (
                <Typography
                  variant={"body2"}
                  sx={{
                    color: "text.secondary",
                    fontWeight: "bold",
                    "&:hover": {
                      color: "primary.main",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(lastImportCutters);
                    toast.success("Copied to clipboard");
                  }}
                >
                  {lastImportCutters}
                </Typography>
              )
            }
          />
        </StyledCard>
      </Box>
      <StyledCard variant={"outlined"}>
        <CardContent>
          <Typography variant="h5" component="div">
            Import Drills
          </Typography>
          <Typography variant="body2">
            Import the excel file with the data to be added to the database
          </Typography>
          <Typography variant="body2">
            The file must be in the .xlsx format
          </Typography>
        </CardContent>
        {importedFile && (
          <CardActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant={"body2"}
              sx={{
                color: "text.secondary",
                fontWeight: "bold",
                "&:hover": {
                  color: "primary.main",
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                navigator.clipboard.writeText(importedFile.name);
                toast.success("Copied to clipboard");
              }}
            >
              {importedFile.name}
            </Typography>
            <Button
              variant={"contained"}
              color={"primary"}
              startIcon={<Delete />}
              onClick={() => {
                setImportedFile("");
                setImported(false);
              }}
            >
              Clear
            </Button>
          </CardActions>
        )}
        <StyledProgress variant="determinate" value={progress} />
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <input
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant={"contained"}
              color={"primary"}
              component="span"
              startIcon={<AddCircle />}
            >
              Choose file
            </Button>
          </label>

          <Button
            variant={"contained"}
            color={"primary"}
            startIcon={<FileUpload />}
            onClick={importExcel}
            disabled={!importedFile}
          >
            Import
          </Button>
        </CardActions>
      </StyledCard>
      <CuttersImport />
    </Box>
  );
};

export default React.memo(ImportExcel);
