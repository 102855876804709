import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { DbData, User } from "../../../../atoms/repairEntryFetchData";
import { toast } from "react-toastify";
import UsersInfoCard from "./UsersInfoCard";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useRecoilState } from "recoil";
import { userRows } from "../../../../atoms/Administration_Atoms/atoms";
import UserTextFieldWithButton from "./UserTextFieldWithButton";
import UsersHeaderLayout from "./UsersHeaderLayout";
import CancelIcon from "@mui/icons-material/Cancel";
import ConfirmationDialog from "../../../../components/ConfirmationDialog/ConfirmationDialog";
const RoleSelectCell = ({ row, roles, disabled }) => {
  const [selectedValue, setSelectedValue] = useState(row.role);

  const changeUserRole = (e) => {
    const newRole = e.target.value;
    setSelectedValue(newRole);
    let roleCode = roles.find((role) => role.Name === newRole).Code;
    const userNameFind = row.name;
    User.setOrUpdateUser(userNameFind, roleCode).then((response) => {
      if (response.ok) {
        toast.success(`Роль  ${userNameFind} успешно обновлена`);
        return;
      }
      toast.error(`Ошибка изменения роли ${userNameFind}`);
    });
  };

  return (
    <Select
      fullWidth
      onChange={changeUserRole}
      value={selectedValue}
      sx={{
        boxShadow: "none",
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
      }}
      disabled={disabled}
    >
      {roles.map((role, index) => (
        <MenuItem key={index} value={role.Name}>
          {role.Name}
        </MenuItem>
      ))}
    </Select>
  );
};
export const UserAdd = (props) => {
  const [roles, setRoles] = useState([]);
  const [userNameFind, setUserNameFind] = useState("");
  const [rows, setRows] = useRecoilState(userRows);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const userColumns = [
    {
      width: 1,
      disableColumnMenu: true,
      sortable: false,

      renderCell: (params) => {
        const handleCellClick = () => {
          setSelectedUser(params.row);
          console.log(params.row);
          setOpen(true);
        };
        return (
          params.row.role !== "Бог" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <IconButton onClick={handleCellClick} color="error">
                <CancelIcon />
              </IconButton>
            </Box>
          )
        );
      },
    },
    {
      field: "name",
      headerName: "Логин",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Почта",
      flex: 1,
    },
    {
      flex: 1,
      field: "role",
      headerName: "Роль",

      renderCell: (params) => {
        return params.row.role === "Бог" ? (
          <RoleSelectCell row={params.row} roles={roles} disabled />
        ) : (
          <RoleSelectCell row={params.row} roles={roles} />
        );
      },
    },
  ];
  const handleRemoveUserClick = () => {
    if (!selectedUser) return;

    const { name } = selectedUser;

    User.removeUser(name)
      .then((response) => {
        if (response.ok) {
          setRows((prevRows) => prevRows.filter((user) => user.name !== name));
          toast.success(`Пользователь ${name} удален`);
        } else {
          toast.error(`Ошибка удаления пользователя ${name}`);
        }
      })
      .finally(() => {
        setOpen(false);
        setSelectedUser(null);
      });
  };

  const asyncGetRoles = async () => {
    let dbData = new DbData("Role", ["Code", "Name"]);
    await dbData.sendRequest();
    setRoles(dbData.result);
  };

  useEffect(() => {
    asyncGetRoles();
  }, []);

  return (
    <Box sx={{ padding: "10px", width: "100%" }}>
      <ConfirmationDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={handleRemoveUserClick}
        title={"Удалить пользователя из системы?"}
      />
      <UsersHeaderLayout />
      {rows && (
        <DataGrid
          columns={userColumns}
          rows={rows}
          getRowId={(row) => row.name}
          sx={{
            height: "70vh",
          }}
        />
      )}
    </Box>
  );
};

export default UserAdd;
