import React, { Component, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import * as Generator from "@mui/x-data-grid-generator";
import { Box, Card, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const RepairDataGrid = ({ rows, store, modalStore }) => {
  // const insertRow = (rowInfo) => {
  //     row.push(rowInfo)
  // }
  const drillColumns = [
    {
      field: "DrillName",
      headerName: "Наименование долота",
      flex: 1,
    },
    {
      field: "Serial",
      headerName: "Серийный номер долота",
      flex: 1,
    },
    {
      field: "MaterialCode",
      headerName: "Артикул",
      flex: 1,
    },
    {
      field: "Location",
      headerName: "Эксплуатирующее подразделение",
      flex: 1,
    },
    {
      field: "Size",
      headerName: "Размер",
      flex: 1,
    },
    {
      field: "Type",
      headerName: "Тип",
      flex: 1,
    },
  ];

  const handleRowClick = (e) => {
    store.setState(e);
    modalStore.setState(true);
  };

  return (
    <Stack display={"flex"} width={"100%"} height={"100%"}>
      <DataGrid
        columns={drillColumns}
        rows={rows}
        getRowId={(row) => row.Serial}
        onCellClick={(e) => handleRowClick(e)}
      />
    </Stack>
  );
};

export default RepairDataGrid;
