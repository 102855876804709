import useRECOILState from "./useRECOILState";
import { bitEvalCheckBoxRepairTypeStore } from "../atoms/bitEvaluationatoms";
import { useRecoilValue } from "recoil";
import { bitEvalOrderObj } from "../atoms/staticAtoms";
  import { toast } from "react-toastify";


/**
 * Этот хук используется для изменения состояния флажков типа ремонта.
 * @returns Возвращает объект с тремя функциями
 * changeStandardState, changeHardFaceState, changeMatrixState
 */
const useRepairTypeStateChange = (props) => {
  const tempRepairTypeState = useRECOILState(bitEvalCheckBoxRepairTypeStore);
  const orderObj = useRecoilValue(bitEvalOrderObj);

  const changeStandardState = (state) => {
    let temp = { ...tempRepairTypeState.state, standard: state.target.checked };
    tempRepairTypeState.setState(temp);
    orderObj.RepairTypeCard.standard = state.target.checked;
  };

  const changeHardFaceState = (state) => {
    if (!tempRepairTypeState.state.matrix) {
      let temp = {
        ...tempRepairTypeState.state,
        hardface: state.target.checked,
      };
      tempRepairTypeState.setState(temp);
      orderObj.RepairTypeCard.hardface = state.target.checked;
    } else
      toast.error(
        "Please select only one of HardFace or Matrix repair types"
      );
  };

  const changeMatrixState = (state) => {
    if (!tempRepairTypeState.state.hardface) {
      let temp = { ...tempRepairTypeState.state, matrix: state.target.checked };
      tempRepairTypeState.setState(temp);
      orderObj.RepairTypeCard.matrix = state.target.checked;
    } else
      toast.error(
        "Please select only one of HardFace or Matrix repair types"
      );
  };

  return {
    changeStandardState,
    changeHardFaceState,
    changeMatrixState,
  };
};

export default useRepairTypeStateChange;
