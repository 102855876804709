import React, {useEffect} from 'react';
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import {useRecoilState} from "recoil";
import {repairsCompleteRows} from "../../../../../atoms/RepairsAtoms/repairsAtoms";

const testData = [
    {
        id: 1,
        serial: 123515,
        material: 3515616,
        size: '11/4/12',
        type: 'testType',
        origin: 'testOrigin',
        repType: 'testStandard, testMatrix',
    }
]

const CompleteTab = (props) => {

    const [completeRows, setCompleteRows] = useRecoilState(repairsCompleteRows);

    const {completeModalStore, rowData} = props;
    const columns = React.useMemo(
        () => [
            {
                field: 'id',
            },
            {
                field: 'evalution',
                headerName: 'Order ID',
            },
            {
                field: 'serial',
                headerName: 'Serial',
                flex: 1,
            },
            {
                field: 'material',
                headerName: 'Material',
                flex: 1,
            },
            {
                field: 'size',
                headerName: 'Size',
                flex: 1,
            },
            {
                field: 'type',
                headerName: 'Type',
                flex: 1,
            },
            {
                field: 'origin',
                headerName: 'Origin',
                flex: 1,
            },
            {
                field: 'repType',
                headerName: 'Repair Type',
                flex: 1,
            },
        ]
    )


    return (
        <DataGrid columns={columns} rows={completeRows}
                  initialState={{
                      columns: {
                          columnVisibilityModel: {
                              id: false,
                          }
                      }
                  }}
                  onRowDoubleClick={(row) => {
                      rowData.setState(row);
                      completeModalStore.setState(true);
                  }}
                  sx={{
                      padding: 1,
                      height: '60vh',
                  }}
        />
    );
}

export default CompleteTab;