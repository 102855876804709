import React from 'react';
import {IconButton, Stack} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {useRecoilValue} from "recoil";
import {bladeStore} from "../../../../../../atoms/cutterMapState";


export const PrimaryRowButtons = (props) => {
    const bladesStore = useRecoilValue(bladeStore);
    const {index, addPrimaryCutter, removePrimaryCutter} = props;
    return (
        <Stack
            direction={"row"}
            gap={1}
            id={"primary-control"}
        >
            <IconButton>
                <AddIcon onClick={()=>addPrimaryCutter(index)}/>
            </IconButton>
            <IconButton>
                <RemoveIcon onClick={()=>removePrimaryCutter(index)}/>
            </IconButton>
        </Stack>
    );
}
