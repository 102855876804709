import useObjectState from "../../../hooks/useObjectState";

const useDrawerState = () => {
    const drawerState = useObjectState(true);

    return {
        drawerState,
    };
}

export default useDrawerState;