import React, {useEffect} from 'react';
import {Box} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {DbData, Order} from "../../../../../../atoms/repairEntryFetchData";

/* Определение столбцов, которые будут отображаться в таблице. */
const testColumns = [
    {
        field: 'id',
    },
    {
        field: 'Name',
        headerName: 'Name',
        flex: 1,
    },
    {
        field: 'MaterialCode',
        headerName: 'Material',
        flex: 1,
    },
    {
        field: 'Description',
        headerName: 'Description',
        hide: true,
        flex: 1,
    },
    {
        field: 'Type',
        headerName: 'Type',
        hide: true,
        flex: 1,
    },
    {
        field: 'Qty',
        headerName: 'Qty',
        flex: 1,
    },
    {
        field: 'Unit',
        headerName: 'Item total',
        hide: true,
        flex: 1,
    }
]

/* Тестовые данные для таблицы. */
const testRows = [
    {
        id: 1,
        serial: 2345,
        material: 5167,
        size: '11/4/12',
        type: 'Some Type',
        origin: 'Buzuluk',
        RepairType: 'Matrix, Standard',
        workOrder: 'NONE',
    }
]

const RepairOrderTypeTable = (props) => {
    const {orderId} = props;
    const [rows, setRows] = React.useState([]);
    
    const getRowsCutters = async () => {
        
        let dbData = new DbData(Order.model, [Order.fields.Id, Order.fields.Serial], Order.fields.Id, props.orderId);
        await dbData.sendRequest();
        let drillSerial = dbData.result[0][Order.fields.Serial];


        let url = "/api/cutters/getCuttersForOrdering?orderId=" + orderId;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                let primary = JSON.parse(data.primaryCutter);
                let secondary = JSON.parse(data.secondaryCutter);
                let rows = JSON.parse(data.rows);

                let allRows = [];
                
                allRows.push(...rows);
                
                // primary.forEach((item) => {
                //     allRows.push(item)})
                //
                // secondary.forEach((item) => {
                //     allRows.push(item)}
                // )

                setRows(allRows);
            })
    }
    useEffect(() => {
        getRowsCutters();
    }, [])
    
    return (
        <Box
            height="100%"
            width="100%"
        >
            <DataGrid columns={testColumns} rows={rows}
                      sx={{
                          height: "100%",
                      }}
            />
        </Box>
    );
}

export default RepairOrderTypeTable;