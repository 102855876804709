import React, {useEffect, useMemo} from "react";
import {Box} from "@mui/material";
import MaterialReactTable from "material-react-table";

const testData = [
    {
        WELL_ID: "test",
        WELL_LEGAL_NAME: "test",
        WELL_COMMON_NAME: "test",
        WELL_OPERATOR: "test",
        API_NO: "test",
        LOC_COUNTRY: "test",
        LOC_COUNTY: "test",
        GEO_LATITUDE: "test",
        GEO_LONGITUDE: "test",
        SPUD_DATE: "test",
        LOC_STATE: "test",
        WELL_CREATED_BY_NAME: "test",
        TIGHT_GROUP_ID: "test",
        WELL_DESC: "test",
        WELL_UWI: "test",
        CREATE_DATE: "test",
        GEO_OFFSET_NORTH: "test",
        GEO_OFFSET_EAST: "test",
        WELL_REMARKS: "test",
        WELLBORE_UWI: "test",
        LICENSE_NO: "test",
        IS_DEVIATED: "test",
        HDBSREPNAME: "test",
        PRIMARYSERVICECENTER: "test",
        DIRECTIONALCO: "test",
        MUDCO: "test",
        RIG_NAME: "test",
        RIG_TYPE: "test",
        CONTRACTOR_NAME: "test",
        OCS_AREA_NAME: "test",
        OCS_BLOCK_NO: "test",
        OCS_NO: "test",
        SECTION: "test",
        TOWNSHIP: "test",
        TOWNSHIP_DIR: "test",
        RANGE: "test",
        RANGE_DIR: "test",
        DLS_LOCATION_EXCEPTION: "test",
        INTL_AREA_NAME: "test",
        INTL_BASIN: "test",
        INTL_BLOCK: "test",
        INTL_FIELD: "test",
        INTL_PAD: "test",
        INTL_DISCOVERY_NAME: "test",
        INTL_PLATFORM: "test",
        INTL_QUADRANT: "test",
        INTL_COORDINATES_E: "test",
        INTL_COORDINATES_N: "test",
        INTL_ABSTRACT: "test",
        INTL_SURVEY: "test",
        INTL_REGION: "test",
        INTL_DIRECTION: "test",
        IS_WELL_RECEIVED: "test",
        LICENSE_RECEIVED_DATE: "test",
        WELL_COMPLETED_DATE: "test",
        UPDATE_DATE: "test",
        UPDATE_USER_ID: "test",
        UPDATE_APP_ID: "test",
        CONNECTION_TYPE: "test",
        GRADE_ID: "test",
        GRADE: "test",
        NOMINAL_WEIGHT: "test",
        ID_BODY: "test",
        LENGTH: "test",
        OD_BODY: "test",
        THREAD_TYPE: "test",
        SECT_TYPE_CODE: "test",
        WELL_PURPOSE: "test",
        ATTACHMENT: "test",
        ATTACHMENTTYPE: "test",
        TARGET_FORMATION: "test",
        WATER_DEPTH: "test",
        RUN_NO: "test",
        MD_IN: "test",
        MD_OUT: "test",
        RUN_LENGTH: "test",
        RUN_DATE: "test",
        NOMINAL_SIZE: "test",
        MANUFACTURER: "test",
        BIT_TYPE: "test",
        SERIAL_NO: "test",
        MATERIAL_NUM: "test",
        TFA: "test",
        CONNECTION_TYPE_1: "test",
        COMP_COST: "test",
        IADC_INNER: "test",
        IADC_OUTER: "test",
        IADC_LOCATION: "test",
        IADC_DULL: "test",
        IADC_OTHER: "test",
        IADC_GAUGE: "test",
        IADC_REASON_PULLED: "test",
        BIT_GROUP: "test",
        IADC_CODE: "test",
        BIT_CLASS: "test",
        BIT_DRIVE: "test",
        BIT_NO: "test",
        BIT_SIZE: "test",
        REMARKS: "test",
        IADCBEARING1: "test",
        IADCBEARING2: "test",
        IADCBEARING3: "test",
        GRADINGDATE: "test",
        BHA_OP_DRILLING_HOURS: "test",
        FLOW_RATE: "test",
        DOGLEG_SEVERITY: "test",
        RPM: "test",
        STAND_PIPE_PRESSURE: "test",
        TORQUE_OFF_BOTTOM: "test",
        TORQUE_ON_BOTTOM: "test",
        PUMPPRESSURELOW: "test",
        PUMPPRESSUREHIGH: "test",
        MUDFLOWRATELOW: "test",
        MUDFLOWRATEHIGH: "test",
        SHOCKSUB: "test",
        VERTICALDEVIATIONOUT: "test",
        DRIVEDETAIL: "test",
        HOURSSTEERING: "test",
        MOTORRPMLOW: "test",
        MOTORRPMHIGH: "test",
        HOURSREAMING: "test",
        SUMMARY: "test",
        ROPSTEERING: "test",
        OPERATIONENDDEPTH: "test",
        OPERATIONSTARTDEPTH: "test",
        AZIMUTHIN: "test",
        AZIMUTHOUT: "test",
        BOTTOMHOLECIRCTEMP: "test",
        HOLEENLARGEMENTSIZE: "test",
        HOURSCIRCULATING: "test",
        MATERIALSUSED: "test",
        SUB_RUN_NO: "test",
        TOTALVERTICALDEPTHIN: "test",
        TOTALVERTICALDEPTHOUT: "test",
        VERTICALDEVIATIONIN: "test",
        HOURSROTATING: "test",
        WOB_MIN: "test",
        WOB_MAX: "test",
        RPM_MIN: "test",
        RPM_MAX: "test",
        FORMATION_NAME: "test",
        OP_DURATION: "test",
        API_WATER_LOSS: "test",
        CIRCULATING_TIME: "test",
        DM_BIT_OP_ROP: "test",
        DENSITY: "test",
        TYPE_MUD_SYSTEM: "test",
        PLASTIC_VISCOSITY: "test",
        YIELD_POINT: "test",
        VISCOSITY_FUNNEL: "test",
        ROP: "test",
        ATTACHMENT_COUNT: "test",
        NOZZLE_TYPE: "test",
        NOZZLE_COUNT: "test",
        NOZZLE_DIAMETER: "test",
        CUSTOMER_GRADINGDT: "test",
        CUSTOMER_DULL: "test",
        CUSTOMER_GUAGE: "test",
        CUSTOMER_ICS: "test",
        CUSTOMER_LOCATION: "test",
        CUSTOMER_OTHER: "test",
        CUSTOMER_OCS: "test",
        CUSTOMER_REASONPULLED: "test",
        CUSTOMER_BEARINGSEAL1: "test",
        CUSTOMER_BEARINGSEAL2: "test",
        CUSTOMER_BEARINGSEAL3: "test",
        MAX_TORQUE_OFF_BOTTOM: "test",
        MAX_TORQUE_ON_BOTTOM: "test",
        MAX_SUB_RUN_NO: "test",
        JET_SIZE: "test",
        TOT_RPM: "test",
        KILO_REV: "test",
        BIT_APPL: "test",
        SALES_ORDER: "test",
        SOLD_TO: "test",
    },
    {
        WELL_ID: "test",
        WELL_LEGAL_NAME: "test",
        WELL_COMMON_NAME: "test",
        WELL_OPERATOR: "test",
        API_NO: "test",
        LOC_COUNTRY: "test",
        LOC_COUNTY: "test",
        GEO_LATITUDE: "test",
        GEO_LONGITUDE: "test",
        SPUD_DATE: "test",
        LOC_STATE: "test",
        WELL_CREATED_BY_NAME: "test",
        TIGHT_GROUP_ID: "test",
        WELL_DESC: "test",
        WELL_UWI: "test",
        CREATE_DATE: "test",
        GEO_OFFSET_NORTH: "test",
        GEO_OFFSET_EAST: "test",
        WELL_REMARKS: "test",
        WELLBORE_UWI: "test",
        LICENSE_NO: "test",
        IS_DEVIATED: "test",
        HDBSREPNAME: "test",
        PRIMARYSERVICECENTER: "test",
        DIRECTIONALCO: "test",
        MUDCO: "test",
        RIG_NAME: "test",
        RIG_TYPE: "test",
        CONTRACTOR_NAME: "test",
        OCS_AREA_NAME: "test",
        OCS_BLOCK_NO: "test",
        OCS_NO: "test",
        SECTION: "test",
        TOWNSHIP: "test",
        TOWNSHIP_DIR: "test",
        RANGE: "test",
        RANGE_DIR: "test",
        DLS_LOCATION_EXCEPTION: "test",
        INTL_AREA_NAME: "test",
        INTL_BASIN: "test",
        INTL_BLOCK: "test",
        INTL_FIELD: "test",
        INTL_PAD: "test",
        INTL_DISCOVERY_NAME: "test",
        INTL_PLATFORM: "test",
        INTL_QUADRANT: "test",
        INTL_COORDINATES_E: "test",
        INTL_COORDINATES_N: "test",
        INTL_ABSTRACT: "test",
        INTL_SURVEY: "test",
        INTL_REGION: "test",
        INTL_DIRECTION: "test",
        IS_WELL_RECEIVED: "test",
        LICENSE_RECEIVED_DATE: "test",
        WELL_COMPLETED_DATE: "test",
        UPDATE_DATE: "test",
        UPDATE_USER_ID: "test",
        UPDATE_APP_ID: "test",
        CONNECTION_TYPE: "test",
        GRADE_ID: "test",
        GRADE: "test",
        NOMINAL_WEIGHT: "test",
        ID_BODY: "test",
        LENGTH: "test",
        OD_BODY: "test",
        THREAD_TYPE: "test",
        SECT_TYPE_CODE: "test",
        WELL_PURPOSE: "test",
        ATTACHMENT: "test",
        ATTACHMENTTYPE: "test",
        TARGET_FORMATION: "test",
        WATER_DEPTH: "test",
        RUN_NO: "test",
        MD_IN: "test",
        MD_OUT: "test",
        RUN_LENGTH: "test",
        RUN_DATE: "test",
        NOMINAL_SIZE: "test",
        MANUFACTURER: "test",
        BIT_TYPE: "test",
        SERIAL_NO: "test",
        MATERIAL_NUM: "test",
        TFA: "test",
        CONNECTION_TYPE_1: "test",
        COMP_COST: "test",
        IADC_INNER: "test",
        IADC_OUTER: "test",
        IADC_LOCATION: "test",
        IADC_DULL: "test",
        IADC_OTHER: "test",
        IADC_GAUGE: "test",
        IADC_REASON_PULLED: "test",
        BIT_GROUP: "test",
        IADC_CODE: "test",
        BIT_CLASS: "test",
        BIT_DRIVE: "test",
        BIT_NO: "test",
        BIT_SIZE: "test",
        REMARKS: "test",
        IADCBEARING1: "test",
        IADCBEARING2: "test",
        IADCBEARING3: "test",
        GRADINGDATE: "test",
        BHA_OP_DRILLING_HOURS: "test",
        FLOW_RATE: "test",
        DOGLEG_SEVERITY: "test",
        RPM: "test",
        STAND_PIPE_PRESSURE: "test",
        TORQUE_OFF_BOTTOM: "test",
        TORQUE_ON_BOTTOM: "test",
        PUMPPRESSURELOW: "test",
        PUMPPRESSUREHIGH: "test",
        MUDFLOWRATELOW: "test",
        MUDFLOWRATEHIGH: "test",
        SHOCKSUB: "test",
        VERTICALDEVIATIONOUT: "test",
        DRIVEDETAIL: "test",
        HOURSSTEERING: "test",
        MOTORRPMLOW: "test",
        MOTORRPMHIGH: "test",
        HOURSREAMING: "test",
        SUMMARY: "test",
        ROPSTEERING: "test",
        OPERATIONENDDEPTH: "test",
        OPERATIONSTARTDEPTH: "test",
        AZIMUTHIN: "test",
        AZIMUTHOUT: "test",
        BOTTOMHOLECIRCTEMP: "test",
        HOLEENLARGEMENTSIZE: "test",
        HOURSCIRCULATING: "test",
        MATERIALSUSED: "test",
        SUB_RUN_NO: "test",
        TOTALVERTICALDEPTHIN: "test",
        TOTALVERTICALDEPTHOUT: "test",
        VERTICALDEVIATIONIN: "test",
        HOURSROTATING: "test",
        WOB_MIN: "test",
        WOB_MAX: "test",
        RPM_MIN: "test",
        RPM_MAX: "test",
        FORMATION_NAME: "test",
        OP_DURATION: "test",
        API_WATER_LOSS: "test",
        CIRCULATING_TIME: "test",
        DM_BIT_OP_ROP: "test",
        DENSITY: "test",
        TYPE_MUD_SYSTEM: "test",
        PLASTIC_VISCOSITY: "test",
        YIELD_POINT: "test",
        VISCOSITY_FUNNEL: "test",
        ROP: "test",
        ATTACHMENT_COUNT: "test",
        NOZZLE_TYPE: "test",
        NOZZLE_COUNT: "test",
        NOZZLE_DIAMETER: "test",
        CUSTOMER_GRADINGDT: "test",
        CUSTOMER_DULL: "test",
        CUSTOMER_GUAGE: "test",
        CUSTOMER_ICS: "test",
        CUSTOMER_LOCATION: "test",
        CUSTOMER_OTHER: "test",
        CUSTOMER_OCS: "test",
        CUSTOMER_REASONPULLED: "test",
        CUSTOMER_BEARINGSEAL1: "test",
        CUSTOMER_BEARINGSEAL2: "test",
        CUSTOMER_BEARINGSEAL3: "test",
        MAX_TORQUE_OFF_BOTTOM: "test",
        MAX_TORQUE_ON_BOTTOM: "test",
        MAX_SUB_RUN_NO: "test",
        JET_SIZE: "test",
        TOT_RPM: "test",
        KILO_REV: "test",
        BIT_APPL: "test",
        SALES_ORDER: "test",
        SOLD_TO: "test",
    },
];

export const BitRun = (props) => {
    /* Создание таблицы с заголовками столбцов. */
    const columns = useMemo(() => [
        {
            accesorKey: "WELL_ID",
            header: "well_id",
        },
        {
            accesorKey: "WELL_LEGAL_NAME",
            header: "well_legal_name",
        },
        {
            accesorKey: "WELL_COMMON_NAME",
            header: "well_common_name",
        },
        {
            accesorKey: "WELL_OPERATOR",
            header: "well_operator",
        },
        {
            accesorKey: "API_NO",
            header: "api_no",
        },
        {
            accesorKey: "LOC_COUNTRY",
            header: "loc_country",
        },
        {
            accesorKey: "LOC_COUNTY",
            header: "loc_county",
        },
        {
            accesorKey: "GEO_LATITUDE",
            header: "geo_latitude",
        },
        {
            accesorKey: "GEO_LONGITUDE",
            header: "geo_longitude",
        },
        {
            accesorKey: "SPUD_DATE",
            header: "spud_date",
        },
        {
            accesorKey: "LOC_STATE",
            header: "loc_state",
        },
        {
            accesorKey: "WELL_CREATED_BY_NAME",
            header: "well_created_by_name",
        },
        {
            accesorKey: "TIGHT_GROUP_ID",
            header: "tight_group_id",
        },
        {
            accesorKey: "WELL_DESC",
            header: "well_desc",
        },
        {
            accesorKey: "WELL_UWI",
            header: "well_uwi",
        },
        {
            accesorKey: "CREATE_DATE",
            header: "create_date",
        },
        {
            accesorKey: "GEO_OFFSET_NORTH",
            header: "geo_offset_north",
        },
        {
            accesorKey: "GEO_OFFSET_EAST",
            header: "geo_offset_east",
        },
        {
            accesorKey: "WELL_REMARKS",
            header: "well_remarks",
        },
        {
            accesorKey: "WELLBORE_UWI",
            header: "wellbore_uwi",
        },
        {
            accesorKey: "LICENSE_NO",
            header: "license_no",
        },
        {
            accesorKey: "IS_DEVIATED",
            header: "is_deviated",
        },
        {
            accesorKey: "HDBSREPNAME",
            header: "hdbsrepname",
        },
        {
            accesorKey: "PRIMARYSERVICECENTER",
            header: "primaryservicecenter",
        },
        {
            accesorKey: "DIRECTIONALCO",
            header: "directionalco",
        },
        {
            accesorKey: "MUDCO",
            header: "mudco",
        },
        {
            accesorKey: "RIG_NAME",
            header: "rig_name",
        },
        {
            accesorKey: "RIG_TYPE",
            header: "rig_type",
        },
        {
            accesorKey: "CONTRACTOR_NAME",
            header: "contractor_name",
        },
        {
            accesorKey: "OCS_AREA_NAME",
            header: "ocs_area_name",
        },
        {
            accesorKey: "OCS_BLOCK_NO",
            header: "ocs_block_no",
        },
        {
            accesorKey: "OCS_NO",
            header: "ocs_no",
        },
        {
            accesorKey: "SECTION",
            header: "section",
        },
        {
            accesorKey: "TOWNSHIP",
            header: "township",
        },
        {
            accesorKey: "TOWNSHIP_DIR",
            header: "township_dir",
        },
        {
            accesorKey: "RANGE",
            header: "range",
        },
        {
            accesorKey: "RANGE_DIR",
            header: "range_dir",
        },
        {
            accesorKey: "DLS_LOCATION_EXCEPTION",
            header: "dls_location_exception",
        },
        {
            accesorKey: "INTL_AREA_NAME",
            header: "intl_area_name",
        },
        {
            accesorKey: "INTL_BASIN",
            header: "intl_basin",
        },
        {
            accesorKey: "INTL_BLOCK",
            header: "intl_block",
        },
        {
            accesorKey: "INTL_FIELD",
            header: "intl_field",
        },
        {
            accesorKey: "INTL_PAD",
            header: "intl_pad",
        },
        {
            accesorKey: "INTL_DISCOVERY_NAME",
            header: "intl_discovery_name",
        },
        {
            accesorKey: "INTL_PLATFORM",
            header: "intl_platform",
        },
        {
            accesorKey: "INTL_QUADRANT",
            header: "intl_quadrant",
        },
        {
            accesorKey: "INTL_COORDINATES_E",
            header: "intl_coordinates_e",
        },
        {
            accesorKey: "INTL_COORDINATES_N",
            header: "intl_coordinates_n",
        },
        {
            accesorKey: "INTL_ABSTRACT",
            header: "intl_abstract",
        },
        {
            accesorKey: "INTL_SURVEY",
            header: "intl_survey",
        },
        {
            accesorKey: "INTL_REGION",
            header: "intl_region",
        },
        {
            accesorKey: "INTL_DIRECTION",
            header: "intl_direction",
        },
        {
            accesorKey: "IS_WELL_RECEIVED",
            header: "is_well_received",
        },
        {
            accesorKey: "LICENSE_RECEIVED_DATE",
            header: "license_received_date",
        },
        {
            accesorKey: "WELL_COMPLETED_DATE",
            header: "well_completed_date",
        },
        {
            accesorKey: "UPDATE_DATE",
            header: "update_date",
        },
        {
            accesorKey: "UPDATE_USER_ID",
            header: "update_user_id",
        },
        {
            accesorKey: "UPDATE_APP_ID",
            header: "update_app_id",
        },
        {
            accesorKey: "CONNECTION_TYPE",
            header: "connection_type",
        },
        {
            accesorKey: "GRADE_ID",
            header: "grade_id",
        },
        {
            accesorKey: "GRADE",
            header: "grade",
        },
        {
            accesorKey: "NOMINAL_WEIGHT",
            header: "nominal_weight",
        },
        {
            accesorKey: "ID_BODY",
            header: "id_body",
        },
        {
            accesorKey: "LENGTH",
            header: "length",
        },
        {
            accesorKey: "OD_BODY",
            header: "od_body",
        },
        {
            accesorKey: "THREAD_TYPE",
            header: "thread_type",
        },
        {
            accesorKey: "SECT_TYPE_CODE",
            header: "sect_type_code",
        },
        {
            accesorKey: "WELL_PURPOSE",
            header: "well_purpose",
        },
        {
            accesorKey: "ATTACHMENT",
            header: "attachment",
        },
        {
            accesorKey: "ATTACHMENTTYPE",
            header: "attachmenttype",
        },
        {
            accesorKey: "TARGET_FORMATION",
            header: "target_formation",
        },
        {
            accesorKey: "WATER_DEPTH",
            header: "water_depth",
        },
        {
            accesorKey: "RUN_NO",
            header: "run_no",
        },
        {
            accesorKey: "MD_IN",
            header: "md_in",
        },
        {
            accesorKey: "MD_OUT",
            header: "md_out",
        },
        {
            accesorKey: "RUN_LENGTH",
            header: "run_length",
        },
        {
            accesorKey: "RUN_DATE",
            header: "run_date",
        },
        {
            accesorKey: "NOMINAL_SIZE",
            header: "nominal_size",
        },
        {
            accesorKey: "MANUFACTURER",
            header: "manufacturer",
        },
        {
            accesorKey: "BIT_TYPE",
            header: "bit_type",
        },
        {
            accesorKey: "SERIAL_NO",
            header: "serial_no",
        },
        {
            accesorKey: "MATERIAL_NUM",
            header: "material_num",
        },
        {
            accesorKey: "TFA",
            header: "tfa",
        },
        {
            accesorKey: "CONNECTION_TYPE_1",
            header: "connection_type_1",
        },
        {
            accesorKey: "COMP_COST",
            header: "comp_cost",
        },
        {
            accesorKey: "IADC_INNER",
            header: "iadc_inner",
        },
        {
            accesorKey: "IADC_OUTER",
            header: "iadc_outer",
        },
        {
            accesorKey: "IADC_LOCATION",
            header: "iadc_location",
        },
        {
            accesorKey: "IADC_DULL",
            header: "iadc_dull",
        },
        {
            accesorKey: "IADC_OTHER",
            header: "iadc_other",
        },
        {
            accesorKey: "IADC_GAUGE",
            header: "iadc_gauge",
        },
        {
            accesorKey: "IADC_REASON_PULLED",
            header: "iadc_reason_pulled",
        },
        {
            accesorKey: "BIT_GROUP",
            header: "bit_group",
        },
        {
            accesorKey: "IADC_CODE",
            header: "iadc_code",
        },
        {
            accesorKey: "BIT_CLASS",
            header: "bit_class",
        },
        {
            accesorKey: "BIT_DRIVE",
            header: "bit_drive",
        },
        {
            accesorKey: "BIT_NO",
            header: "bit_no",
        },
        {
            accesorKey: "BIT_SIZE",
            header: "bit_size",
        },
        {
            accesorKey: "REMARKS",
            header: "remarks",
        },
        {
            accesorKey: "IADCBEARING1",
            header: "iadcbearing1",
        },
        {
            accesorKey: "IADCBEARING2",
            header: "iadcbearing2",
        },
        {
            accesorKey: "IADCBEARING3",
            header: "iadcbearing3",
        },
        {
            accesorKey: "GRADINGDATE",
            header: "gradingdate",
        },
        {
            accesorKey: "BHA_OP_DRILLING_HOURS",
            header: "bha_op_drilling_hours",
        },
        {
            accesorKey: "FLOW_RATE",
            header: "flow_rate",
        },
        {
            accesorKey: "DOGLEG_SEVERITY",
            header: "dogleg_severity",
        },
        {
            accesorKey: "RPM",
            header: "rpm",
        },
        {
            accesorKey: "STAND_PIPE_PRESSURE",
            header: "stand_pipe_pressure",
        },
        {
            accesorKey: "TORQUE_OFF_BOTTOM",
            header: "torque_off_bottom",
        },
        {
            accesorKey: "TORQUE_ON_BOTTOM",
            header: "torque_on_bottom",
        },
        {
            accesorKey: "PUMPPRESSURELOW",
            header: "pumppressurelow",
        },
        {
            accesorKey: "PUMPPRESSUREHIGH",
            header: "pumppressurehigh",
        },
        {
            accesorKey: "MUDFLOWRATELOW",
            header: "mudflowratelow",
        },
        {
            accesorKey: "MUDFLOWRATEHIGH",
            header: "mudflowratehigh",
        },
        {
            accesorKey: "SHOCKSUB",
            header: "shocksub",
        },
        {
            accesorKey: "VERTICALDEVIATIONOUT",
            header: "verticaldeviationout",
        },
        {
            accesorKey: "DRIVEDETAIL",
            header: "drivedetail",
        },
        {
            accesorKey: "HOURSSTEERING",
            header: "hourssteering",
        },
        {
            accesorKey: "MOTORRPMLOW",
            header: "motorrpmlow",
        },
        {
            accesorKey: "MOTORRPMHIGH",
            header: "motorrpmhigh",
        },
        {
            accesorKey: "HOURSREAMING",
            header: "hoursreaming",
        },
        {
            accesorKey: "SUMMARY",
            header: "summary",
        },
        {
            accesorKey: "ROPSTEERING",
            header: "ropsteering",
        },
        {
            accesorKey: "OPERATIONENDDEPTH",
            header: "operationenddepth",
        },
        {
            accesorKey: "OPERATIONSTARTDEPTH",
            header: "operationstartdepth",
        },
        {
            accesorKey: "AZIMUTHIN",
            header: "azimuthin",
        },
        {
            accesorKey: "AZIMUTHOUT",
            header: "azimuthout",
        },
        {
            accesorKey: "BOTTOMHOLECIRCTEMP",
            header: "bottomholecirctemp",
        },
        {
            accesorKey: "HOLEENLARGEMENTSIZE",
            header: "holeenlargementsize",
        },
        {
            accesorKey: "HOURSCIRCULATING",
            header: "hourscirculating",
        },
        {
            accesorKey: "MATERIALSUSED",
            header: "materialsused",
        },
        {
            accesorKey: "SUB_RUN_NO",
            header: "sub_run_no",
        },
        {
            accesorKey: "TOTALVERTICALDEPTHIN",
            header: "totalverticaldepthin",
        },
        {
            accesorKey: "TOTALVERTICALDEPTHOUT",
            header: "totalverticaldepthout",
        },
        {
            accesorKey: "VERTICALDEVIATIONIN",
            header: "verticaldeviationin",
        },
        {
            accesorKey: "HOURSROTATING",
            header: "hoursrotating",
        },
        {
            accesorKey: "WOB_MIN",
            header: "wob_min",
        },
        {
            accesorKey: "WOB_MAX",
            header: "wob_max",
        },
        {
            accesorKey: "RPM_MIN",
            header: "rpm_min",
        },
        {
            accesorKey: "RPM_MAX",
            header: "rpm_max",
        },
        {
            accesorKey: "FORMATION_NAME",
            header: "formation_name",
        },
        {
            accesorKey: "OP_DURATION",
            header: "op_duration",
        },
        {
            accesorKey: "API_WATER_LOSS",
            header: "api_water_loss",
        },
        {
            accesorKey: "CIRCULATING_TIME",
            header: "circulating_time",
        },
        {
            accesorKey: "DM_BIT_OP_ROP",
            header: "dm_bit_op_rop",
        },
        {
            accesorKey: "DENSITY",
            header: "density",
        },
        {
            accesorKey: "TYPE_MUD_SYSTEM",
            header: "type_mud_system",
        },
        {
            accesorKey: "PLASTIC_VISCOSITY",
            header: "plastic_viscosity",
        },
        {
            accesorKey: "YIELD_POINT",
            header: "yield_point",
        },
        {
            accesorKey: "VISCOSITY_FUNNEL",
            header: "viscosity_funnel",
        },
        {
            accesorKey: "ROP",
            header: "rop",
        },
        {
            accesorKey: "ATTACHMENT_COUNT",
            header: "attachment_count",
        },
        {
            accesorKey: "NOZZLE_TYPE",
            header: "nozzle_type",
        },
        {
            accesorKey: "NOZZLE_COUNT",
            header: "nozzle_count",
        },
        {
            accesorKey: "NOZZLE_DIAMETER",
            header: "nozzle_diameter",
        },
        {
            accesorKey: "CUSTOMER_GRADINGDT",
            header: "customer_gradingdt",
        },
        {
            accesorKey: "CUSTOMER_DULL",
            header: "customer_dull",
        },
        {
            accesorKey: "CUSTOMER_GUAGE",
            header: "customer_guage",
        },
        {
            accesorKey: "CUSTOMER_ICS",
            header: "customer_ics",
        },
        {
            accesorKey: "CUSTOMER_LOCATION",
            header: "customer_location",
        },
        {
            accesorKey: "CUSTOMER_OTHER",
            header: "customer_other",
        },
        {
            accesorKey: "CUSTOMER_OCS",
            header: "customer_ocs",
        },
        {
            accesorKey: "CUSTOMER_REASONPULLED",
            header: "customer_reasonpulled",
        },
        {
            accesorKey: "CUSTOMER_BEARINGSEAL1",
            header: "customer_bearingseal1",
        },
        {
            accesorKey: "CUSTOMER_BEARINGSEAL2",
            header: "customer_bearingseal2",
        },
        {
            accesorKey: "CUSTOMER_BEARINGSEAL3",
            header: "customer_bearingseal3",
        },
        {
            accesorKey: "MAX_TORQUE_OFF_BOTTOM",
            header: "max_torque_off_bottom",
        },
        {
            accesorKey: "MAX_TORQUE_ON_BOTTOM",
            header: "max_torque_on_bottom",
        },
        {
            accesorKey: "MAX_SUB_RUN_NO",
            header: "max_sub_run_no",
        },
        {
            accesorKey: "JET_SIZE",
            header: "jet_size",
        },
        {
            accesorKey: "TOT_RPM",
            header: "tot_rpm",
        },
        {
            accesorKey: "KILO_REV",
            header: "kilo_rev",
        },
        {
            accesorKey: "BIT_APPL",
            header: "bit_appl",
        },
        {
            accesorKey: "SALES_ORDER",
            header: "sales_order",
        },
        {
            accesorKey: "SOLD_TO",
            header: "sold_to",
        },
    ]);

    
    return (
        <>
            <Box
                style={{width: "calc(100%-260px)"}}
                sx={{
                    // display: "flex",
                    gap: 1,
                    padding: 1,
                    // alignItems: 'center',
                    maxHeight: "100%",
                    overflow: "auto",
                }}
            >
                <MaterialReactTable
                    columns={columns}
                    data={testData}
                    enableRowVirtualization
                    enableRowSelection
                   /* muiTablePaperProps={{
                        sx: {width: "100%"},
                    }}*/
                />
            </Box>
        </>
    );
};