import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ArchiveHeader } from "./components";
import ArchiveTable from "./components/ArchiveTable";
import UseObjectState from "../../../hooks/useObjectState";
import { ArchiveModal } from "./components/archive-modal";
import { urlSetParam } from "../../../atoms/repairEntryFetchData";
  import { toast } from "react-toastify";


const Archive = (props) => {
  const modalStore = UseObjectState(false);
  const rowData = UseObjectState(null);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    document.title = "Archive";
  });

  const loadHistory = (searchValue) => {
    let urlRequest = "/api/orders/getHistory?serial=%0";
    let url = urlSetParam(urlRequest, [searchValue]);
    fetch(url)
      .then((response) => {
        if (response.ok) {
          toast.success("History loaded successfully");
          return response.json();
        }
        toast.error("Bit not found");
      })
      .then((data) => {
        setRows(data.history);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        padding: 1,
        width: "100%",
        height: "100%",
      }}
    >
      <ArchiveHeader loadHistory={loadHistory} />
      <ArchiveTable rowData={rowData} rows={rows} modalStore={modalStore} />
      {modalStore.state === true && (
        <ArchiveModal modalStore={modalStore} rowData={rowData} />
      )}
    </Box>
  );
};

export default Archive;
