import {atom} from "recoil";

const orderingSearchTextFieldAtom = atom({
    key: "orderingSearchTextFieldAtom",
    default: '',
})

const orderingWorkOrderAtom = atom({
    key: "orderingWorkOrderAtom",
    default: ''
})

export {
    orderingSearchTextFieldAtom,
    orderingWorkOrderAtom,
}