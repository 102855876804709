import React from 'react';
import {Box} from "@mui/material";
import {ScrapDatePicker, ScrapSelect} from "./index";
import {useRecoilValue} from "recoil";
import {bitEvalOrderObj} from "../../../../../../../atoms/staticAtoms";

const ScrapDateReasonLayout = (props) => {

    // TODO: Подвязать скрап к дрели, а не только к ордеру

    return (
        <React.Fragment>
            <Box
                id="scrap-date-reason-layout"
                sx={{
                    display: "flex",
                    gap: 1, 
                    padding: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: "space-evenly",
                }}
            >
                <ScrapDatePicker/>
                <ScrapSelect/>
            </Box>
        </React.Fragment>
    );
}

export default ScrapDateReasonLayout;