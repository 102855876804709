import React, {useState} from "react";
import {Avatar, Tooltip, Typography} from "@mui/material";
import UseObjectState from "../../../../../../hooks/useObjectState";
import useOrderingChangeBladeMaterial from "../../../../../../hooks/useOrderingChangeBladeMaterial";
import Box from "@mui/material/Box";
import Theme from "../../../../../../theme/theme";

const SecondaryRowsAvatars = (props) => {
    const {
        cutterState,
        cutterNumber,
        primaryRow,
        parentState,
        childrenState,
        cutterIndex,
        bladeIndex,
    } = props;
    const newState = UseObjectState(null);
    const {pushNewCutter, getBladeStore} = useOrderingChangeBladeMaterial();
    const [reclaimState, setReclaimState] = useState('');
    
    
    const handleAvatarClick = () => {
        if (childrenState) {
            newState.setState(childrenState.name);
            pushNewCutter(childrenState.name, bladeIndex, cutterIndex, false);
        } else {
            newState.setState(parentState);
            console.log('Parent State: ', parentState);
            reclaimState === '' ? setReclaimState('Reclaim') : setReclaimState('');
            pushNewCutter(parentState.name, bladeIndex, cutterIndex, false);
        }
    };
    return (
        <>
            {cutterNumber === parentState && cutterState === "X" ? (
                <Tooltip
                    title={
                        <Box>
                            <Typography>Cutter State: {cutterState}</Typography>
                            {reclaimState !== '' && <Typography>Reclaim State: {reclaimState}</Typography>}
                            {newState.state !== null && (
                                <Typography>
                                    Cutter Material Number: {newState.state}
                                </Typography>
                            )}
                        </Box>
                    }
                >
                    {newState.state ? (
                        <Avatar
                            onClick={handleAvatarClick}
                            sx={{
                                backgroundColor: "red",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 9,
                                }}
                            >
                                {newState.state}
                            </Typography>
                        </Avatar>
                    ) : (
                        <Avatar
                            onClick={handleAvatarClick}
                            sx={{
                                backgroundColor: "red",
                            }}
                        >
                            {cutterState}
                        </Avatar>
                    )}
                </Tooltip>
            ) : (
                <Tooltip title={cutterState ? cutterState : "O"}>
                    <Avatar>{cutterState ? cutterState : "O"}</Avatar>
                </Tooltip>
            )}
        </>
    );
};


export default SecondaryRowsAvatars;