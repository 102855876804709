import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {useRecoilState} from "recoil";
import {DbData, Order} from "../../../../../../atoms/repairEntryFetchData";
import {selectedNotes} from "../../../../../../atoms/BitEvalutionNotesAtoms/atoms";
import Divider from "@mui/material/Divider";

export const EvalutionNotesRepairs  = (props) => {

    const drillSerial = props.drillSerial;

    const [notes, setNotes] = useState([]);
    const [selectedNote, setSelectedNotes] = useState({
        Title: "",
        Text: "",
    });

    const getNotes = async () => {
        let dbData = new DbData(Order.model, [Order.fields.Id, Order.fields.Serial, Order.fields.Notes], Order.fields.Serial, drillSerial);
        await dbData.sendRequest();

        let result = dbData.result;

        let tempNotes = [];
        if (result !== "No find") {
            result.map((item) => {
                if (item.FieldNotes === "NO DATA") return;
                let notes = JSON.parse(item.FieldNotes);
                notes.map((note) => {
                    let noteObj = {
                        Title: note.Title,
                        Text: note.Text,
                        Order: item.OrderId
                    }
                    tempNotes.push(noteObj);
                })
            })
        }

        setNotes(tempNotes);
    }
    
    useEffect(() => {
        getNotes();
    }, [])
    
    return (
        <React.Fragment>
            <Box sx={{
                border: "1px solid #e0e0e0",
                borderRadius: "1px",
                padding: "10px",
                display: "flex",
            }}>
                <NotesList setSelectedNote={setSelectedNotes} selectedNote={selectedNote} notes={notes}/>
                <FullNotes selectedNote={selectedNote}/>
            </Box>
        </React.Fragment>
    )
}

const NotesList = (props) => {
    
    return (
        <Box sx={{
            border: "1px solid #e0e0e0",
            width: "30%",
            borderRadius: "3px",
        }}>
            <Box sx={{
                overflowY: "scroll",
                height: "600px"
            }}>
                {props.notes.map((note, index) => {
                    let border = note === props.selectedNote ? '2px solid #1976D2' : '1px solid #e0e0e0';

                    return (
                        <React.Fragment key={index}>
                            <Box sx={{margin: "10px"}}>
                                <Box sx={{
                                    border: {border},
                                    borderRadius: "4px",
                                    margin: "10px",
                                    padding: "10px",
                                    ":hover": {
                                        borderColor: "#1976D2",
                                    }
                                }} onClick={(e) => {
                                    props.setSelectedNote(note);
                                }}>
                                    <Typography sx={{wordWrap: "break-word",}}>
                                        {note.Title} - Order {note.Order}
                                    </Typography>
                                </Box>
                            </Box>
                        </React.Fragment>

                    )
                })}
            </Box>
        </Box>
    )
}

const FullNotes = (props) => {
    return (
        <Box sx={{
            marginLeft: "10px",
            border: "1px solid #e0e0e0",
            borderRadius: "3px",
            width: "70%",
        }}>
            <Typography variant={'h4'} sx={{padding: "10px"}}>{props.selectedNote.Title}</Typography>
            <Divider/>
            <Typography component='pre' variant={'h5'} sx={{padding: "10px"}}>{props.selectedNote.Text}</Typography>
        </Box>
    )
}

export default EvalutionNotesRepairs;